import { DocumentModel } from './../../../../mik.smartportal.common.client/src/api-autogen/companyrule/models/document-model'
// Pinia 스토어
import { AxiosResponse } from 'axios'
import { CompanyRulePolicyModelApiResult, Int32ApiResult } from 'mik-common/src/api-autogen/companyrule'
import { CompanyRuleApi, ConfigApi } from 'mik-common/src/api-autogen/companyrule/api'
import { CompanyRulePolicyModel } from 'mik-common/src/api-autogen/companyrule/models'

import { defineStore } from 'pinia'

export const useConfigStore = defineStore('config', {
  state: (): IConfig => {
    return {
      authorityLevel: 0,
      companyRulePolicyModel: {
        useChangeHistoryShow: '',
        useOriginalPriority: '',
        useAttachPriority: '',
        useListViewCategoryColumnVisible: false,
        useCategoryTreeAllActive: ''
      },
      documentModel: null as DocumentModel | null
    }
  },
  actions: {
    //Index 페이지에서 호출
    async fnGetAuthorityLevel() {
      const api = new CompanyRuleApi()
      const response: AxiosResponse<Int32ApiResult> = await api.apiV1CompanyRuleAuthorityLevelGetGet()
      if (response.data.data) {
        this.authorityLevel = response.data.data
      }
    },
    //Index 페이지에서 호출
    async fnGetCompanyRuleSetting() {
      const api = new ConfigApi()
      const response: AxiosResponse<CompanyRulePolicyModelApiResult> = await api.apiV1ConfigCompanyRulePolicyGetGet()
      if (response.data.data) {
        this.companyRulePolicyModel = response.data.data
      }
    },
    //View 페이지에서 호출
    setDocumentModel(model: DocumentModel) {
      this.documentModel = model
    }
  },
  persist: true
})

export interface IConfig {
  authorityLevel: number
  companyRulePolicyModel: CompanyRulePolicyModel
  documentModel: DocumentModel | null
}
