import { BoardParams, BoardQueryParams } from 'mik-community/src/model/CommunityBoardModel'
import { useCommunityStore } from 'mik-community/src/store/community'
import { BoardLayoutModel } from 'mik-common/src/api-autogen/community'
import { HomeApiAxiosParamCreator } from 'mik-common/src/api-autogen/community/api'
import { Router } from 'vue-router'
/**
 * 커뮤니티 홈 화면 웹파트에서 제목 등이 길어지는 경우 ellipsis 처리되면서 부모 박스를 넘어가는 경우가 있어
 * 우선 임시처리용 width 고정
 */
export const calcWidgetMaxWidth = (w: number) => {
  switch (w) {
    case 1:
      return 180
    case 2:
      return 320
    case 3:
      return 560
    case 4:
      return 790
  }
}

export const getThumbnailPath = async (relativeUrl: string, type: string, isNew?: boolean) => {
  let api
  switch (type) {
    case 'banner':
      api = await HomeApiAxiosParamCreator().v1HomeBannerImageGet()
      break
    case 'cover':
      api = await HomeApiAxiosParamCreator().v1HomeCoverImageGet()
      break
  }
  const url = api?.url
  return `${import.meta.env.V_API_COMMUNITY_PATH}${url}?communityUrl=${relativeUrl}${isNew ? '&t=' + Date.now() : ''}`
}

const getTextColorClass = (color: string) => {
  switch (color) {
    case 'yellow':
    case 'lime':
    case 'amber':
      return 'text-dark'
    default:
      return 'text-grey-2'
  }
}

export const getThemeClass = (type: string) => {
  const store = useCommunityStore()
  const color = store.theme
  switch (type) {
    case 'lnb':
      return `bg-${color}-4`
    case 'gnb':
      return color == 'default' || color == '' ? 'bg-grey-9' : `bg-${color}-5`
    case 'base':
      return `bg-${color}-1`
    case 'icon':
      return color ? `${color}-4` : ''
    case 'header':
      return `text-${color}-10`
    case 'separator':
      return color == 'default' || color == '' ? 'dark' : `${color}-10`
    case 'lnb-separator':
      return color == 'default' || color == '' ? '' : `${color}-4`
    case 'gnbText':
      return getTextColorClass(color)
  }
}

export const getLayoutIdFromBoardUrl = (boardUrl: string) => {
  const boardUrlSplit = boardUrl.split('/')
  if (boardUrlSplit.indexOf('Gallery') > 0) {
    return boardUrlSplit[boardUrlSplit.indexOf('Gallery')]
  }
  return boardUrl.slice(boardUrl.lastIndexOf('/') + 1)
}

export const pushToBoard = (router: Router, communityUrl: string, boardUrl: string, query?: BoardQueryParams) => {
  /**
   * Scheduler가 포함된 경우 일정게시판으로 이동
   * 현재는 하드코딩. 추후 개선
   *
   */
  const layoutId = getLayoutIdFromBoardUrl(boardUrl)

  if (layoutId.toLowerCase() === 'scheduler') {
    router.push({
      name: 'Community.Home.Scheduler',
      params: {
        communityUrl
      }
    })
  } else if (layoutId.toLowerCase() === 'gallery') {
    router.push({
      name: 'Home.Gallery',
      params: {
        communityUrl,
        layoutId: boardUrl.slice(boardUrl.lastIndexOf('/') + 1)
      },
      state: { boardUrl: boardUrl },
      query: { ...query }
    })
  } else {
    router.push({
      name: 'Community.Home.Board',
      params: {
        communityUrl,
        layoutId: getLayoutIdFromBoardUrl(boardUrl)
      },
      state: { boardUrl: boardUrl },
      query: { ...query }
    })
  }
}

export const pushToBoardItem = (
  router: Router,
  communityUrl: string,
  boardUrl: string,
  itemId: string,
  query?: any
) => {
  // 커뮤니티 게시판이 아니면 새창 띄우도록 수정
  if (boardUrl.toLowerCase().indexOf('community/') < 0) {
    window.open(`${import.meta.env.V_PORTAL_PATH}/board/${boardUrl}/View/${itemId}`)
    return
  }

  router.push({
    name: 'Community.Home.BoardView',
    params: {
      communityUrl,
      layoutId: getLayoutIdFromBoardUrl(boardUrl),
      itemId
    },
    state: { boardUrl: boardUrl },
    query: { ...query }
  })
}

export const pushToBoardEdit = (
  router: Router,
  communityUrl: string,
  boardUrl: string,
  mode: 'CREATE' | 'EDIT' | 'REPLY',
  itemId: string | null,
  query?: any
) => {
  router.push({
    name: 'Community.Home.BoardEdit',
    params: {
      communityUrl,
      layoutId: getLayoutIdFromBoardUrl(boardUrl),
      mode,
      itemId
    },
    state: { boardUrl: boardUrl },
    query: { ...query }
  })
}

export const getBoardQuery = (
  pageNumber: number,
  searchType: string,
  searchPeriod: string,
  searchKeyword: string,
  searchPeriodFrom?: string,
  searchPeriodTo?: string
) => {
  const query = {} as BoardQueryParams
  if (pageNumber != 1) query.pageNumber = pageNumber
  if (searchType) query.searchType = searchType
  if (searchPeriod) query.searchPeriod = searchPeriod
  if (searchPeriodFrom) query.searchPeriodFrom = searchPeriodFrom
  if (searchPeriodTo) query.searchPeriodTo = searchPeriodTo
  if (searchKeyword) query.searchKeyword = searchKeyword

  return query
}

// tempList 배열에서 id 값을 가진 객체 찾기
export function findBoardByBoardUrl(boardList: BoardLayoutModel[], boardUrl: string): string | undefined {
  for (const board of boardList) {
    if (board.boardUrl === boardUrl) {
      return board.mikboardID
    }
    const found = findBoardByBoardUrl(board.children ?? [], boardUrl)
    if (found) {
      return found
    }
  }
  return undefined
}
