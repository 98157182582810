import { useQuasar } from 'quasar'
import { Notify } from 'quasar'

export const useToast = () => {
  return {
    success(msg: string, caption?: string) {
      Notify.create({
        type: 'positive',
        message: msg,
        caption: caption,
        position: 'top',
        classes: 'toast_gnb',
        timeout: 1000
      })
    },

    error(msg: string, caption?: string) {
      Notify.create({
        type: 'negative',
        message: msg,
        caption: caption,
        position: 'top',
        classes: 'toast_gnb',
        timeout: 1000
      })
    },

    warning(msg: string, caption?: string) {
      Notify.create({
        type: 'warning',
        message: msg,
        caption: caption,
        position: 'top',
        classes: 'toast_gnb',
        timeout: 1000
      })
    },

    info(msg: string, caption?: string) {
      Notify.create({
        type: 'info',
        message: msg,
        caption: caption,
        position: 'top',
        classes: 'toast_gnb',
        timeout: 1000
      })
    }

    //   triggerOngoing() {
    //     // we need to get the notification reference
    //     // otherwise it will never get dismissed ('ongoing' type has timeout 0)
    //     const notif = $q.notify({
    //       type: 'ongoing',
    //       message: 'Looking up the search terms...'
    //     })

    //     // simulate delay
    //     setTimeout(() => {
    //       notif({
    //         type: 'positive',
    //         message: 'Found the results that you were looking for',
    //         timeout: 1000
    //       })
    //     }, 4000)
    //   }
  }
}
