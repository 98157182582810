const routers = [
  {
    path: '/',
    redirect: { name: 'MyWorkHistory' },
    beforeEnter: []
  },
  {
    name: 'MyWork',
    path: '/MyWork',
    component: () => import('mik-workplanner/src/pages/user/MyWork/MyWork.vue')
  },
  {
    name: 'MyWorkPlan',
    path: '/MyWorkPlan',
    component: () => import('mik-workplanner/src/pages/user/WorkPlan/MyWorkPlan.vue')
  },

  {
    name: 'MyWorkHistory',
    path: '/MyWorkHistory',
    component: () => import('mik-workplanner/src/pages/user/WorkHistory/MyWorkHistory.vue')
  },
  {
    name: 'MyWorkManage',
    path: '/MyWorkManage',
    component: () => import('mik-workplanner/src/pages/user/WorkManage/MyWorkManage.vue')
  },
  {
    name: 'MyWorkHistoryDetail',
    path: '/MyWorkHistoryDetail',
    component: () => import('mik-workplanner/src/pages/user/WorkHistory/MyWorkHistoryDetail.vue')
  },
  {
    name: 'WorkTotal',
    path: '/WorkTotal',
    component: () => import('mik-workplanner/src/pages/admin/work/WorkTotal.vue')
  },
  {
    name: 'LegalWorkingHoursList',
    path: '/LegalWorkingHours',
    component: () => import('mik-workplanner/src/pages/admin/LegalWorkingHours/List.vue')
  },
  {
    name: 'LegalWorkingHoursDetail',
    path: '/LegalWorkingHours/Detail/:legalID',
    props: true,
    component: () => import('mik-workplanner/src/pages/admin/LegalWorkingHours/Detail.vue')
  },
  {
    name: 'VacationDaysPerYear',
    path: '/VacationDaysPerYear',
    component: () => import('mik-workplanner/src/pages/admin/VacationDaysPerYear.vue')
  },
  {
    name: 'Dashboard',
    path: '/Dashboard',
    component: () => import('mik-workplanner/src/pages/user/Dashboard.vue')
  },
  {
    name: 'ProjectsList',
    path: '/Projects',
    component: () => import('mik-workplanner/src/pages/admin/projects/List.vue')
  },
  {
    name: 'ProjectsEdit',
    path: '/Projects/Edit',
    component: () => import('mik-workplanner/src/pages/admin/projects/Edit.vue')
  },
  {
    name: 'ProjectsView',
    path: '/Projects/View',
    props: true,
    component: () => import('mik-workplanner/src/pages/admin/projects/View.vue')
  },
  {
    name: 'WorkStatus',
    path: '/WorkStatus',
    props: true,
    component: () => import('mik-workplanner/src/pages/user/WorkStatus.vue')
  }
]

export default routers
