<template>
  <div class="community">
    <div v-if="isLoaded" class="community skin01" :class="themeClass">
      <div id="lay_wrap">
        <!-- start of - Community GNB -->
        <div id="lay_header" class="gwp_header gnb_use_navigation">
          <CommunityHeader :cover-image-url="coverImageUrl" />
        </div>
        <!-- end of - Community GNB -->
        <div id="lay_container">
          <!-- start of - Community LNB -->
          <div id="lay_lnb" class="gwp_default">
            <!-- 관리자 메뉴 -->
            <ManagementLocalMenu
              v-if="route.meta.communityManage"
              @go-management="handleManagementClick"
              @set-cover="settingCoverImage" />
            <!-- 일반 메뉴 -->
            <CommunityLocalMenu v-else @go-management="handleManagementClick" @set-cover="settingCoverImage" />
          </div>
          <!-- end of - Community LNB -->
          <div id="lay_contents">
            <slot />
          </div>
        </div>
      </div>
    </div>

    <image-cropper
      v-model:current-status="isOpenIconImage"
      v-model:base64-string="coverImageBase64"
      v-model:model-value="coverImageFile"
      :resizable="true"
      :ratio="3 / 1"
      :width="400"
      :height="300"></image-cropper>
  </div>
</template>

<script setup lang="ts">
import CommunityHeader from 'mik-community/src/components/community/CommunityHeader.vue'
import CommunityLocalMenu from 'mik-community/src/components/community/CommunityLocalMenu.vue'
import ManagementLocalMenu from 'mik-community/src/components/community/ManagementLocalMenu.vue'
import ImageCropper from 'mik-community/src/components/popup/ImageCropper.vue'

import { useCommunityStore } from 'mik-community/src/store/community'
import { getThumbnailPath } from 'mik-community/src/utils/util'
import { HomeApi } from 'mik-common/src/api-autogen/community'
import { useToast } from 'mik-community/src/common/toast'
import { getThemeClass } from 'mik-community/src/utils/util'

const route = useRoute()
const router = useRouter()
const store = useCommunityStore()
const api = new HomeApi()
const toast = useToast()
const { t } = useI18n()

const themeClass = ref(getThemeClass('base'))

const isLoaded = ref(false)

/** [Profile] 이미지 model */
const coverImageFile = ref<File | undefined | null>(null)
/** [Profile] 이미지 base64 */
const coverImageBase64 = ref<string>('')
/** [Profile] 이미지 변경 Popup */
const isOpenIconImage = ref(false)

/** [Profile] 이미지 초기화 */
const profileImageInit = () => {
  coverImageBase64.value = ''
}
/** [Profile] 이미지 제거 */
const handleDeleteImage = () => {
  profileImageInit()
}
const coverImageUrl = ref('')

const getCoverImageUrl = async () => {
  if (!store.communityInfo) return
  if (!coverImageUrl.value) coverImageUrl.value = store.headerImageUrl
  else coverImageUrl.value = await getThumbnailPath(store.communityInfo.relativeUrl ?? '', 'cover', true)
  // 변경된 내용 store에도 반영
  store.headerImageUrl = await getThumbnailPath(store.communityInfo.relativeUrl ?? '', 'cover', true)
}
const visitStats = async () => {
  // const response: AxiosResponse<ApiResult> = await api.v1CommunityVisitCommunityIdGet(
  //   store.communityInfo.communityID ?? ''
  // )
  // if (!response.data.success) {
  //   console.log('방문기록 실패')
  // }
}

const settingCoverImage = () => {
  isOpenIconImage.value = true
}

const handleManagementClick = () => {
  router.push({ name: 'Community.Management.Home' })
}

watch(coverImageBase64, async () => {
  if (!coverImageBase64.value) return
  const request = {
    communityID: store.communityInfo.communityId,
    coverImage: coverImageBase64.value
  }

  const uploadImage = await api.v1HomeCoverImageUploadPost(request)
  if (uploadImage.data.success) {
    // coverImageFile.value = null
    coverImageBase64.value = ''
    getCoverImageUrl()
  } else {
    toast.error(t('UploadFailure', '업로드에 실패하였습니다.'))
  }
})

watch(store.communityInfo, async () => {
  await visitStats()
})

watch(
  () => store.theme,
  (newVal, oldVal) => {
    themeClass.value = getThemeClass('base')
  }
)

onBeforeMount(async (): Promise<void> => {
  isLoaded.value = true

  if (store.communityInfo.communityId) {
    getCoverImageUrl()
  }
})
</script>
<style scope="scss">
@import 'mik-community/src/assets/styles/community.scss';
</style>

<style scoped lang="scss"></style>
