import {
  ConsentFormApi,
  ConsentFormModel,
  ConsentFormModelApiResult,
  ConsentFormModelConsentItemListInfoModel,
  ConsentFormRequestModel,
  ContentsHtmlModelApiResult,
  UserAuthorityConstant
} from 'mik-common/src/api-autogen/consentform'

import { AxiosResponse } from 'axios'

export const useConsentFormStore = defineStore('consentform', {
  state: () => {
    return {
      formID: '',
      hasContent: false,
      contents: '',
      searchOption: {
        isAdmin: true,
        pageNum: 1,
        pageSize: 15,
        searchKeyword: '',
        searchType: 'All'
      } as ConsentFormRequestModel,
      consentFormList: {} as ConsentFormModelConsentItemListInfoModel,
      consentForm: {} as ConsentFormModel,
      authLevel: 0 as UserAuthorityConstant
    }
  },
  getters: {
    isEdit(state): boolean {
      return state.authLevel >= UserAuthorityConstant.USER_AUTH_READWRITE
    },
    isAdmin(state): boolean {
      return state.authLevel >= UserAuthorityConstant.USER_AUTH_MANAGER
    }
  },
  actions: {
    async getContentsHtml(formID: string | undefined) {
      const api = new ConsentFormApi()
      const response: AxiosResponse<ContentsHtmlModelApiResult> = await api.v1ConsentFormContentsGet(formID)

      this.formID = formID ?? ''
      if (response.data.success && response.data.data) {
        this.contents = response.data.data.contentsHtml ?? ''
        this.hasContent = response.data.data.hasContent ?? false
        this.authLevel = response.data.userAuthority as UserAuthorityConstant
      } else {
        this.authLevel = 0
        this.hasContent = false
      }
    },
    async getConsentFormList(request: ConsentFormRequestModel) {
      const api = new ConsentFormApi()
      const response = await api.v1ConsentFormListPost(request)

      if (response.data.success) {
        this.authLevel = response.data.userAuthority ?? UserAuthorityConstant.USER_AUTH_NONE
        Object.assign(this.consentFormList, response.data.data ?? {})
      } else {
        this.authLevel = UserAuthorityConstant.USER_AUTH_NONE
      }
    },
    async getConsentFormView(formID: string) {
      const api = new ConsentFormApi()
      const response: AxiosResponse<ConsentFormModelApiResult> = await api.v1ConsentFormFormIDGet(formID)

      if (response.data.success) {
        if (response.data.data) {
          this.authLevel = response.data.userAuthority ?? UserAuthorityConstant.USER_AUTH_NONE
          Object.assign(this.consentForm, response.data.data ?? {})
        } else {
          this.authLevel = UserAuthorityConstant.USER_AUTH_NONE
        }
      } else {
        this.authLevel = UserAuthorityConstant.USER_AUTH_NONE
      }
    }
  }
})
