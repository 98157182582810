/* tslint:disable */
/* eslint-disable */
/**
 * Mik.SmartPortal.Portal.App
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import type { RequestArgs } from '../base';
// @ts-ignore
import type { ApiResult } from '../models';
// @ts-ignore
import type { BoardInfoModelApiResult } from '../models';
// @ts-ignore
import type { FieldInfoModelListApiResult } from '../models';
// @ts-ignore
import type { FieldMultiFilterModelListApiResult } from '../models';
// @ts-ignore
import type { GuidApiResult } from '../models';
/**
 * BoardApi - axios parameter creator
 * @export
 */
export const BoardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 게시판 필드 중 선택 필드의 키-값 리스트를 조회합니다.
         * @param {string} boardStaticName 
         * @param {string} fieldId 
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardBoardStaticNameFieldIdChoiceGet: async (boardStaticName: string, fieldId: string, langCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1BoardBoardStaticNameFieldIdChoiceGet', 'boardStaticName', boardStaticName)
            // verify required parameter 'fieldId' is not null or undefined
            assertParamExists('v1BoardBoardStaticNameFieldIdChoiceGet', 'fieldId', fieldId)
            const localVarPath = `/v1/board/{boardStaticName}/{fieldId}/choice`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)))
                .replace(`{${"fieldId"}}`, encodeURIComponent(String(fieldId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (langCode !== undefined) {
                localVarQueryParameter['langCode'] = langCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 게시판 - 사용자 필터멀티검색 데이터 저장
         * @param {string} boardStaticName 
         * @param {number} fieldId 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardBoardStaticNameFieldIdChoicePost: async (boardStaticName: string, fieldId: number, requestBody?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1BoardBoardStaticNameFieldIdChoicePost', 'boardStaticName', boardStaticName)
            // verify required parameter 'fieldId' is not null or undefined
            assertParamExists('v1BoardBoardStaticNameFieldIdChoicePost', 'fieldId', fieldId)
            const localVarPath = `/v1/board/{boardStaticName}/{fieldId}/choice`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)))
                .replace(`{${"fieldId"}}`, encodeURIComponent(String(fieldId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 게시판 필드 정보를 조회합니다.
         * @param {string} boardStaticName 
         * @param {string} [databaseId] 
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardBoardStaticNameFieldsGet: async (boardStaticName: string, databaseId?: string, langCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1BoardBoardStaticNameFieldsGet', 'boardStaticName', boardStaticName)
            const localVarPath = `/v1/board/{boardStaticName}/fields`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (databaseId !== undefined) {
                localVarQueryParameter['databaseId'] = databaseId;
            }

            if (langCode !== undefined) {
                localVarQueryParameter['langCode'] = langCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 게시판 상세 정보를 조회합니다.
         * @param {string} boardStaticName 
         * @param {string} [databaseId] 
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardBoardStaticNameGet: async (boardStaticName: string, databaseId?: string, langCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1BoardBoardStaticNameGet', 'boardStaticName', boardStaticName)
            const localVarPath = `/v1/board/{boardStaticName}`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (databaseId !== undefined) {
                localVarQueryParameter['databaseId'] = databaseId;
            }

            if (langCode !== undefined) {
                localVarQueryParameter['langCode'] = langCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 게시판 조회
         * @param {string} boardStaticName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardBoardStaticNameIdGet: async (boardStaticName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1BoardBoardStaticNameIdGet', 'boardStaticName', boardStaticName)
            const localVarPath = `/v1/board/{boardStaticName}/id`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary staticName으로 boardId를 포함한 상세 정보를 조회합니다.
         * @param {string} boardStaticName 
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardBoardStaticNameInfoGet: async (boardStaticName: string, langCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1BoardBoardStaticNameInfoGet', 'boardStaticName', boardStaticName)
            const localVarPath = `/v1/board/{boardStaticName}/info`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (langCode !== undefined) {
                localVarQueryParameter['langCode'] = langCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 게시판 개인설정을 초기화합니다.
         * @param {string} boardStaticName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardBoardStaticNameResetBoardPersonalizationPost: async (boardStaticName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1BoardBoardStaticNameResetBoardPersonalizationPost', 'boardStaticName', boardStaticName)
            const localVarPath = `/v1/board/{boardStaticName}/ResetBoardPersonalization`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 게시판 개인설정을 세팅합니다.
         * @param {string} boardStaticName 
         * @param {string} [optionName] 
         * @param {string} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardBoardStaticNameSetBoardPersonalizationPost: async (boardStaticName: string, optionName?: string, value?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1BoardBoardStaticNameSetBoardPersonalizationPost', 'boardStaticName', boardStaticName)
            const localVarPath = `/v1/board/{boardStaticName}/SetBoardPersonalization`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (optionName !== undefined) {
                localVarQueryParameter['optionName'] = optionName;
            }

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BoardApi - functional programming interface
 * @export
 */
export const BoardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BoardApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 게시판 필드 중 선택 필드의 키-값 리스트를 조회합니다.
         * @param {string} boardStaticName 
         * @param {string} fieldId 
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BoardBoardStaticNameFieldIdChoiceGet(boardStaticName: string, fieldId: string, langCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FieldMultiFilterModelListApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BoardBoardStaticNameFieldIdChoiceGet(boardStaticName, fieldId, langCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 게시판 - 사용자 필터멀티검색 데이터 저장
         * @param {string} boardStaticName 
         * @param {number} fieldId 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BoardBoardStaticNameFieldIdChoicePost(boardStaticName: string, fieldId: number, requestBody?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BoardBoardStaticNameFieldIdChoicePost(boardStaticName, fieldId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 게시판 필드 정보를 조회합니다.
         * @param {string} boardStaticName 
         * @param {string} [databaseId] 
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BoardBoardStaticNameFieldsGet(boardStaticName: string, databaseId?: string, langCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FieldInfoModelListApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BoardBoardStaticNameFieldsGet(boardStaticName, databaseId, langCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 게시판 상세 정보를 조회합니다.
         * @param {string} boardStaticName 
         * @param {string} [databaseId] 
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BoardBoardStaticNameGet(boardStaticName: string, databaseId?: string, langCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoardInfoModelApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BoardBoardStaticNameGet(boardStaticName, databaseId, langCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 게시판 조회
         * @param {string} boardStaticName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BoardBoardStaticNameIdGet(boardStaticName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BoardBoardStaticNameIdGet(boardStaticName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary staticName으로 boardId를 포함한 상세 정보를 조회합니다.
         * @param {string} boardStaticName 
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BoardBoardStaticNameInfoGet(boardStaticName: string, langCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoardInfoModelApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BoardBoardStaticNameInfoGet(boardStaticName, langCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 게시판 개인설정을 초기화합니다.
         * @param {string} boardStaticName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BoardBoardStaticNameResetBoardPersonalizationPost(boardStaticName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BoardBoardStaticNameResetBoardPersonalizationPost(boardStaticName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 게시판 개인설정을 세팅합니다.
         * @param {string} boardStaticName 
         * @param {string} [optionName] 
         * @param {string} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BoardBoardStaticNameSetBoardPersonalizationPost(boardStaticName: string, optionName?: string, value?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BoardBoardStaticNameSetBoardPersonalizationPost(boardStaticName, optionName, value, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BoardApi - factory interface
 * @export
 */
export const BoardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BoardApiFp(configuration)
    return {
        /**
         * 
         * @summary 게시판 필드 중 선택 필드의 키-값 리스트를 조회합니다.
         * @param {string} boardStaticName 
         * @param {string} fieldId 
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardBoardStaticNameFieldIdChoiceGet(boardStaticName: string, fieldId: string, langCode?: string, options?: any): AxiosPromise<FieldMultiFilterModelListApiResult> {
            return localVarFp.v1BoardBoardStaticNameFieldIdChoiceGet(boardStaticName, fieldId, langCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 게시판 - 사용자 필터멀티검색 데이터 저장
         * @param {string} boardStaticName 
         * @param {number} fieldId 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardBoardStaticNameFieldIdChoicePost(boardStaticName: string, fieldId: number, requestBody?: Array<string>, options?: any): AxiosPromise<ApiResult> {
            return localVarFp.v1BoardBoardStaticNameFieldIdChoicePost(boardStaticName, fieldId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 게시판 필드 정보를 조회합니다.
         * @param {string} boardStaticName 
         * @param {string} [databaseId] 
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardBoardStaticNameFieldsGet(boardStaticName: string, databaseId?: string, langCode?: string, options?: any): AxiosPromise<FieldInfoModelListApiResult> {
            return localVarFp.v1BoardBoardStaticNameFieldsGet(boardStaticName, databaseId, langCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 게시판 상세 정보를 조회합니다.
         * @param {string} boardStaticName 
         * @param {string} [databaseId] 
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardBoardStaticNameGet(boardStaticName: string, databaseId?: string, langCode?: string, options?: any): AxiosPromise<BoardInfoModelApiResult> {
            return localVarFp.v1BoardBoardStaticNameGet(boardStaticName, databaseId, langCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 게시판 조회
         * @param {string} boardStaticName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardBoardStaticNameIdGet(boardStaticName: string, options?: any): AxiosPromise<GuidApiResult> {
            return localVarFp.v1BoardBoardStaticNameIdGet(boardStaticName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary staticName으로 boardId를 포함한 상세 정보를 조회합니다.
         * @param {string} boardStaticName 
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardBoardStaticNameInfoGet(boardStaticName: string, langCode?: string, options?: any): AxiosPromise<BoardInfoModelApiResult> {
            return localVarFp.v1BoardBoardStaticNameInfoGet(boardStaticName, langCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 게시판 개인설정을 초기화합니다.
         * @param {string} boardStaticName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardBoardStaticNameResetBoardPersonalizationPost(boardStaticName: string, options?: any): AxiosPromise<ApiResult> {
            return localVarFp.v1BoardBoardStaticNameResetBoardPersonalizationPost(boardStaticName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 게시판 개인설정을 세팅합니다.
         * @param {string} boardStaticName 
         * @param {string} [optionName] 
         * @param {string} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardBoardStaticNameSetBoardPersonalizationPost(boardStaticName: string, optionName?: string, value?: string, options?: any): AxiosPromise<ApiResult> {
            return localVarFp.v1BoardBoardStaticNameSetBoardPersonalizationPost(boardStaticName, optionName, value, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BoardApi - object-oriented interface
 * @export
 * @class BoardApi
 * @extends {BaseAPI}
 */
export class BoardApi extends BaseAPI {
    /**
     * 
     * @summary 게시판 필드 중 선택 필드의 키-값 리스트를 조회합니다.
     * @param {string} boardStaticName 
     * @param {string} fieldId 
     * @param {string} [langCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardApi
     */
    public v1BoardBoardStaticNameFieldIdChoiceGet(boardStaticName: string, fieldId: string, langCode?: string, options?: AxiosRequestConfig) {
        return BoardApiFp(this.configuration).v1BoardBoardStaticNameFieldIdChoiceGet(boardStaticName, fieldId, langCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 게시판 - 사용자 필터멀티검색 데이터 저장
     * @param {string} boardStaticName 
     * @param {number} fieldId 
     * @param {Array<string>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardApi
     */
    public v1BoardBoardStaticNameFieldIdChoicePost(boardStaticName: string, fieldId: number, requestBody?: Array<string>, options?: AxiosRequestConfig) {
        return BoardApiFp(this.configuration).v1BoardBoardStaticNameFieldIdChoicePost(boardStaticName, fieldId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 게시판 필드 정보를 조회합니다.
     * @param {string} boardStaticName 
     * @param {string} [databaseId] 
     * @param {string} [langCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardApi
     */
    public v1BoardBoardStaticNameFieldsGet(boardStaticName: string, databaseId?: string, langCode?: string, options?: AxiosRequestConfig) {
        return BoardApiFp(this.configuration).v1BoardBoardStaticNameFieldsGet(boardStaticName, databaseId, langCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 게시판 상세 정보를 조회합니다.
     * @param {string} boardStaticName 
     * @param {string} [databaseId] 
     * @param {string} [langCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardApi
     */
    public v1BoardBoardStaticNameGet(boardStaticName: string, databaseId?: string, langCode?: string, options?: AxiosRequestConfig) {
        return BoardApiFp(this.configuration).v1BoardBoardStaticNameGet(boardStaticName, databaseId, langCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 게시판 조회
     * @param {string} boardStaticName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardApi
     */
    public v1BoardBoardStaticNameIdGet(boardStaticName: string, options?: AxiosRequestConfig) {
        return BoardApiFp(this.configuration).v1BoardBoardStaticNameIdGet(boardStaticName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary staticName으로 boardId를 포함한 상세 정보를 조회합니다.
     * @param {string} boardStaticName 
     * @param {string} [langCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardApi
     */
    public v1BoardBoardStaticNameInfoGet(boardStaticName: string, langCode?: string, options?: AxiosRequestConfig) {
        return BoardApiFp(this.configuration).v1BoardBoardStaticNameInfoGet(boardStaticName, langCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 게시판 개인설정을 초기화합니다.
     * @param {string} boardStaticName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardApi
     */
    public v1BoardBoardStaticNameResetBoardPersonalizationPost(boardStaticName: string, options?: AxiosRequestConfig) {
        return BoardApiFp(this.configuration).v1BoardBoardStaticNameResetBoardPersonalizationPost(boardStaticName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 게시판 개인설정을 세팅합니다.
     * @param {string} boardStaticName 
     * @param {string} [optionName] 
     * @param {string} [value] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardApi
     */
    public v1BoardBoardStaticNameSetBoardPersonalizationPost(boardStaticName: string, optionName?: string, value?: string, options?: AxiosRequestConfig) {
        return BoardApiFp(this.configuration).v1BoardBoardStaticNameSetBoardPersonalizationPost(boardStaticName, optionName, value, options).then((request) => request(this.axios, this.basePath));
    }
}
