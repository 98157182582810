import type { RouteLocationNormalized } from 'vue-router'

/** 게시판 모듈의 url prefix 처리용 router meta 데이터 */
const routeCommonMeta = { smartPortalModulePath: import.meta.env.V_PORTAL_PATH }

const routers = [
  {
    name: 'BoardList',
    path: '/Board/:boardName',
    component: () => import('mik-board/src/pages/user/board/list/List.vue'),
    meta: routeCommonMeta
  },
  {
    path: '/Board/:boardName/View',
    redirect: (to: RouteLocationNormalized) => {
      return {
        name: 'BoardView',
        params: { boardName: to.params.boardName as string, boardItemId: to.query.ItemID as string },
        query: {},
        meta: routeCommonMeta
      }
    },
    meta: routeCommonMeta
  },
  {
    name: 'BoardView',
    path: '/Board/:boardName/View/:boardItemId',
    component: () => import('mik-board/src/pages/user/board/view/View.vue'),
    meta: routeCommonMeta
  },
  {
    name: 'BoardEdit',
    path: '/Board/:boardName/Edit/:boardItemId?',
    component: () => import('mik-board/src/pages/user/board/edit/edit.vue'),
    meta: routeCommonMeta
  },
  {
    name: 'GalleryList',
    path: '/Gallery/:boardName',
    component: () => import('mik-board/src/pages/user/gallery/list/List.vue'),
    meta: routeCommonMeta
  },
  {
    path: '/Gallery/:boardName/View',
    redirect: (to: RouteLocationNormalized) => {
      return {
        name: 'GalleryView',
        params: { boardName: to.params.boardName as string, boardItemId: to.query.ItemID as string },
        query: {},
        meta: routeCommonMeta
      }
    },
    meta: routeCommonMeta
  },
  {
    name: 'GalleryView',
    path: '/Gallery/:boardName/View/:boardItemId',
    component: () => import('mik-board/src/pages/user/gallery/view/View.vue'),
    meta: routeCommonMeta
  },
  {
    name: 'GalleryEdit',
    path: '/Gallery/:boardName/Edit/:boardItemId?',
    component: () => import('mik-board/src/pages/user/gallery/edit/edit.vue'),
    meta: routeCommonMeta
  },
  {
    name: 'SchedulerList',
    path: '/Scheduler/:boardName',
    component: () => import('mik-board/src/pages/user/scheduler/CalendarMain.vue'),
    meta: routeCommonMeta
  },
  {
    name: 'SchedulerPopupView',
    path: '/Scheduler/:boardName/:scheduleId',
    component: () => import('mik-board/src/pages/user/scheduler/popup/ScheduleViewPopup.vue'),
    meta: routeCommonMeta
  },
  {
    path: '/Static/View/:siteStaticName/:sectionStaticName',
    name: 'StaticView',
    component: () => import('mik-board/src/pages/user/static/View.vue'),
    meta: routeCommonMeta
  },
  {
    name: 'MediaManagementList',
    path: '/Media/Management',
    component: () => import('mik-board/src/pages/user/media/management/List.vue'),
    meta: routeCommonMeta
  },
  {
    name: 'MediaManagementEdit',
    path: '/Media/Management/Edit',
    component: () => import('mik-board/src/pages/user/media/management/Edit.vue'),
    meta: routeCommonMeta
  },
  {
    name: 'MediaManagementView',
    path: '/Media/Management/View',
    component: () => import('mik-board/src/pages/user/media/management/View.vue'),
    meta: routeCommonMeta
  },
  {
    name: 'SectionBoardList',
    path: '/SectionBoard/:section/List',
    component: () => import('mik-board/src/pages/user/sectionBoard/list/List.vue'),
    meta: routeCommonMeta
  },
  {
    name: 'MyArticleList',
    path: '/MyArticle/List',
    component: () => import('mik-board/src/pages/user/myarticle/list/List.vue'),
    meta: routeCommonMeta,
    children: [
      {
        name: 'MyArticleList.Article',
        path: 'Article',
        meta: {
          temporaryArticle: 'N'
        },
        component: () => import('mik-board/src/pages/user/myarticle/list/List.vue')
      },
      {
        name: 'MyArticleList.Article.Section',
        path: 'Article/:section',
        meta: {
          temporaryArticle: 'N'
        },
        component: () => import('mik-board/src/pages/user/myarticle/list/List.vue')
      },
      {
        name: 'MyArticleList.Temporary',
        path: 'Temporary',
        meta: {
          temporaryArticle: 'Y'
        },
        component: () => import('mik-board/src/pages/user/myarticle/list/List.vue')
      },
      {
        name: 'MyArticleList.Temporary.Section',
        path: 'Temporary/:section',
        meta: {
          temporaryArticle: 'Y'
        },
        component: () => import('mik-board/src/pages/user/myarticle/list/List.vue')
      }
    ]
  }
]

export default routers
