import {
  CommunityInfoModel,
  CommunitySummaryModel,
  BoardLayoutInfoModel,
  LnbWidgetModel,
  RecentItemModel,
  ResultCommunityNoticeModel,
  EventDayInfoModel,
  AdminInfoModel
} from 'mik-common/src/api-autogen/community'
import { HomeApi } from 'mik-common/src/api-autogen/community'
import { DropDownOption } from 'mik-common/src/components/common/MikSelectDropdown.vue'
import { getThumbnailPath } from 'mik-community/src/utils/util'

export const useCommunityStore = defineStore('community', {
  persist: [
    {
      paths: ['headerImageUrl', 'headerHeight'],
      storage: localStorage
    }
  ],
  state: () => {
    return {
      communityInfo: {} as CommunityInfoModel,
      myCommunityList: [] as CommunitySummaryModel[],
      boardLayoutInfo: {} as BoardLayoutInfoModel,
      lnbWidgetList: [] as LnbWidgetModel[],
      lnbRecentItemList: [] as RecentItemModel[],
      notice: { communityNoticeItemList: [] } as ResultCommunityNoticeModel,
      adminInfo: {} as AdminInfoModel,
      headerImageUrl: '',
      headerHeight: 200,
      eventDayList: [] as EventDayInfoModel[]
    }
  },
  getters: {
    communityStaticName(state): string {
      if (state.communityInfo.relativeUrl) {
        return state.communityInfo.relativeUrl
      } else {
        return ''
      }
    },
    myCommunityDropDownOption(state): DropDownOption[] {
      const options: DropDownOption[] = []
      if (state.myCommunityList.length > 0) {
        state.myCommunityList.forEach((community) => {
          options.push({
            value: community.relativeUrl ?? '',
            label: community.title ?? ''
          })
        })
      }
      return options
    },
    communityMenuInfo(state): BoardLayoutInfoModel {
      return state.boardLayoutInfo
    },
    theme(state): string {
      return state.communityInfo.themeColor ?? ''
    },
    isAdmin(state): boolean {
      return state.adminInfo.isAdmin ?? false
    }
  },
  actions: {
    /**
     * Initialize Community GNB, LNB info
     * @param communityRelativeUrl
     */
    async initCommunity(communityRelativeUrl: string) {
      const api = new HomeApi()
      await api
        .v1HomeInitializeGet(communityRelativeUrl)
        .then(async (response) => {
          // set GNB
          const communityInfo = response.data.data?.communityInfo
          if (communityInfo) {
            // set Banner
            this.communityInfo = communityInfo
            this.communityInfo.bannerImageUrl = await getThumbnailPath(communityInfo.relativeUrl ?? '', 'banner')
            // set Cover
            this.headerImageUrl = await getThumbnailPath(communityInfo.relativeUrl ?? '', 'cover')
          }
          // set my community list
          const myCommunityList = response.data.data?.communitySummaryList
          if (myCommunityList && myCommunityList.length > 0) {
            this.myCommunityList = myCommunityList
          }
          // set LNB widget
          const lnbWidgetList = response.data.data?.lnbWidgetList
          if (lnbWidgetList) {
            this.lnbWidgetList = lnbWidgetList
          }
          // set LNB Category
          const boardLayoutInfo = response.data.data?.boardLayoutInfo
          if (boardLayoutInfo) {
            this.boardLayoutInfo = boardLayoutInfo
          }
          // set LNB recent board items
          const lnbRecentItemList = response.data.data?.lnbRecentItemList
          if (lnbRecentItemList) {
            this.lnbRecentItemList = lnbRecentItemList
          }

          // set Notice list
          const noticeInfo = response.data.data?.communityNoticeInfo
          if (noticeInfo) {
            this.notice = noticeInfo
          }
          const eventDays = response.data.data?.eventDays
          if (eventDays) {
            this.eventDayList = eventDays
          }
          // set admin
          const adminInfo = response.data.data?.adminInfoModel
          if (adminInfo) {
            this.adminInfo = adminInfo
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },

    async getCommunityInfo(communityStaticName: string) {
      if (this.$state.communityInfo.relativeUrl?.toLocaleLowerCase() !== communityStaticName.toLocaleLowerCase()) {
        // const api = new CommunityApi()
        // const response: AxiosResponse<CommunityEntityListApiResult> =
        //   await api.v1CommunityInfoCommunityStaticNameGet(communityStaticName)
        // if (response.data.success) {
        //   Object.assign(this.communityInfo, JSON.parse(JSON.stringify(response.data.data))[0])
        //   this.headerImageUrl = '/CommunityRest/v1/community/cover/get/' + this.communityInfo.communityID
        // } else {
        //   Object.assign(this.$state.communityInfo, {})
        // }
      }
    },
    settingHeaderHeight() {
      if (this.headerHeight === 200) {
        this.headerHeight = 400
      } else {
        this.headerHeight = 200
      }
    }
  }
})
