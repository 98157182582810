<template>
  <q-expansion-item :model-value="true" class="lnb_category">
    <template #header>
      <div class="self-center text-subtitle1">{{ $t('Community_Common_CommunityCategory', '카테고리') }}</div>
    </template>
    <q-scroll-area
      :style="'height:' + (20 + lnbCategoryTreeScroll.height.value) + 'px;'"
      class="q-ml-md"
      :vertical-thumb-style="{ opacity: '0' }">
      <q-tree
        ref="lnbCategoryTree"
        v-model:expanded="menuExpanded"
        v-model:selected="lnbCategoryTreeSeleced"
        :nodes="menuDataList"
        node-key="layoutID"
        class="text-body2"
        label-key="text"
        children-key="children"
        selected-color="primary"
        no-connectors
        @update:selected="handleTreeClick(lnbCategoryTreeSeleced)" />
    </q-scroll-area>
  </q-expansion-item>
</template>

<script setup lang="ts">
import { useCommunityStore } from 'mik-community/src/store/community'
import { pushToBoard } from 'mik-community/src/utils/util'
import { useElementSize } from '@vueuse/core'

// 라우터
const router = useRouter()

const store = useCommunityStore()

const lnbCategoryTree = ref()
const lnbCategoryTreeSeleced = ref('' as string | null)

const menuDataList = ref<[]>([])
const menuExpanded = ref<[]>([])

// tree 길이 실시간 반영
const lnbCategoryTreeScroll = computed(() => {
  return useElementSize(lnbCategoryTree)
})

const handleTreeClick = (layoutId: string | null) => {
  const select = lnbCategoryTree.value.getNodeByKey(layoutId)

  if (select.boardUrl) {
    pushToBoard(router, store.communityInfo.relativeUrl ?? '', select.boardUrl)
  }

  lnbCategoryTreeSeleced.value = null
}

const setTreeData = () => {
  // 메뉴 리스트
  if (store.communityMenuInfo.boardLayoutList) {
    menuDataList.value = [...store.communityMenuInfo.boardLayoutList] as []
  } else {
    menuDataList.value = []
  }
  // 확장 리스트
  if (store.communityMenuInfo.boardLayoutExpanded) {
    menuExpanded.value = [...store.communityMenuInfo.boardLayoutExpanded] as []
  } else {
    menuExpanded.value = []
  }
}

setTreeData()
</script>

<style scoped></style>
