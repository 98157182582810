<template>
  <div class="community-manage-lnb">
    <community-info @go-management="handleManagementClick" @set-cover="settingCoverImage" />
    <!-- start of 커뮤니티 목록 -->
    <div class="col select_area" :class="themeClass">
      <q-card class="my-card text-white">
        <mik-drop-down
          v-model="dropDownModel"
          :label="$t('Community_Menu_MyCommunity', '내가 속한 커뮤니티')"
          :drop-down-options="store.myCommunityDropDownOption"
          :value="''"
          @update:model-value="changeMyCommunityDropDown"
      /></q-card>
    </div>
    <!-- end of 커뮤니티 목록 -->

    <!-- start of 위젯 -->
    <div>
      <!--CategoryTemplate, RecentItemTemplate 표시-->

      <q-expansion-item :model-value="true" class="lnb_category">
        <template #header>
          <div class="lnb_menu_header">{{ $t('Category', '카테고리') }}</div>
        </template>
        <q-scroll-area :style="'height:' + (20 + lnbPostListScroll.height.value) + 'px;'">
          <q-list ref="lnbPostList">
            <q-item>
              <a href="javascript:;" @click="router.push({ name: 'Community.Management.Information' })">
                <span class="txt">{{ $t('Community_Info', '커뮤니티 정보') }}</span>
              </a>
            </q-item>
            <q-item>
              <a href="javascript:;" @click="router.push({ name: 'Community.Management.Member' })">
                <span class="txt">{{ $t('MemberManagement', '회원 관리') }}</span>
              </a>
            </q-item>
            <q-item>
              <a href="javascript:;" @click="router.push({ name: 'Community.Management.Group' })">
                <span class="txt">{{ $t('GroupManagement', '그룹 관리') }}</span>
              </a>
            </q-item>
            <q-item>
              <a href="javascript:;" @click="router.push({ name: 'Community.Management.Join' })">
                <span class="txt">{{ $t('Community_Management_Join_Approve', '가입 승인') }}</span>
              </a>
            </q-item>
            <q-item>
              <a href="javascript:;" @click="router.push({ name: 'Community.Management.LocalMenu' })">
                <span class="txt">{{ $t('Menu_Management', '메뉴 관리') }}</span>
              </a>
            </q-item>
            <q-item>
              <a href="javascript:;" @click="router.push({ name: 'Community.Management.Home' })">
                <span class="txt">{{ $t('MainManagement', '메인 화면 관리') }}</span>
              </a>
            </q-item>
            <q-item>
              <a href="javascript:;" @click="router.push({ name: 'Community.Management.Theme' })">
                <span class="txt">{{ $t('ThemeManagement', '테마 설정') }}</span>
              </a>
            </q-item>
            <q-item>
              <a href="javascript:;" @click="router.push({ name: 'Community.Management.Stats' })">
                <span class="txt">{{ $t('CommunityStatistics', '커뮤니티 통계') }}</span>
              </a>
            </q-item>
            <q-item>
              <a href="javascript:;" @click="router.push({ name: 'Community.Home' })">
                <span class="txt">{{ $t('Homepage', '홈페이지') }}</span>
              </a>
            </q-item>
          </q-list>
        </q-scroll-area>
      </q-expansion-item>
    </div>
  </div>
</template>

<script setup lang="ts">
import CommunityInfo from 'mik-community/src/components/lnb/CommunityInfo.vue'

import { useElementSize } from '@vueuse/core'
import MikDropDown, { DropDownOption } from 'mik-common/src/components/common/MikSelectDropdown.vue'
import { useCommunityStore } from 'mik-community/src/store/community'
import { getThemeClass } from 'mik-community/src/utils/util'

const store = useCommunityStore()
const $emit = defineEmits(['go-management', 'set-cover'])
const router = useRouter()
const themeClass = ref(getThemeClass('lnb'))

const lnbPostList = ref()
const { t } = useI18n()

const dropDownModel = ref<DropDownOption>({
  value: '',
  label: t('Community_Menu_MyCommunity', '내가 속한 커뮤니티')
})

const changeMyCommunityDropDown = (option: DropDownOption): void => {
  dropDownModel.value.value = option.value
  dropDownModel.value.label = option.label

  /**
   * To-Do
   * 현재는 새창 띄우기로 동작 (as-is 동일)
   * 페이지 이동으로 변경 시 router.push를 사용하는 경우 Gnb, Lnb 영역 Refresh가 되지 않음 (필요시 개선)
   */

  const url = `${import.meta.env.V_COMMUNITY_PATH}/${option.value}`
  window.open(url, '_blank')
}

// tree 길이 실시간 반영
const lnbPostListScroll = computed(() => {
  return useElementSize(lnbPostList)
})
const handleManagementClick = () => {
  $emit('go-management')
}
const settingCoverImage = () => {
  $emit('set-cover')
}

onBeforeMount(async () => {})
watch(
  () => store.theme,
  (newVal, oldVal) => {
    themeClass.value = getThemeClass('lnb')
  }
)
</script>
<style scoped lang="scss">
.community-manage-lnb {
  .select_area {
    padding: 15px 20px 15px 20px;
    background-color: #444;

    .q-field {
      padding: 0px 10px 5px 10px;
    }
  }

  .q-expansion-item {
    padding: 10px 5px 5px 5px;

    .lnb_menu_header {
      width: 100%;
      line-height: 1.33em;
      font-size: 14px;
      font-weight: 700;
    }

    .q-scrollarea {
      padding: 0px 20px 0px 10px;
    }

    .q-list {
      padding: 0px 20px 0px 0px;

      .q-item {
        line-height: 1.4;
        font-size: 15px;
        font-weight: 400;
        white-space: nowrap;
      }
    }
  }
}
</style>
