<template>
  <!-- <teleport to="body"> -->
  <div
    v-if="props.modelValue"
    ref="profileCard"
    class="user-info-detail"
    tabindex="0"
    :style="`top: ${props.position.y}px; left: ${props.position.x}px;`">
    <div class="user-profile-popover" :class="themeClass">
      <div class="close-btn" @click="close">
        <q-icon name="close" />
      </div>
      <div class="user-profile-info" :class="themeTextClass">
        <user-profile :user-id="props.userId"></user-profile>
        <div class="user-profile-info_props">
          <p class="username-first">
            <b>{{ profileInfo?.displayName }}</b>
          </p>
          <p class="username-last">{{ profileInfo?.deptName }}</p>
        </div>
      </div>
    </div>
    <div class="user-profile-popover_menu">
      <ul class="user-profile-menu-list">
        <!-- 이메일주소 -->
        <li class="user-profile-menu-li cursor-pointer">
          <div class="icon-profile-container">
            <q-icon :color="themeIconClass" name="email" />
          </div>
          <span class="user-profile-menu-text">
            {{ profileInfo?.emailAddress }}
          </span>
          <span class="user-profile-copy-btn" @click="copyData(profileInfo?.emailAddress)"> ({{ $t('Copy') }}) </span>
        </li>
        <!-- 협업 메세지 -->
        <li class="user-profile-menu-li cursor-pointer">
          <div class="icon-profile-container">
            <q-icon :color="themeIconClass" name="chat" />
          </div>
          <span class="user-profile-menu-text" @click="openChatPage()"> 1:1 {{ $t('Chat', '채팅') }} </span>
        </li>
        <!-- 가입일 -->
        <li class="user-profile-menu-li">
          <div class="icon-profile-container">
            <q-icon :color="themeIconClass" name="verified_user" />
          </div>
          <span class="user-profile-menu-text">
            {{ $t('JoinDate', '가입일') }}: {{ moment(profileInfo?.joinCommunityDate).format('YYYY-MM-DD') }}
          </span>
        </li>
        <!-- 방문일 -->
        <li class="user-profile-menu-li">
          <div class="icon-profile-container">
            <q-icon :color="themeIconClass" name="work_history" />
          </div>
          <span class="user-profile-menu-text">
            {{ $t('RecentVisitDate', '최근 방문일') }}: {{ moment(profileInfo?.recentVisitDate).format('YYYY-MM-DD') }}
          </span>
        </li>
      </ul>
    </div>
    <div class="popper__arrow" style="left: 142.5px"></div>
  </div>
  <!-- </teleport> -->
</template>
<script setup lang="ts">
import UserProfile from 'mik-community/src/components/common/UserProfile.vue'

import { onClickOutside } from '@vueuse/core'

import { useToast } from 'mik-community/src/common/toast'
import { useCommunityStore } from 'mik-community/src/store/community'
import { MemberApi, MemberModel } from 'mik-common/src/api-autogen/community'
import { getThemeClass } from 'mik-community/src/utils/util'
import moment from 'moment'
const store = useCommunityStore()
const { t } = useI18n()
const props = defineProps<{
  position: {
    x: number
    y: number
  }
  userId: string
  modelValue: boolean
}>()

const emit = defineEmits<{ (e: 'update:modelValue', value: boolean): void }>()
const toast = useToast()
const api = new MemberApi()

const profileInfo = ref<MemberModel>()

const getUserProfileInfo = async () => {
  const response = await api.v1MemberGet(store.communityInfo.communityId, props.userId)
  if (response.data.success) {
    profileInfo.value = response.data.data
  }
}

/** 1:1 채팅창 열기 */
const openChatPage = () => {
  api.v1MemberChatUrlGet(props.userId).then((result) => {
    const chatUrl = result.data.data ?? ''
    if (chatUrl == 'Dev is not in service.') {
      toast.info(chatUrl)
      return
    }
    window.open(chatUrl, '_blank')
  })
}

const close = (event: MouseEvent) => {
  emit('update:modelValue', false)
}

const copyData = (value: string | undefined | null) => {
  if (!value) return
  const textArea = document.createElement('textarea')
  textArea.value = value
  document.body.appendChild(textArea)
  textArea.select()
  try {
    document.execCommand('copy')
  } catch (err) {
    // 복사 실패
    toast.error(t('MsgCopyingToClipboardFailed', '클립보드에 복사 실패하였습니다.'))
  }
  toast.info(t('MsgCopiedToClipboard', '클립보드에 복사되었습니다.'))
  document.body.removeChild(textArea)
}

watch(
  () => props.modelValue,
  () => {
    console.log('bbb')
    if (!props.modelValue) return
    getUserProfileInfo()
  }
)

const profileCard = ref(null)
onClickOutside(profileCard, (e: MouseEvent) => {
  close(e)
})

// 팝업 호출 시
// onBeforeUpdate(async () => {
//   getUserProfileInfo()
// })

const themeClass = ref(getThemeClass('lnb'))
const themeIconClass = ref(getThemeClass('icon'))
const themeTextClass = ref(getThemeClass('gnbText'))
</script>
<style scoped lang="scss">
@import 'mik-community/src/assets/styles/_common.scss';

.user-info-detail {
  position: fixed;
  display: inherit;
  box-shadow: 0 2px 12px #0003;
  width: 300px;
  z-index: 9999;
  .user-profile-popover {
    @include column;
    margin-top: -8px;
    padding: 12px;
    // background-color: #f66c51;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    .close-btn {
      align-self: flex-end;
      position: absolute;
      cursor: pointer;
      color: white;
      font-weight: bold;
      size: 15px;
    }
    .user-profile-info {
      @include row;
      padding: 10px;
    }
  }

  .user-profile-info_props {
    p {
      margin: 0;
    }

    @include column($justify: center);
    margin-left: 20px;

    & .username-first {
      font-size: 0.9375rem;
      color: #fff;
    }

    & .username-last {
      margin-top: 3px;
      font-size: 0.8125rem;
      color: #fff;
    }
  }

  .user-profile-popover_menu {
    padding: 12px;
    background-color: #fff;

    .user-profile-menu-list {
      margin: 0;
      padding: 0;
    }

    li {
      @include row($align: center);
      height: 35px;
      padding: 0 10px;
      border-radius: 4px;
      font-size: 0.875rem;
      gap: 10px;
      // cursor: pointer;

      &:hover {
        background-color: #f7f7f7;

        .user-profile-copy-btn {
          color: #f66c51;
          display: block;
        }
      }

      & .user-profile-copy-btn {
        display: none;
      }

      .icon-profile-container {
        @include row($align: center);
        width: 20px;
        height: 20px;
        .icon-profile {
          background-repeat: no-repeat;
          width: 16px;
          height: 14px;
          display: inline-block;
          background-size: 16px;

          &.icon-profile-mail {
          }
          &.icon-profile-tit {
          }
          &.icon-profile-mobiletel {
          }
          &.icon-profile-officetel {
          }
          &.icon-profile-chat {
          }
        }
      }

      .user-profile-menu-text {
        @include row($align: center);
      }
    }
  }

  .popper__arrow {
    border-width: 6px;
    filter: drop-shadow(0 2px 12px rgba(0, 0, 0, 0.03));
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    top: -23.5px;

    &:after {
      border-width: 6px;
      filter: drop-shadow(0 2px 12px rgba(0, 0, 0, 0.03));
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      border-bottom-color: $primary !important;
      margin-left: -6px;
      border-top-width: 0;
      content: ' ';
      border-width: 6px;
    }
  }
}
</style>
