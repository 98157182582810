import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'

import { useBoardStore } from 'mik-board/src/store/board'
import { useGalleryStore } from 'mik-board/src/store/gallery'
import { useStaticStore } from 'mik-board/src/store/static'
import { useModeStore } from 'mik-common/src/store/mode'
import { StaticPageMenuTypes, UserAuthorityConstant } from 'mik-common/src/api-autogen/portal'

const guards = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
  useRouterMerge: boolean
) => {
  try {
    //if (to.name === 'BoardEdit' || to.name === 'BoardView') {
    if (to.name === 'BoardEdit') {
      const boardName: string = to.params?.boardName as string
      const boardItemId: string = (to.params?.boardItemId as string) ?? ''

      const store = useBoardStore()
      await store.getBoardInfoByView(boardName, boardItemId)
      // 편집화면은 30이상
      if (to.name === 'BoardEdit' && store.authLevel < 30) {
        next({
          path: '/Error/401'
        })
      } else if (store.authLevel >= 10) {
        next()
      }
      // 권한 없음.
      else {
        next({
          path: '/Error/401'
        })
      }
    } else if (to.name === 'BoardList') {
      const boardName: string = to.params.boardName as string

      const store = useBoardStore()
      await store.getBoardInfo(boardName, false)
      // 편집화면은 30이상
      if (store.authLevel >= 10) {
        next()
      }
      // 권한 없음.
      else {
        next({
          path: '/Error/401'
        })
      }
    } else if (to.name === 'GalleryEdit' || to.name === 'GalleryView') {
      const boardName: string = to.params?.boardName as string
      const boardItemId: string = (to.params?.boardItemId as string) ?? ''

      const store = useGalleryStore()
      await store.getBoardInfoByView(boardName, boardItemId)

      // 편집화면은 30이상
      if (to.name === 'GalleryEdit' && store.authLevel < 30) {
        next({
          path: '/Error/401'
        })
      } else if (store.authLevel >= 10) {
        next()
      }
      // 권한 없음.
      else {
        next({
          path: '/Error/401'
        })
      }
    } else if (to.name === 'GalleryList') {
      const boardName: string = to.params.boardName as string
      const store = useGalleryStore()
      await store.getBoardInfo(boardName, true)

      // 편집화면은 30이상
      if (store.authLevel >= 10) {
        next()
      }
      // 권한 없음.
      else {
        next({
          path: '/Error/401'
        })
      }
    } else if (to.name === 'StaticView') {
      const siteStaticName = to.params.siteStaticName as string
      const sectionStaticName = to.params.sectionStaticName as string
      const store = useStaticStore()
      const modeStore = useModeStore()

      modeStore.setAlternativeUrl(to, from)

      await store.getStaticPageInfo(siteStaticName, sectionStaticName)

      if (store.authLevel >= UserAuthorityConstant.USER_AUTH_READONLY) {
        modeStore.setGnbMenuMode(store.menuType !== StaticPageMenuTypes.NONE)
        modeStore.setLocalMenuMode(store.menuType === StaticPageMenuTypes.GNBLNB)
        next()
      } else {
        next({
          path: '/Error/401'
        })
      }
    } else if (!useRouterMerge) {
      if (to.name) {
        next()
      } else {
        next(false)
      }
    }
  } catch (error) {
    console.log(error)
    next({
      path: '/Error/401'
    })
  }
}

export default guards
