import { reactive } from 'vue'

const param = reactive({
  visible: false,
  position: {
    x: 0,
    y: 0
  },
  userId: '',
  target: undefined as HTMLElement | undefined
})

const showUserProfileCard = (event: MouseEvent, userId: string) => {
  const target = (param.target = event.currentTarget as HTMLElement)

  const leftGap = -150
  param.position.x = target.getBoundingClientRect().x + target.offsetWidth / 2 + leftGap
  param.position.y = target.getBoundingClientRect().y + target.offsetHeight + 15
  param.userId = userId
  param.visible = true
}

export const useUserProfileCard = () => ({
  param,
  showUserProfileCard
})

const isInViewport = (element: HTMLElement) => {
  const rect = element.getBoundingClientRect()
  console.log(rect)
  console.log(rect.bottom, window.innerHeight, document.documentElement.clientHeight)
  return rect.bottom + 210 < (window.innerHeight || document.documentElement.clientHeight)
}

const scrollHandler = () => {
  const target = param.target
  const leftGap = -150
  if (target != undefined) {
    param.position.x = target.getBoundingClientRect().x + target.offsetWidth / 2 + leftGap
    param.position.y = target.getBoundingClientRect().y + target.offsetHeight + 15
  }
}
watch(
  () => param.visible,
  (v) => {
    if (v) {
      document.addEventListener('scroll', scrollHandler)
    } else {
      document.removeEventListener('scroll', scrollHandler)
    }
  }
)
