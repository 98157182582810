/* tslint:disable */
/* eslint-disable */
/**
 * Mik.SmartPortal.Portal.App
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import type { RequestArgs } from '../base';
// @ts-ignore
import type { ApiResult } from '../models';
// @ts-ignore
import type { BooleanApiResult } from '../models';
// @ts-ignore
import type { CommentContentInfoModel } from '../models';
// @ts-ignore
import type { CommentInfoModelItemListInfoModelApiResult } from '../models';
// @ts-ignore
import type { Int32ApiResult } from '../models';
// @ts-ignore
import type { Int64ApiResult } from '../models';
// @ts-ignore
import type { SettingPasswordModel } from '../models';
/**
 * CommentApi - axios parameter creator
 * @export
 */
export const CommentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 댓글 잠금 (관리자)
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {number} commentId 
         * @param {string} boardType 
         * @param {boolean} [lockStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardTypeCommentBoardStaticNameLockPostIdCommentIdPost: async (boardStaticName: string, postId: string, commentId: number, boardType: string, lockStatus?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1BoardTypeCommentBoardStaticNameLockPostIdCommentIdPost', 'boardStaticName', boardStaticName)
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('v1BoardTypeCommentBoardStaticNameLockPostIdCommentIdPost', 'postId', postId)
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('v1BoardTypeCommentBoardStaticNameLockPostIdCommentIdPost', 'commentId', commentId)
            // verify required parameter 'boardType' is not null or undefined
            assertParamExists('v1BoardTypeCommentBoardStaticNameLockPostIdCommentIdPost', 'boardType', boardType)
            const localVarPath = `/v1/{boardType}/comment/{boardStaticName}/Lock/{postId}/{commentId}`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)))
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)))
                .replace(`{${"commentId"}}`, encodeURIComponent(String(commentId)))
                .replace(`{${"boardType"}}`, encodeURIComponent(String(boardType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lockStatus !== undefined) {
                localVarQueryParameter['lockStatus'] = lockStatus;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 패스워드 변경
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {number} commentId 
         * @param {string} boardType 
         * @param {SettingPasswordModel} [settingPasswordModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardTypeCommentBoardStaticNamePostIdCommentIdChangePasswordPost: async (boardStaticName: string, postId: string, commentId: number, boardType: string, settingPasswordModel?: SettingPasswordModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1BoardTypeCommentBoardStaticNamePostIdCommentIdChangePasswordPost', 'boardStaticName', boardStaticName)
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('v1BoardTypeCommentBoardStaticNamePostIdCommentIdChangePasswordPost', 'postId', postId)
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('v1BoardTypeCommentBoardStaticNamePostIdCommentIdChangePasswordPost', 'commentId', commentId)
            // verify required parameter 'boardType' is not null or undefined
            assertParamExists('v1BoardTypeCommentBoardStaticNamePostIdCommentIdChangePasswordPost', 'boardType', boardType)
            const localVarPath = `/v1/{boardType}/comment/{boardStaticName}/{postId}/{commentId}/changePassword`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)))
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)))
                .replace(`{${"commentId"}}`, encodeURIComponent(String(commentId)))
                .replace(`{${"boardType"}}`, encodeURIComponent(String(boardType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(settingPasswordModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 패스워드 체크
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {number} commentId 
         * @param {string} boardType 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardTypeCommentBoardStaticNamePostIdCommentIdCheckPasswordPost: async (boardStaticName: string, postId: string, commentId: number, boardType: string, body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1BoardTypeCommentBoardStaticNamePostIdCommentIdCheckPasswordPost', 'boardStaticName', boardStaticName)
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('v1BoardTypeCommentBoardStaticNamePostIdCommentIdCheckPasswordPost', 'postId', postId)
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('v1BoardTypeCommentBoardStaticNamePostIdCommentIdCheckPasswordPost', 'commentId', commentId)
            // verify required parameter 'boardType' is not null or undefined
            assertParamExists('v1BoardTypeCommentBoardStaticNamePostIdCommentIdCheckPasswordPost', 'boardType', boardType)
            const localVarPath = `/v1/{boardType}/comment/{boardStaticName}/{postId}/{commentId}/checkPassword`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)))
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)))
                .replace(`{${"commentId"}}`, encodeURIComponent(String(commentId)))
                .replace(`{${"boardType"}}`, encodeURIComponent(String(boardType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 댓글을 삭제합니다.
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {number} commentId 
         * @param {string} boardType 
         * @param {string} [databaseId] 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardTypeCommentBoardStaticNamePostIdCommentIdDelete: async (boardStaticName: string, postId: string, commentId: number, boardType: string, databaseId?: string, body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1BoardTypeCommentBoardStaticNamePostIdCommentIdDelete', 'boardStaticName', boardStaticName)
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('v1BoardTypeCommentBoardStaticNamePostIdCommentIdDelete', 'postId', postId)
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('v1BoardTypeCommentBoardStaticNamePostIdCommentIdDelete', 'commentId', commentId)
            // verify required parameter 'boardType' is not null or undefined
            assertParamExists('v1BoardTypeCommentBoardStaticNamePostIdCommentIdDelete', 'boardType', boardType)
            const localVarPath = `/v1/{boardType}/comment/{boardStaticName}/{postId}/{commentId}`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)))
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)))
                .replace(`{${"commentId"}}`, encodeURIComponent(String(commentId)))
                .replace(`{${"boardType"}}`, encodeURIComponent(String(boardType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (databaseId !== undefined) {
                localVarQueryParameter['databaseId'] = databaseId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 게시글의 댓글을 조회합니다.  댓글ID를 토대로 댓글ID가 있는 페이지를 리턴한다.
         * @param {string} boardStaticName 
         * @param {string} boardType 
         * @param {string} postId 
         * @param {string} [databaseId] 
         * @param {number} [commentId] 
         * @param {number} [size] 
         * @param {number} [pageCount] 
         * @param {string} [langCode] 
         * @param {boolean} [displayMine] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardTypeCommentBoardStaticNamePostIdCommentIdGet: async (boardStaticName: string, boardType: string, postId: string, databaseId?: string, commentId?: number, size?: number, pageCount?: number, langCode?: string, displayMine?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1BoardTypeCommentBoardStaticNamePostIdCommentIdGet', 'boardStaticName', boardStaticName)
            // verify required parameter 'boardType' is not null or undefined
            assertParamExists('v1BoardTypeCommentBoardStaticNamePostIdCommentIdGet', 'boardType', boardType)
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('v1BoardTypeCommentBoardStaticNamePostIdCommentIdGet', 'postId', postId)
            const localVarPath = `/v1/{boardType}/comment/{boardStaticName}/{postId}/commentId`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)))
                .replace(`{${"boardType"}}`, encodeURIComponent(String(boardType)))
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (databaseId !== undefined) {
                localVarQueryParameter['databaseId'] = databaseId;
            }

            if (commentId !== undefined) {
                localVarQueryParameter['commentId'] = commentId;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (pageCount !== undefined) {
                localVarQueryParameter['pageCount'] = pageCount;
            }

            if (langCode !== undefined) {
                localVarQueryParameter['langCode'] = langCode;
            }

            if (displayMine !== undefined) {
                localVarQueryParameter['displayMine'] = displayMine;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 댓글에 댓글을 등록합니다.
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {number} commentId 
         * @param {string} boardType 
         * @param {string} [databaseId] 
         * @param {CommentContentInfoModel} [commentContentInfoModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardTypeCommentBoardStaticNamePostIdCommentIdPost: async (boardStaticName: string, postId: string, commentId: number, boardType: string, databaseId?: string, commentContentInfoModel?: CommentContentInfoModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1BoardTypeCommentBoardStaticNamePostIdCommentIdPost', 'boardStaticName', boardStaticName)
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('v1BoardTypeCommentBoardStaticNamePostIdCommentIdPost', 'postId', postId)
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('v1BoardTypeCommentBoardStaticNamePostIdCommentIdPost', 'commentId', commentId)
            // verify required parameter 'boardType' is not null or undefined
            assertParamExists('v1BoardTypeCommentBoardStaticNamePostIdCommentIdPost', 'boardType', boardType)
            const localVarPath = `/v1/{boardType}/comment/{boardStaticName}/{postId}/{commentId}`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)))
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)))
                .replace(`{${"commentId"}}`, encodeURIComponent(String(commentId)))
                .replace(`{${"boardType"}}`, encodeURIComponent(String(boardType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (databaseId !== undefined) {
                localVarQueryParameter['databaseId'] = databaseId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commentContentInfoModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 댓글을 수정합니다.
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {number} commentId 
         * @param {string} boardType 
         * @param {string} [databaseId] 
         * @param {CommentContentInfoModel} [commentContentInfoModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardTypeCommentBoardStaticNamePostIdCommentIdPut: async (boardStaticName: string, postId: string, commentId: number, boardType: string, databaseId?: string, commentContentInfoModel?: CommentContentInfoModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1BoardTypeCommentBoardStaticNamePostIdCommentIdPut', 'boardStaticName', boardStaticName)
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('v1BoardTypeCommentBoardStaticNamePostIdCommentIdPut', 'postId', postId)
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('v1BoardTypeCommentBoardStaticNamePostIdCommentIdPut', 'commentId', commentId)
            // verify required parameter 'boardType' is not null or undefined
            assertParamExists('v1BoardTypeCommentBoardStaticNamePostIdCommentIdPut', 'boardType', boardType)
            const localVarPath = `/v1/{boardType}/comment/{boardStaticName}/{postId}/{commentId}`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)))
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)))
                .replace(`{${"commentId"}}`, encodeURIComponent(String(commentId)))
                .replace(`{${"boardType"}}`, encodeURIComponent(String(boardType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (databaseId !== undefined) {
                localVarQueryParameter['databaseId'] = databaseId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commentContentInfoModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 게시글의 댓글을 조회합니다.
         * @param {string} boardStaticName 
         * @param {string} boardType 
         * @param {string} postId 
         * @param {string} [databaseId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {number} [pageCount] 
         * @param {string} [langCode] 
         * @param {boolean} [displayMine] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardTypeCommentBoardStaticNamePostIdGet: async (boardStaticName: string, boardType: string, postId: string, databaseId?: string, page?: number, size?: number, pageCount?: number, langCode?: string, displayMine?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1BoardTypeCommentBoardStaticNamePostIdGet', 'boardStaticName', boardStaticName)
            // verify required parameter 'boardType' is not null or undefined
            assertParamExists('v1BoardTypeCommentBoardStaticNamePostIdGet', 'boardType', boardType)
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('v1BoardTypeCommentBoardStaticNamePostIdGet', 'postId', postId)
            const localVarPath = `/v1/{boardType}/comment/{boardStaticName}/{postId}`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)))
                .replace(`{${"boardType"}}`, encodeURIComponent(String(boardType)))
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (databaseId !== undefined) {
                localVarQueryParameter['databaseId'] = databaseId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (pageCount !== undefined) {
                localVarQueryParameter['pageCount'] = pageCount;
            }

            if (langCode !== undefined) {
                localVarQueryParameter['langCode'] = langCode;
            }

            if (displayMine !== undefined) {
                localVarQueryParameter['displayMine'] = displayMine;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 게시글에 댓글을 등록합니다.
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {string} boardType 
         * @param {string} [databaseId] 
         * @param {CommentContentInfoModel} [commentContentInfoModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardTypeCommentBoardStaticNamePostIdPost: async (boardStaticName: string, postId: string, boardType: string, databaseId?: string, commentContentInfoModel?: CommentContentInfoModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1BoardTypeCommentBoardStaticNamePostIdPost', 'boardStaticName', boardStaticName)
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('v1BoardTypeCommentBoardStaticNamePostIdPost', 'postId', postId)
            // verify required parameter 'boardType' is not null or undefined
            assertParamExists('v1BoardTypeCommentBoardStaticNamePostIdPost', 'boardType', boardType)
            const localVarPath = `/v1/{boardType}/comment/{boardStaticName}/{postId}`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)))
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)))
                .replace(`{${"boardType"}}`, encodeURIComponent(String(boardType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (databaseId !== undefined) {
                localVarQueryParameter['databaseId'] = databaseId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commentContentInfoModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 댓글 추천, 비추천 업데이트
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {number} commentId 
         * @param {string} boardType 
         * @param {boolean} [isLike] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardTypeCommentBoardStaticNameRecommendPostIdCommentIdPost: async (boardStaticName: string, postId: string, commentId: number, boardType: string, isLike?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1BoardTypeCommentBoardStaticNameRecommendPostIdCommentIdPost', 'boardStaticName', boardStaticName)
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('v1BoardTypeCommentBoardStaticNameRecommendPostIdCommentIdPost', 'postId', postId)
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('v1BoardTypeCommentBoardStaticNameRecommendPostIdCommentIdPost', 'commentId', commentId)
            // verify required parameter 'boardType' is not null or undefined
            assertParamExists('v1BoardTypeCommentBoardStaticNameRecommendPostIdCommentIdPost', 'boardType', boardType)
            const localVarPath = `/v1/{boardType}/comment/{boardStaticName}/recommend/{postId}/{commentId}`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)))
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)))
                .replace(`{${"commentId"}}`, encodeURIComponent(String(commentId)))
                .replace(`{${"boardType"}}`, encodeURIComponent(String(boardType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (isLike !== undefined) {
                localVarQueryParameter['isLike'] = isLike;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommentApi - functional programming interface
 * @export
 */
export const CommentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 댓글 잠금 (관리자)
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {number} commentId 
         * @param {string} boardType 
         * @param {boolean} [lockStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BoardTypeCommentBoardStaticNameLockPostIdCommentIdPost(boardStaticName: string, postId: string, commentId: number, boardType: string, lockStatus?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Int32ApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BoardTypeCommentBoardStaticNameLockPostIdCommentIdPost(boardStaticName, postId, commentId, boardType, lockStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 패스워드 변경
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {number} commentId 
         * @param {string} boardType 
         * @param {SettingPasswordModel} [settingPasswordModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BoardTypeCommentBoardStaticNamePostIdCommentIdChangePasswordPost(boardStaticName: string, postId: string, commentId: number, boardType: string, settingPasswordModel?: SettingPasswordModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BoardTypeCommentBoardStaticNamePostIdCommentIdChangePasswordPost(boardStaticName, postId, commentId, boardType, settingPasswordModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 패스워드 체크
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {number} commentId 
         * @param {string} boardType 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BoardTypeCommentBoardStaticNamePostIdCommentIdCheckPasswordPost(boardStaticName: string, postId: string, commentId: number, boardType: string, body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BoardTypeCommentBoardStaticNamePostIdCommentIdCheckPasswordPost(boardStaticName, postId, commentId, boardType, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 댓글을 삭제합니다.
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {number} commentId 
         * @param {string} boardType 
         * @param {string} [databaseId] 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BoardTypeCommentBoardStaticNamePostIdCommentIdDelete(boardStaticName: string, postId: string, commentId: number, boardType: string, databaseId?: string, body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Int32ApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BoardTypeCommentBoardStaticNamePostIdCommentIdDelete(boardStaticName, postId, commentId, boardType, databaseId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 게시글의 댓글을 조회합니다.  댓글ID를 토대로 댓글ID가 있는 페이지를 리턴한다.
         * @param {string} boardStaticName 
         * @param {string} boardType 
         * @param {string} postId 
         * @param {string} [databaseId] 
         * @param {number} [commentId] 
         * @param {number} [size] 
         * @param {number} [pageCount] 
         * @param {string} [langCode] 
         * @param {boolean} [displayMine] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BoardTypeCommentBoardStaticNamePostIdCommentIdGet(boardStaticName: string, boardType: string, postId: string, databaseId?: string, commentId?: number, size?: number, pageCount?: number, langCode?: string, displayMine?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentInfoModelItemListInfoModelApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BoardTypeCommentBoardStaticNamePostIdCommentIdGet(boardStaticName, boardType, postId, databaseId, commentId, size, pageCount, langCode, displayMine, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 댓글에 댓글을 등록합니다.
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {number} commentId 
         * @param {string} boardType 
         * @param {string} [databaseId] 
         * @param {CommentContentInfoModel} [commentContentInfoModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BoardTypeCommentBoardStaticNamePostIdCommentIdPost(boardStaticName: string, postId: string, commentId: number, boardType: string, databaseId?: string, commentContentInfoModel?: CommentContentInfoModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Int64ApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BoardTypeCommentBoardStaticNamePostIdCommentIdPost(boardStaticName, postId, commentId, boardType, databaseId, commentContentInfoModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 댓글을 수정합니다.
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {number} commentId 
         * @param {string} boardType 
         * @param {string} [databaseId] 
         * @param {CommentContentInfoModel} [commentContentInfoModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BoardTypeCommentBoardStaticNamePostIdCommentIdPut(boardStaticName: string, postId: string, commentId: number, boardType: string, databaseId?: string, commentContentInfoModel?: CommentContentInfoModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BoardTypeCommentBoardStaticNamePostIdCommentIdPut(boardStaticName, postId, commentId, boardType, databaseId, commentContentInfoModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 게시글의 댓글을 조회합니다.
         * @param {string} boardStaticName 
         * @param {string} boardType 
         * @param {string} postId 
         * @param {string} [databaseId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {number} [pageCount] 
         * @param {string} [langCode] 
         * @param {boolean} [displayMine] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BoardTypeCommentBoardStaticNamePostIdGet(boardStaticName: string, boardType: string, postId: string, databaseId?: string, page?: number, size?: number, pageCount?: number, langCode?: string, displayMine?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentInfoModelItemListInfoModelApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BoardTypeCommentBoardStaticNamePostIdGet(boardStaticName, boardType, postId, databaseId, page, size, pageCount, langCode, displayMine, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 게시글에 댓글을 등록합니다.
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {string} boardType 
         * @param {string} [databaseId] 
         * @param {CommentContentInfoModel} [commentContentInfoModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BoardTypeCommentBoardStaticNamePostIdPost(boardStaticName: string, postId: string, boardType: string, databaseId?: string, commentContentInfoModel?: CommentContentInfoModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Int64ApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BoardTypeCommentBoardStaticNamePostIdPost(boardStaticName, postId, boardType, databaseId, commentContentInfoModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 댓글 추천, 비추천 업데이트
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {number} commentId 
         * @param {string} boardType 
         * @param {boolean} [isLike] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BoardTypeCommentBoardStaticNameRecommendPostIdCommentIdPost(boardStaticName: string, postId: string, commentId: number, boardType: string, isLike?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Int32ApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BoardTypeCommentBoardStaticNameRecommendPostIdCommentIdPost(boardStaticName, postId, commentId, boardType, isLike, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommentApi - factory interface
 * @export
 */
export const CommentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommentApiFp(configuration)
    return {
        /**
         * 
         * @summary 댓글 잠금 (관리자)
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {number} commentId 
         * @param {string} boardType 
         * @param {boolean} [lockStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardTypeCommentBoardStaticNameLockPostIdCommentIdPost(boardStaticName: string, postId: string, commentId: number, boardType: string, lockStatus?: boolean, options?: any): AxiosPromise<Int32ApiResult> {
            return localVarFp.v1BoardTypeCommentBoardStaticNameLockPostIdCommentIdPost(boardStaticName, postId, commentId, boardType, lockStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 패스워드 변경
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {number} commentId 
         * @param {string} boardType 
         * @param {SettingPasswordModel} [settingPasswordModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardTypeCommentBoardStaticNamePostIdCommentIdChangePasswordPost(boardStaticName: string, postId: string, commentId: number, boardType: string, settingPasswordModel?: SettingPasswordModel, options?: any): AxiosPromise<ApiResult> {
            return localVarFp.v1BoardTypeCommentBoardStaticNamePostIdCommentIdChangePasswordPost(boardStaticName, postId, commentId, boardType, settingPasswordModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 패스워드 체크
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {number} commentId 
         * @param {string} boardType 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardTypeCommentBoardStaticNamePostIdCommentIdCheckPasswordPost(boardStaticName: string, postId: string, commentId: number, boardType: string, body?: string, options?: any): AxiosPromise<ApiResult> {
            return localVarFp.v1BoardTypeCommentBoardStaticNamePostIdCommentIdCheckPasswordPost(boardStaticName, postId, commentId, boardType, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 댓글을 삭제합니다.
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {number} commentId 
         * @param {string} boardType 
         * @param {string} [databaseId] 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardTypeCommentBoardStaticNamePostIdCommentIdDelete(boardStaticName: string, postId: string, commentId: number, boardType: string, databaseId?: string, body?: string, options?: any): AxiosPromise<Int32ApiResult> {
            return localVarFp.v1BoardTypeCommentBoardStaticNamePostIdCommentIdDelete(boardStaticName, postId, commentId, boardType, databaseId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 게시글의 댓글을 조회합니다.  댓글ID를 토대로 댓글ID가 있는 페이지를 리턴한다.
         * @param {string} boardStaticName 
         * @param {string} boardType 
         * @param {string} postId 
         * @param {string} [databaseId] 
         * @param {number} [commentId] 
         * @param {number} [size] 
         * @param {number} [pageCount] 
         * @param {string} [langCode] 
         * @param {boolean} [displayMine] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardTypeCommentBoardStaticNamePostIdCommentIdGet(boardStaticName: string, boardType: string, postId: string, databaseId?: string, commentId?: number, size?: number, pageCount?: number, langCode?: string, displayMine?: boolean, options?: any): AxiosPromise<CommentInfoModelItemListInfoModelApiResult> {
            return localVarFp.v1BoardTypeCommentBoardStaticNamePostIdCommentIdGet(boardStaticName, boardType, postId, databaseId, commentId, size, pageCount, langCode, displayMine, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 댓글에 댓글을 등록합니다.
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {number} commentId 
         * @param {string} boardType 
         * @param {string} [databaseId] 
         * @param {CommentContentInfoModel} [commentContentInfoModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardTypeCommentBoardStaticNamePostIdCommentIdPost(boardStaticName: string, postId: string, commentId: number, boardType: string, databaseId?: string, commentContentInfoModel?: CommentContentInfoModel, options?: any): AxiosPromise<Int64ApiResult> {
            return localVarFp.v1BoardTypeCommentBoardStaticNamePostIdCommentIdPost(boardStaticName, postId, commentId, boardType, databaseId, commentContentInfoModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 댓글을 수정합니다.
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {number} commentId 
         * @param {string} boardType 
         * @param {string} [databaseId] 
         * @param {CommentContentInfoModel} [commentContentInfoModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardTypeCommentBoardStaticNamePostIdCommentIdPut(boardStaticName: string, postId: string, commentId: number, boardType: string, databaseId?: string, commentContentInfoModel?: CommentContentInfoModel, options?: any): AxiosPromise<BooleanApiResult> {
            return localVarFp.v1BoardTypeCommentBoardStaticNamePostIdCommentIdPut(boardStaticName, postId, commentId, boardType, databaseId, commentContentInfoModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 게시글의 댓글을 조회합니다.
         * @param {string} boardStaticName 
         * @param {string} boardType 
         * @param {string} postId 
         * @param {string} [databaseId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {number} [pageCount] 
         * @param {string} [langCode] 
         * @param {boolean} [displayMine] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardTypeCommentBoardStaticNamePostIdGet(boardStaticName: string, boardType: string, postId: string, databaseId?: string, page?: number, size?: number, pageCount?: number, langCode?: string, displayMine?: boolean, options?: any): AxiosPromise<CommentInfoModelItemListInfoModelApiResult> {
            return localVarFp.v1BoardTypeCommentBoardStaticNamePostIdGet(boardStaticName, boardType, postId, databaseId, page, size, pageCount, langCode, displayMine, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 게시글에 댓글을 등록합니다.
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {string} boardType 
         * @param {string} [databaseId] 
         * @param {CommentContentInfoModel} [commentContentInfoModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardTypeCommentBoardStaticNamePostIdPost(boardStaticName: string, postId: string, boardType: string, databaseId?: string, commentContentInfoModel?: CommentContentInfoModel, options?: any): AxiosPromise<Int64ApiResult> {
            return localVarFp.v1BoardTypeCommentBoardStaticNamePostIdPost(boardStaticName, postId, boardType, databaseId, commentContentInfoModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 댓글 추천, 비추천 업데이트
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {number} commentId 
         * @param {string} boardType 
         * @param {boolean} [isLike] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardTypeCommentBoardStaticNameRecommendPostIdCommentIdPost(boardStaticName: string, postId: string, commentId: number, boardType: string, isLike?: boolean, options?: any): AxiosPromise<Int32ApiResult> {
            return localVarFp.v1BoardTypeCommentBoardStaticNameRecommendPostIdCommentIdPost(boardStaticName, postId, commentId, boardType, isLike, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommentApi - object-oriented interface
 * @export
 * @class CommentApi
 * @extends {BaseAPI}
 */
export class CommentApi extends BaseAPI {
    /**
     * 
     * @summary 댓글 잠금 (관리자)
     * @param {string} boardStaticName 
     * @param {string} postId 
     * @param {number} commentId 
     * @param {string} boardType 
     * @param {boolean} [lockStatus] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public v1BoardTypeCommentBoardStaticNameLockPostIdCommentIdPost(boardStaticName: string, postId: string, commentId: number, boardType: string, lockStatus?: boolean, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).v1BoardTypeCommentBoardStaticNameLockPostIdCommentIdPost(boardStaticName, postId, commentId, boardType, lockStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 패스워드 변경
     * @param {string} boardStaticName 
     * @param {string} postId 
     * @param {number} commentId 
     * @param {string} boardType 
     * @param {SettingPasswordModel} [settingPasswordModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public v1BoardTypeCommentBoardStaticNamePostIdCommentIdChangePasswordPost(boardStaticName: string, postId: string, commentId: number, boardType: string, settingPasswordModel?: SettingPasswordModel, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).v1BoardTypeCommentBoardStaticNamePostIdCommentIdChangePasswordPost(boardStaticName, postId, commentId, boardType, settingPasswordModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 패스워드 체크
     * @param {string} boardStaticName 
     * @param {string} postId 
     * @param {number} commentId 
     * @param {string} boardType 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public v1BoardTypeCommentBoardStaticNamePostIdCommentIdCheckPasswordPost(boardStaticName: string, postId: string, commentId: number, boardType: string, body?: string, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).v1BoardTypeCommentBoardStaticNamePostIdCommentIdCheckPasswordPost(boardStaticName, postId, commentId, boardType, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 댓글을 삭제합니다.
     * @param {string} boardStaticName 
     * @param {string} postId 
     * @param {number} commentId 
     * @param {string} boardType 
     * @param {string} [databaseId] 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public v1BoardTypeCommentBoardStaticNamePostIdCommentIdDelete(boardStaticName: string, postId: string, commentId: number, boardType: string, databaseId?: string, body?: string, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).v1BoardTypeCommentBoardStaticNamePostIdCommentIdDelete(boardStaticName, postId, commentId, boardType, databaseId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 게시글의 댓글을 조회합니다.  댓글ID를 토대로 댓글ID가 있는 페이지를 리턴한다.
     * @param {string} boardStaticName 
     * @param {string} boardType 
     * @param {string} postId 
     * @param {string} [databaseId] 
     * @param {number} [commentId] 
     * @param {number} [size] 
     * @param {number} [pageCount] 
     * @param {string} [langCode] 
     * @param {boolean} [displayMine] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public v1BoardTypeCommentBoardStaticNamePostIdCommentIdGet(boardStaticName: string, boardType: string, postId: string, databaseId?: string, commentId?: number, size?: number, pageCount?: number, langCode?: string, displayMine?: boolean, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).v1BoardTypeCommentBoardStaticNamePostIdCommentIdGet(boardStaticName, boardType, postId, databaseId, commentId, size, pageCount, langCode, displayMine, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 댓글에 댓글을 등록합니다.
     * @param {string} boardStaticName 
     * @param {string} postId 
     * @param {number} commentId 
     * @param {string} boardType 
     * @param {string} [databaseId] 
     * @param {CommentContentInfoModel} [commentContentInfoModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public v1BoardTypeCommentBoardStaticNamePostIdCommentIdPost(boardStaticName: string, postId: string, commentId: number, boardType: string, databaseId?: string, commentContentInfoModel?: CommentContentInfoModel, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).v1BoardTypeCommentBoardStaticNamePostIdCommentIdPost(boardStaticName, postId, commentId, boardType, databaseId, commentContentInfoModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 댓글을 수정합니다.
     * @param {string} boardStaticName 
     * @param {string} postId 
     * @param {number} commentId 
     * @param {string} boardType 
     * @param {string} [databaseId] 
     * @param {CommentContentInfoModel} [commentContentInfoModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public v1BoardTypeCommentBoardStaticNamePostIdCommentIdPut(boardStaticName: string, postId: string, commentId: number, boardType: string, databaseId?: string, commentContentInfoModel?: CommentContentInfoModel, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).v1BoardTypeCommentBoardStaticNamePostIdCommentIdPut(boardStaticName, postId, commentId, boardType, databaseId, commentContentInfoModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 게시글의 댓글을 조회합니다.
     * @param {string} boardStaticName 
     * @param {string} boardType 
     * @param {string} postId 
     * @param {string} [databaseId] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {number} [pageCount] 
     * @param {string} [langCode] 
     * @param {boolean} [displayMine] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public v1BoardTypeCommentBoardStaticNamePostIdGet(boardStaticName: string, boardType: string, postId: string, databaseId?: string, page?: number, size?: number, pageCount?: number, langCode?: string, displayMine?: boolean, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).v1BoardTypeCommentBoardStaticNamePostIdGet(boardStaticName, boardType, postId, databaseId, page, size, pageCount, langCode, displayMine, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 게시글에 댓글을 등록합니다.
     * @param {string} boardStaticName 
     * @param {string} postId 
     * @param {string} boardType 
     * @param {string} [databaseId] 
     * @param {CommentContentInfoModel} [commentContentInfoModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public v1BoardTypeCommentBoardStaticNamePostIdPost(boardStaticName: string, postId: string, boardType: string, databaseId?: string, commentContentInfoModel?: CommentContentInfoModel, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).v1BoardTypeCommentBoardStaticNamePostIdPost(boardStaticName, postId, boardType, databaseId, commentContentInfoModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 댓글 추천, 비추천 업데이트
     * @param {string} boardStaticName 
     * @param {string} postId 
     * @param {number} commentId 
     * @param {string} boardType 
     * @param {boolean} [isLike] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public v1BoardTypeCommentBoardStaticNameRecommendPostIdCommentIdPost(boardStaticName: string, postId: string, commentId: number, boardType: string, isLike?: boolean, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).v1BoardTypeCommentBoardStaticNameRecommendPostIdCommentIdPost(boardStaticName, postId, commentId, boardType, isLike, options).then((request) => request(this.axios, this.basePath));
    }
}
