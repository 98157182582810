<template>
  <div id="lay_wrap">
    <div id="lay_container" :class="{ popup_mode: modeStore.isPopupMode }">
      <slot />
    </div>
    <site-footer v-show="modeStore.showFooterMode"></site-footer>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'

import SiteFooter from 'mik-common/src/components/layout/MikFooter.vue'
import { useModeStore } from 'mik-common/src/store/mode'

const route = useRoute()
const modeStore = useModeStore()

onMounted(() => {
  if (modeStore.isPrintMode) {
    const printMe = window
    setTimeout(() => {
      setTimeout(() => {
        printMe.focus()
        printMe.print()
      }, 500)
    }, 100)

    // 프린트 종료 후 print area element 제거
    printMe.onafterprint = () => {
      const printArea = printMe.parent.document.getElementsByClassName('mik_printing_area')[0]
      if (printArea) {
        printArea.remove()
      }
    }
  }
})
</script>
<style>
/** Gnb css */
/* @import '@/../node_modules/mik-gnb/dist/mik-gnb.css'; */

/** Popup Mode */
.popup_mode {
  padding: 0 !important;
  margin: 0 !important;
  max-width: 100% !important;
  width: 100% !important;
  min-width: auto !important;

  #lay_contents {
    margin-left: auto !important;
    padding-right: 0 !important;
  }
}

/** print시 background css가 적용되지 않아 추가 */
@media print {
  * {
    -webkit-print-color-adjust: exact;
  }
}
</style>
