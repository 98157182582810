/* tslint:disable */
/* eslint-disable */
/**
 * Mik.SmartPortal.Community.App
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import type { RequestArgs } from '../base';
// @ts-ignore
import type { ApiResult } from '../models';
// @ts-ignore
import type { MemberModelApiResult } from '../models';
// @ts-ignore
import type { RequestMailModel } from '../models';
// @ts-ignore
import type { RequestMemberListModel } from '../models';
// @ts-ignore
import type { ResultMemberModelApiResult } from '../models';
// @ts-ignore
import type { StringApiResult } from '../models';
/**
 * MemberApi - axios parameter creator
 * @export
 */
export const MemberApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 회원 추가
         * @param {string} [communityId] 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MemberAddPost: async (communityId?: string, requestBody?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/member/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (communityId !== undefined) {
                localVarQueryParameter['communityId'] = communityId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} [userID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MemberChatUrlGet: async (userID?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/member/chat-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userID !== undefined) {
                localVarQueryParameter['userID'] = userID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 회원 탈퇴
         * @param {string} [communityId] 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MemberDeletePost: async (communityId?: string, requestBody?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/member/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (communityId !== undefined) {
                localVarQueryParameter['communityId'] = communityId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 회원 정보
         * @param {string} [communityId] 
         * @param {string} [empId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MemberGet: async (communityId?: string, empId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/member`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (communityId !== undefined) {
                localVarQueryParameter['communityId'] = communityId;
            }

            if (empId !== undefined) {
                localVarQueryParameter['empId'] = empId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 전체 회원 목록
         * @param {string} [communityId] 
         * @param {RequestMemberListModel} [requestMemberListModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MemberListPost: async (communityId?: string, requestMemberListModel?: RequestMemberListModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/member/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (communityId !== undefined) {
                localVarQueryParameter['communityId'] = communityId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestMemberListModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 선택 회원에게 메일 발신
         * @param {string} [communityId] 
         * @param {RequestMailModel} [requestMailModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MemberMailAllPost: async (communityId?: string, requestMailModel?: RequestMailModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/member/mail/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (communityId !== undefined) {
                localVarQueryParameter['communityId'] = communityId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestMailModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 선택 회원에게 메일 발신
         * @param {string} [communityId] 
         * @param {RequestMailModel} [requestMailModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MemberMailPost: async (communityId?: string, requestMailModel?: RequestMailModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/member/mail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (communityId !== undefined) {
                localVarQueryParameter['communityId'] = communityId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestMailModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MemberApi - functional programming interface
 * @export
 */
export const MemberApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MemberApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 회원 추가
         * @param {string} [communityId] 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MemberAddPost(communityId?: string, requestBody?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1MemberAddPost(communityId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} [userID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MemberChatUrlGet(userID?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1MemberChatUrlGet(userID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 회원 탈퇴
         * @param {string} [communityId] 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MemberDeletePost(communityId?: string, requestBody?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1MemberDeletePost(communityId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 회원 정보
         * @param {string} [communityId] 
         * @param {string} [empId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MemberGet(communityId?: string, empId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberModelApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1MemberGet(communityId, empId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 전체 회원 목록
         * @param {string} [communityId] 
         * @param {RequestMemberListModel} [requestMemberListModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MemberListPost(communityId?: string, requestMemberListModel?: RequestMemberListModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultMemberModelApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1MemberListPost(communityId, requestMemberListModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 선택 회원에게 메일 발신
         * @param {string} [communityId] 
         * @param {RequestMailModel} [requestMailModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MemberMailAllPost(communityId?: string, requestMailModel?: RequestMailModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1MemberMailAllPost(communityId, requestMailModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 선택 회원에게 메일 발신
         * @param {string} [communityId] 
         * @param {RequestMailModel} [requestMailModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MemberMailPost(communityId?: string, requestMailModel?: RequestMailModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1MemberMailPost(communityId, requestMailModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MemberApi - factory interface
 * @export
 */
export const MemberApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MemberApiFp(configuration)
    return {
        /**
         * 
         * @summary 회원 추가
         * @param {string} [communityId] 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MemberAddPost(communityId?: string, requestBody?: Array<string>, options?: any): AxiosPromise<ApiResult> {
            return localVarFp.v1MemberAddPost(communityId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} [userID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MemberChatUrlGet(userID?: string, options?: any): AxiosPromise<StringApiResult> {
            return localVarFp.v1MemberChatUrlGet(userID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 회원 탈퇴
         * @param {string} [communityId] 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MemberDeletePost(communityId?: string, requestBody?: Array<string>, options?: any): AxiosPromise<ApiResult> {
            return localVarFp.v1MemberDeletePost(communityId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 회원 정보
         * @param {string} [communityId] 
         * @param {string} [empId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MemberGet(communityId?: string, empId?: string, options?: any): AxiosPromise<MemberModelApiResult> {
            return localVarFp.v1MemberGet(communityId, empId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 전체 회원 목록
         * @param {string} [communityId] 
         * @param {RequestMemberListModel} [requestMemberListModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MemberListPost(communityId?: string, requestMemberListModel?: RequestMemberListModel, options?: any): AxiosPromise<ResultMemberModelApiResult> {
            return localVarFp.v1MemberListPost(communityId, requestMemberListModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 선택 회원에게 메일 발신
         * @param {string} [communityId] 
         * @param {RequestMailModel} [requestMailModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MemberMailAllPost(communityId?: string, requestMailModel?: RequestMailModel, options?: any): AxiosPromise<StringApiResult> {
            return localVarFp.v1MemberMailAllPost(communityId, requestMailModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 선택 회원에게 메일 발신
         * @param {string} [communityId] 
         * @param {RequestMailModel} [requestMailModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MemberMailPost(communityId?: string, requestMailModel?: RequestMailModel, options?: any): AxiosPromise<StringApiResult> {
            return localVarFp.v1MemberMailPost(communityId, requestMailModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MemberApi - object-oriented interface
 * @export
 * @class MemberApi
 * @extends {BaseAPI}
 */
export class MemberApi extends BaseAPI {
    /**
     * 
     * @summary 회원 추가
     * @param {string} [communityId] 
     * @param {Array<string>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public v1MemberAddPost(communityId?: string, requestBody?: Array<string>, options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).v1MemberAddPost(communityId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} [userID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public v1MemberChatUrlGet(userID?: string, options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).v1MemberChatUrlGet(userID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 회원 탈퇴
     * @param {string} [communityId] 
     * @param {Array<string>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public v1MemberDeletePost(communityId?: string, requestBody?: Array<string>, options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).v1MemberDeletePost(communityId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 회원 정보
     * @param {string} [communityId] 
     * @param {string} [empId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public v1MemberGet(communityId?: string, empId?: string, options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).v1MemberGet(communityId, empId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 전체 회원 목록
     * @param {string} [communityId] 
     * @param {RequestMemberListModel} [requestMemberListModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public v1MemberListPost(communityId?: string, requestMemberListModel?: RequestMemberListModel, options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).v1MemberListPost(communityId, requestMemberListModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 선택 회원에게 메일 발신
     * @param {string} [communityId] 
     * @param {RequestMailModel} [requestMailModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public v1MemberMailAllPost(communityId?: string, requestMailModel?: RequestMailModel, options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).v1MemberMailAllPost(communityId, requestMailModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 선택 회원에게 메일 발신
     * @param {string} [communityId] 
     * @param {RequestMailModel} [requestMailModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public v1MemberMailPost(communityId?: string, requestMailModel?: RequestMailModel, options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).v1MemberMailPost(communityId, requestMailModel, options).then((request) => request(this.axios, this.basePath));
    }
}
