import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'
import {
  useGlobalMenuStore,
  useMyTenantsStore,
  usePersonalizationStore,
  useUserInfoStore
} from '@gnb/modules/menus/main'
import { useReservationStore } from 'mik-reservation/src/store/reservation'

const guards = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
  useRouterMerge: boolean
) => {
  // const useLocalMenu = useGlobalMenuStore()

  // user 정보 세팅
  const userInfoStore = useUserInfoStore()
  userInfoStore.userInfoInit()
  const userInfo = userInfoStore.getUserInfo

  // tenantID 목록 세팅
  const useMyTenants = useMyTenantsStore()
  useMyTenants.myTenantsInit()
  const myTenants = ref(useMyTenants.getMyTenants)

  // personalization 세팅
  usePersonalizationStore().personalizationInit()

  if (to.name === 'meetingRoomReservationDetail') {
    const reservationID: number = parseInt(to.query?.reservationID as string)
    const mode: string = to.query?.mode as string

    if (mode === 'edit') {
      const store = useReservationStore()
      const userInfo = useUserInfoStore().userInfo

      store
        .getMeetingRoomReservationDetail(reservationID)
        .then(() => {
          // 관리자 or 작성자 수정 권한
          if (
            store.isAdmin ||
            store.meetingroomDetail.reservationUserID?.toLowerCase() === userInfo.userID?.toLowerCase()
          ) {
            next()
          } else {
            next({
              path: '/Error/401'
            })
          }
        })
        .catch((err) => {
          console.log(err)
          next({
            path: '/Error/401'
          })
        })
    } else {
      next()
    }
  } else if (to.name === 'equipmentReservationDetail') {
    const reservationID: number = parseInt(to.query?.reservationID as string)
    const mode: string = to.query?.mode as string

    const store = useReservationStore()
    const userInfo = useUserInfoStore().userInfo

    if (mode === 'edit') {
      store
        .getEquipmentReservationDetail(reservationID)
        .then(() => {
          // 관리자 or 작성자 수정 권한
          if (
            store.isAdmin ||
            store.equipmentDetail.reservationUserID?.toLowerCase() === userInfo.userID?.toLowerCase()
          ) {
            next()
          } else {
            next({
              path: '/Error/401'
            })
          }
        })
        .catch((err) => {
          console.log(err)
          next({
            path: '/Error/401'
          })
        })
    } else {
      next()
    }
  } else if (!useRouterMerge) {
    if (to.name) {
      next()
    } else {
      next(false)
    }
  }
}

export default guards
