<template>
  <div
    :title="store.communityInfo.title ?? ''"
    class="header_top cursor-pointer"
    :style="`background-image: url('${props.coverImageUrl}');background-size: 100% 400px;background-position: center;height: ${store.headerHeight}px;`"
    @click="handleTitleClick"></div>

  <q-bar rounded class="sub-menu-box q-mt-sm" caption :class="getThemeClass('gnb')">
    <div class="cursor-pointer text-bold" :class="getThemeClass('gnbText')" @click="handleNoticeClick">
      {{ lastNotice.itemTItle }}
    </div>
    <q-space />
    <!-- Show only for admin -->
    <div
      v-if="store.headerHeight === 200"
      class="cursor-pointer"
      :class="getThemeClass('gnbText')"
      @click="store.settingHeaderHeight()">
      <q-icon class="q-mr-xs" name="zoom_out_map"></q-icon>
    </div>
    <div v-else class="cursor-pointer" :class="getThemeClass('gnbText')" @click="store.settingHeaderHeight()">
      <q-icon class="q-mr-xs" name="zoom_in_map"></q-icon>
    </div>
  </q-bar>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { useCommunityStore } from 'mik-community/src/store/community'
import { getThemeClass, pushToBoardItem } from 'mik-community/src/utils/util'
import { WidgetRecentItem } from 'mik-common/src/api-autogen/community'

// 라우터
const router = useRouter()
// 스토어
const store = useCommunityStore()
const themeClass = ref(getThemeClass('gnb'))
const lastNotice = ref({} as WidgetRecentItem)
if (store.notice.communityNoticeItemList && store.notice.communityNoticeItemList.length > 0)
  lastNotice.value = store.notice.communityNoticeItemList[store.notice.communityNoticeItemList.length - 1]

const props = defineProps<{
  coverImageUrl: string
}>()

watch(
  () => store.theme,
  (newVal, oldVal) => {
    themeClass.value = getThemeClass('gnb')
  }
)
const handleTitleClick = () => {
  router.push({ name: 'Community.Home' })
}

const handleNoticeClick = () => {
  if (lastNotice.value.boardUrl && lastNotice.value.itemId)
    pushToBoardItem(router, store.communityInfo.relativeUrl ?? '', lastNotice.value.boardUrl, lastNotice.value.itemId)
}
</script>

<style scoped>
.sub-menu-box {
  height: 45px;
}
.header_top {
  overflow: auto;
  max-height: 400px;
  min-height: 130px;
  transition: all 0.5s linear;
}
</style>
