import { AxiosResponse } from 'axios'
import {
  EquipmentReservationEntity,
  EquipmentReservationEntityApiResult,
  MeetingRoomReservationEntity,
  MeetingRoomReservationEntityApiResult,
  UserAuthorityConstant
} from 'mik-common/src/api-autogen/reservation'
import { ReservationApi } from 'mik-common/src/api-autogen/reservation'

export const useReservationStore = defineStore('reservation', {
  state: (): IReservation => {
    return {
      meetingroomDetail: {} as MeetingRoomReservationEntity,
      equipmentDetail: {} as EquipmentReservationEntity,
      authLevel: UserAuthorityConstant.USER_AUTH_NONE
    }
  },
  getters: {
    isWrite(state): boolean {
      return state.authLevel >= UserAuthorityConstant.USER_AUTH_READWRITE
    },
    isAdmin(state): boolean {
      return state.authLevel >= UserAuthorityConstant.USER_AUTH_MANAGER
    }
  },
  actions: {
    async getMeetingRoomReservationDetail(reservationID: number) {
      const api = new ReservationApi()
      const response: AxiosResponse<MeetingRoomReservationEntityApiResult> =
        await api.v1ReservationMeetingroomReservationReservationIDGet(reservationID)

      if (response.data.success) {
        Object.assign(this.meetingroomDetail, response.data.data)
        this.authLevel = response.data.userAuthority ?? UserAuthorityConstant.USER_AUTH_NONE
      } else {
        Object.assign(this.meetingroomDetail, {})
        this.authLevel = UserAuthorityConstant.USER_AUTH_NONE
      }
    },
    async getEquipmentReservationDetail(reservationID: number) {
      const api = new ReservationApi()
      const response: AxiosResponse<EquipmentReservationEntityApiResult> =
        await api.v1ReservationEquipmentReservationReservationIDGet(reservationID)

      if (response.data.success) {
        Object.assign(this.equipmentDetail, response.data.data)
        this.authLevel = response.data.userAuthority ?? UserAuthorityConstant.USER_AUTH_NONE
      } else {
        Object.assign(this.equipmentDetail, {})
        this.authLevel = UserAuthorityConstant.USER_AUTH_NONE
      }
    }
  }
})

export interface IReservation {
  meetingroomDetail: MeetingRoomReservationEntity
  equipmentDetail: EquipmentReservationEntity
  authLevel: UserAuthorityConstant
}
