const routers = [
  // 나의 동의서 목록
  {
    name: 'MyConsentList',
    path: '/MyConsentList',
    component: () => import('mik-consentform/src/pages/user/list/MyConsentList.vue')
  },
  // 나의 동의서 상세보기
  {
    name: 'MyConsentView',
    path: '/MyConsentView',
    component: () => import('mik-consentform/src/pages/user/view/MyConsentView.vue'),
    meta: {
      isEmpty: true
    }
  },
  // 동의서 작성
  {
    name: 'ManagementEdit',
    path: '/Management/Edit',
    component: () => import('mik-consentform/src/pages/management/edit/Edit.vue')
  },
  // 동의서 목록
  {
    name: 'ManagementList',
    path: '/Management/List',
    component: () => import('mik-consentform/src/pages/management/list/List.vue')
  },
  // 동의서 상세보기
  {
    name: 'ManagementView',
    path: '/Management/View/:formID',
    component: () => import('mik-consentform/src/pages/management/view/View.vue')
  },
  // 동의서 수신자 인쇄 팝업
  {
    name: 'UserConsentView',
    path: '/Management/UserConsentView',
    component: () => import('mik-consentform/src/pages/management/view/user/UserConsentView.vue'),
    meta: {
      isEmpty: true
    }
  },
  // 동의현황 관리
  {
    name: 'ManagementStatusList',
    path: '/Management/Status/List',
    component: () => import('mik-consentform/src/pages/management/status/list/ConsentStatusList.vue')
  },
  // 동의현황 상세보기
  {
    name: 'ManagementStatusView',
    path: '/Management/Status/View/:formID/:recipientID',
    component: () => import('mik-consentform/src/pages/management/status/view/ConsentStatusView.vue')
  },
  // 동의현황 통계
  {
    name: 'ManagementStatusStatistics',
    path: '/Management/Status/Statistics/:formID',
    component: () => import('mik-consentform/src/pages/management/status/statistics/ConsentStatusStatistics.vue')
  },
  // 카테고리 관리
  {
    name: 'CategoryManagement',
    path: '/Management/CategoryManagement',
    component: () => import('mik-consentform/src/pages/management/category/CategoryList.vue')
  }
]

export default routers
