/* tslint:disable */
/* eslint-disable */
/**
 * Mik.SmartPortal.Community.App
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import type { RequestArgs } from '../base';
// @ts-ignore
import type { ApiResult } from '../models';
// @ts-ignore
import type { BooleanApiResult } from '../models';
// @ts-ignore
import type { RequestJoinMemberModel } from '../models';
// @ts-ignore
import type { RequestMemberListModel } from '../models';
// @ts-ignore
import type { ResultJoinCommunityModelApiResult } from '../models';
/**
 * JoinApi - axios parameter creator
 * @export
 */
export const JoinApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 가입 신청
         * @param {string} [communityId] 
         * @param {RequestJoinMemberModel} [requestJoinMemberModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1JoinApplicationPost: async (communityId?: string, requestJoinMemberModel?: RequestJoinMemberModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/join/application`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (communityId !== undefined) {
                localVarQueryParameter['communityId'] = communityId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestJoinMemberModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 가입 중인 커뮤니티 리스트 조회
         * @param {string} [communityId] 
         * @param {RequestMemberListModel} [requestMemberListModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1JoinCommunitiesPost: async (communityId?: string, requestMemberListModel?: RequestMemberListModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/join/communities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (communityId !== undefined) {
                localVarQueryParameter['communityId'] = communityId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestMemberListModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 가입 승인/반려
         * @param {string} [communityId] 
         * @param {RequestJoinMemberModel} [requestJoinMemberModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1JoinProcessPost: async (communityId?: string, requestJoinMemberModel?: RequestJoinMemberModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/join/process`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (communityId !== undefined) {
                localVarQueryParameter['communityId'] = communityId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestJoinMemberModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JoinApi - functional programming interface
 * @export
 */
export const JoinApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = JoinApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 가입 신청
         * @param {string} [communityId] 
         * @param {RequestJoinMemberModel} [requestJoinMemberModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1JoinApplicationPost(communityId?: string, requestJoinMemberModel?: RequestJoinMemberModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1JoinApplicationPost(communityId, requestJoinMemberModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 가입 중인 커뮤니티 리스트 조회
         * @param {string} [communityId] 
         * @param {RequestMemberListModel} [requestMemberListModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1JoinCommunitiesPost(communityId?: string, requestMemberListModel?: RequestMemberListModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultJoinCommunityModelApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1JoinCommunitiesPost(communityId, requestMemberListModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 가입 승인/반려
         * @param {string} [communityId] 
         * @param {RequestJoinMemberModel} [requestJoinMemberModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1JoinProcessPost(communityId?: string, requestJoinMemberModel?: RequestJoinMemberModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1JoinProcessPost(communityId, requestJoinMemberModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * JoinApi - factory interface
 * @export
 */
export const JoinApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JoinApiFp(configuration)
    return {
        /**
         * 
         * @summary 가입 신청
         * @param {string} [communityId] 
         * @param {RequestJoinMemberModel} [requestJoinMemberModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1JoinApplicationPost(communityId?: string, requestJoinMemberModel?: RequestJoinMemberModel, options?: any): AxiosPromise<BooleanApiResult> {
            return localVarFp.v1JoinApplicationPost(communityId, requestJoinMemberModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 가입 중인 커뮤니티 리스트 조회
         * @param {string} [communityId] 
         * @param {RequestMemberListModel} [requestMemberListModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1JoinCommunitiesPost(communityId?: string, requestMemberListModel?: RequestMemberListModel, options?: any): AxiosPromise<ResultJoinCommunityModelApiResult> {
            return localVarFp.v1JoinCommunitiesPost(communityId, requestMemberListModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 가입 승인/반려
         * @param {string} [communityId] 
         * @param {RequestJoinMemberModel} [requestJoinMemberModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1JoinProcessPost(communityId?: string, requestJoinMemberModel?: RequestJoinMemberModel, options?: any): AxiosPromise<ApiResult> {
            return localVarFp.v1JoinProcessPost(communityId, requestJoinMemberModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * JoinApi - object-oriented interface
 * @export
 * @class JoinApi
 * @extends {BaseAPI}
 */
export class JoinApi extends BaseAPI {
    /**
     * 
     * @summary 가입 신청
     * @param {string} [communityId] 
     * @param {RequestJoinMemberModel} [requestJoinMemberModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JoinApi
     */
    public v1JoinApplicationPost(communityId?: string, requestJoinMemberModel?: RequestJoinMemberModel, options?: AxiosRequestConfig) {
        return JoinApiFp(this.configuration).v1JoinApplicationPost(communityId, requestJoinMemberModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 가입 중인 커뮤니티 리스트 조회
     * @param {string} [communityId] 
     * @param {RequestMemberListModel} [requestMemberListModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JoinApi
     */
    public v1JoinCommunitiesPost(communityId?: string, requestMemberListModel?: RequestMemberListModel, options?: AxiosRequestConfig) {
        return JoinApiFp(this.configuration).v1JoinCommunitiesPost(communityId, requestMemberListModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 가입 승인/반려
     * @param {string} [communityId] 
     * @param {RequestJoinMemberModel} [requestJoinMemberModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JoinApi
     */
    public v1JoinProcessPost(communityId?: string, requestJoinMemberModel?: RequestJoinMemberModel, options?: AxiosRequestConfig) {
        return JoinApiFp(this.configuration).v1JoinProcessPost(communityId, requestJoinMemberModel, options).then((request) => request(this.axios, this.basePath));
    }
}
