<template>
  <q-expansion-item :model-value="true" class="lnb_post">
    <template #header>
      <div class="self-center text-subtitle1">{{ $t('RecentBoardItems', '최근 게시글') }}</div>
    </template>
    <q-scroll-area :style="'height:' + (20 + lnbPostListScroll.height.value) + 'px;'">
      <q-list ref="lnbPostList" class="q-ml-md">
        <q-item v-for="item in itemList">
          <div class="self-center cursor-pointer text-body2 ellipsis" @click="handleItemClick(item)">
            {{ item.itemTItle }}
          </div>
        </q-item>
      </q-list>
    </q-scroll-area>
  </q-expansion-item>
</template>

<script setup lang="ts">
import { useToast } from 'mik-community/src/common/toast'
import { useCommunityStore } from 'mik-community/src/store/community'
import { pushToBoardItem } from 'mik-community/src/utils/util'
import { useElementSize } from '@vueuse/core'
import { RecentItemModel } from 'mik-common/src/api-autogen/community'

const router = useRouter()

const store = useCommunityStore()
const itemList = store.lnbRecentItemList
const lnbPostList = ref()

const lnbPostListScroll = computed(() => {
  return useElementSize(lnbPostList)
})

const handleItemClick = (item: RecentItemModel) => {
  if (item.boardUrl && item.itemId)
    pushToBoardItem(router, store.communityInfo.relativeUrl ?? '', item.boardUrl, item.itemId)
}
</script>

<style scoped></style>
