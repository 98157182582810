const routers = [
  // 프로젝트 루트 => 메인페이지
  {
    path: '/',
    redirect: { name: 'CompanyRuleMain' }
  },
  //메인페이지
  {
    name: 'CompanyRuleMain',
    path: '/Main',
    component: () => import('mik-companyrule/src/pages/user/companyrule/Main.vue')
  },
  //목록페이지
  {
    name: 'CompanyRuleList',
    path: '/List',
    component: () => import('mik-companyrule/src/pages/user/companyrule/List.vue')
  },
  //수정페이지
  {
    name: 'CompanyRuleEdit',
    path: '/Edit/:editType',
    component: () => import('mik-companyrule/src/pages/user/companyrule/Edit.vue')
  },
  //상세페이지
  {
    name: 'CompanyRuleView',
    path: '/View/:docID?',
    component: () => import('mik-companyrule/src/pages/user/companyrule/View.vue')
  },
  //에디터 뷰
  {
    name: 'CompanyRuleEditView',
    path: '/EditView/:docID',
    component: () => import('mik-companyrule/src/components/editor/EditorView.vue')
  }
]

export default routers
