<template>
  <base-layout><router-view /></base-layout>
</template>

<script setup lang="ts">
import { usePortalCss } from 'mik-common/src/common/css'
import BaseLayout from '../layouts/Base.vue'

onBeforeMount(() => {
  usePortalCss()
})
</script>
