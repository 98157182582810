import { BoardApi, BoardPostApi } from 'mik-common/src/api-autogen/portal/api'
import {
  BoardInfoModel,
  BoardInfoModelApiResult,
  UserAuthorityConstant,
  PostInfoModel as PostInfo
} from 'mik-common/src/api-autogen/portal/models'
import { AxiosResponse } from 'axios'
import { SearchOption } from 'mik-board/src/models/board/header/search-option'
import { useUserInfoStore } from '@gnb/modules/menus/main'
import { StandardBoardOption_IdentityOptions } from 'mik-board/src/models/board/nickname/standard-board-option-identity-options'
import { Cookies } from 'quasar'
import { CommentApi } from 'mik-common/src/api-autogen/portal'

export const useBoardStore = defineStore('board', {
  state: (): IBoard => {
    return {
      postId: 'none',
      // 목록 게시판 정보
      boardInfo: {} as BoardInfoModel,
      // 수정이나, 조회 게시판 정보
      boardInfoByView: {} as BoardInfoModel,
      langCode: 'ko',
      authLevel: UserAuthorityConstant.USER_AUTH_NONE,
      searchOption: { pageNumber: 1 } as SearchOption,
      socialPageNumber: 1,
      resultStatus: -2,
      primaryImageOfAddedMedia: '',
      primaryImageDataOfAddedMedia: ''
    }
  },
  getters: {
    boardStaticName(state): string {
      if (state.boardInfo.boardUrl) {
        return state.boardInfo.boardUrl
      } else if (state.boardInfoByView.boardUrl) {
        return state.boardInfoByView.boardUrl
      } else {
        return ''
      }
    },
    isWrite(state): boolean {
      return state.authLevel >= UserAuthorityConstant.USER_AUTH_READWRITE
    },
    isAdmin(state): boolean {
      return state.authLevel >= UserAuthorityConstant.USER_AUTH_MANAGER
    },
    useFolder(state): boolean {
      return state.boardInfo?.useFolder ?? state.boardInfoByView?.useFolder ?? false
    },
    enableNotification(state): boolean {
      return state.boardInfo?.enableNotification ?? false
    }
  },
  actions: {
    async getBoardInfo(boardStaticName: string, forceRefresh: boolean) {
      const currentLangCode = Cookies.get('GWP_LANGUAGE_CODE') ?? 'ko'

      if (
        this.boardInfo.boardUrl?.toLowerCase() !== boardStaticName.toLowerCase() ||
        this.langCode !== currentLangCode ||
        forceRefresh
      ) {
        const api = new BoardApi()
        const response: AxiosResponse<BoardInfoModelApiResult> = await api.v1BoardBoardStaticNameGet(
          boardStaticName,
          undefined,
          currentLangCode
        )

        if (response.data.success) {
          Object.assign(this.boardInfo, JSON.parse(JSON.stringify(response.data.data)))
          this.authLevel = response.data.userAuthority ?? UserAuthorityConstant.USER_AUTH_NONE
        } else {
          Object.assign(this.boardInfo, {})
          this.authLevel = UserAuthorityConstant.USER_AUTH_NONE
        }

        this.langCode = currentLangCode

        this.initSearchOption()
      }
    },
    async getBoardInfoByView(boardStaticName: string, postId: string) {
      const currentLangCode = Cookies.get('GWP_LANGUAGE_CODE') ?? 'ko'

      if (
        this.boardInfoByView.boardUrl?.toLowerCase() !== boardStaticName.toLowerCase() ||
        this.postId !== postId ||
        this.langCode !== currentLangCode
      ) {
        const api = new BoardPostApi()
        const response: AxiosResponse<BoardInfoModelApiResult> = await api.v1BoardPostBoardStaticNameEditinfoGet(
          boardStaticName,
          postId,
          currentLangCode
        )

        if (response.data.success) {
          Object.assign(this.boardInfoByView, JSON.parse(JSON.stringify(response.data.data)))
          this.postId = postId
          this.authLevel = response.data.userAuthority ?? UserAuthorityConstant.USER_AUTH_NONE
        } else {
          Object.assign(this.boardInfoByView, {})
          this.postId = ''
          this.authLevel = UserAuthorityConstant.USER_AUTH_NONE
        }

        this.langCode = currentLangCode
      }
    },
    getBoardInfoByModel(boardInfo: BoardInfoModel, userAuthority: UserAuthorityConstant) {
      this.boardInfo = boardInfo
      this.boardInfoByView = boardInfo
      this.authLevel = userAuthority
    },
    isEdit(boardItemInfo: PostInfo | undefined): boolean {
      if (this.isAdmin) return true

      if (this.isWrite) {
        // const userInfo = useUserInfoStore().userInfo
        // if (boardItemInfo && boardItemInfo.creator?.userId == userInfo.userID) {
        //   return true
        // }
        // if (
        //   // 닉네임 사용 게시판일 경우
        //   (((this.boardInfoByView.identityOption ?? 0) & StandardBoardOption_IdentityOptions.Board_Nickname) ===
        //     StandardBoardOption_IdentityOptions.Board_Nickname ||
        //     ((this.boardInfoByView.identityOption ?? 0) &
        //       StandardBoardOption_IdentityOptions.Board_IdentityOrNickname) ===
        //       StandardBoardOption_IdentityOptions.Board_IdentityOrNickname) &&
        //   boardItemInfo &&
        //   //boardItemInfo.creator?.userName == userInfo.nickName
        // ) {
        //   return true
        // }
        // if (
        //   // 익명 사용 게시판일 경우
        //   (((this.boardInfoByView.identityOption ?? 0) & StandardBoardOption_IdentityOptions.Board_Anonymous) ===
        //     StandardBoardOption_IdentityOptions.Board_Anonymous ||
        //     ((this.boardInfoByView.identityOption ?? 0) &
        //       StandardBoardOption_IdentityOptions.Board_IdentityOrAnonymous) ===
        //       StandardBoardOption_IdentityOptions.Board_IdentityOrAnonymous) &&
        //   boardItemInfo
        // ) {
        //   return true
        // }
        return boardItemInfo?.isEditable ?? false
      }

      return false
    },
    isEnabledEditorAppendMedia() {
      return (this.boardInfoByView.additionalConfiguration?.editorAppendMedia ?? 0) > 0
    },
    setPrimaryImageOfAddedMedia(url: string) {
      this.primaryImageOfAddedMedia = url

      const canvas = document.createElement('CANVAS')
      const img = document.createElement('img')
      img.src = this.primaryImageOfAddedMedia
      const vm = this
      img.onload = function () {
        //@ts-ignore
        vm.primaryImageDataOfAddedMedia = canvas.toDataURL('image/png')
      }
    },
    initSearchOption() {
      // 검색조건 초기화
      Object.assign(this.searchOption, {
        pageNumber: 1,
        searchKeyword: '',
        searchType: '',
        searchPeriod: '',
        fieldInfo: '',
        datePeriod: { from: '', to: '' },
        itemFilter: '',
        fieldDatetimeInfo: ''
      })
      this.socialPageNumber = 1
    },
    async updateCommentLikeOrDislike(
      boardStaticName: string,
      postId: string,
      commentId: number,
      boardType: string,
      isLike?: boolean
    ) {
      const api = new CommentApi()
      const response = await api.v1BoardTypeCommentBoardStaticNameRecommendPostIdCommentIdPost(
        boardStaticName,
        postId,
        commentId,
        boardType,
        isLike
      )

      if (response.data.success) {
        this.resultStatus = response.data.data ?? -2
      } else {
        this.resultStatus = -2
      }
    }
  }
})

export interface IBoard {
  postId: string
  boardInfo: BoardInfoModel
  boardInfoByView: BoardInfoModel
  langCode: string
  authLevel: UserAuthorityConstant
  searchOption: SearchOption
  socialPageNumber: number
  resultStatus: number
  primaryImageOfAddedMedia: string
  primaryImageDataOfAddedMedia: string
}

export type BoardStoreType = ReturnType<typeof useBoardStore>

export const getAuthLevel = (boardStore: BoardStoreType) => {
  return boardStore.authLevel
}

export const isBoardAdmin = (boardStore: BoardStoreType) => {
  return boardStore.isAdmin
}

export const getLangCode = (boardStore: BoardStoreType) => {
  return boardStore.langCode
}
