/* tslint:disable */
/* eslint-disable */
/**
 * Mik.SmartPortal.Reservation.App
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import type { RequestArgs } from '../base';
// @ts-ignore
import type { ApiResult } from '../models';
// @ts-ignore
import type { AttendantContainerApiResult } from '../models';
// @ts-ignore
import type { AvailableEquipmentRequest } from '../models';
// @ts-ignore
import type { BuildingEntityListApiResult } from '../models';
// @ts-ignore
import type { BuildingFloorEntityListApiResult } from '../models';
// @ts-ignore
import type { EquipmentEntityListApiResult } from '../models';
// @ts-ignore
import type { EquipmentReservationEntity } from '../models';
// @ts-ignore
import type { EquipmentReservationEntityApiResult } from '../models';
// @ts-ignore
import type { EquipmentReservationEntityPagedApiResult } from '../models';
// @ts-ignore
import type { EquipmentResourceEntityApiResult } from '../models';
// @ts-ignore
import type { EquipmentResourceEntityListApiResult } from '../models';
// @ts-ignore
import type { MeetingRoomEntityListApiResult } from '../models';
// @ts-ignore
import type { MeetingRoomReservationEntityApiResult } from '../models';
// @ts-ignore
import type { MeetingRoomReservationEntityPagedApiResult } from '../models';
// @ts-ignore
import type { MeetingRoomReservationRequest } from '../models';
// @ts-ignore
import type { MeetingRoomResourceEntityApiResult } from '../models';
// @ts-ignore
import type { MeetingRoomResourceEntityListApiResult } from '../models';
// @ts-ignore
import type { MeetingRoomResourceRequest } from '../models';
// @ts-ignore
import type { ReservationCheckStatusResponseApiResult } from '../models';
/**
 * ReservationApi - axios parameter creator
 * @export
 */
export const ReservationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 회의실 수정 가능 목록을 반환합니다.
         * @param {number} [buildingID] 
         * @param {number} [buildingFloorID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationBuildingFloorEditableMeetingroomsGet: async (buildingID?: number, buildingFloorID?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reservation/building/floor/editable/meetingrooms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (buildingID !== undefined) {
                localVarQueryParameter['buildingID'] = buildingID;
            }

            if (buildingFloorID !== undefined) {
                localVarQueryParameter['buildingFloorID'] = buildingFloorID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 회의실 목록을 반환합니다.
         * @param {string} [tenantID] 
         * @param {number} [buildingID] 
         * @param {number} [buildingFloorID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationBuildingFloorMeetingroomsGet: async (tenantID?: string, buildingID?: number, buildingFloorID?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reservation/building/floor/meetingrooms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tenantID !== undefined) {
                localVarQueryParameter['tenantID'] = tenantID;
            }

            if (buildingID !== undefined) {
                localVarQueryParameter['buildingID'] = buildingID;
            }

            if (buildingFloorID !== undefined) {
                localVarQueryParameter['buildingFloorID'] = buildingFloorID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 층 목록을 반환합니다.
         * @param {string} [tenantID] 
         * @param {number} [buildingID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationBuildingFloorsGet: async (tenantID?: string, buildingID?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reservation/building/floors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tenantID !== undefined) {
                localVarQueryParameter['tenantID'] = tenantID;
            }

            if (buildingID !== undefined) {
                localVarQueryParameter['buildingID'] = buildingID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 사옥 목록을 반환합니다.
         * @param {string} [tenantID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationBuildingsGet: async (tenantID?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reservation/buildings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tenantID !== undefined) {
                localVarQueryParameter['tenantID'] = tenantID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 장비 예약 전 중복된 예약 체크를 합니다.
         * @param {EquipmentReservationEntity} [equipmentReservationEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationEquipmentReservationCheckPost: async (equipmentReservationEntity?: EquipmentReservationEntity, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reservation/equipment/reservation/check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(equipmentReservationEntity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 장비 예약 정보를 수정합니다.
         * @param {EquipmentReservationEntity} [equipmentReservationEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationEquipmentReservationEditPost: async (equipmentReservationEntity?: EquipmentReservationEntity, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reservation/equipment/reservation/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(equipmentReservationEntity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 장비 예약 상태를 업데이트합니다.
         * @param {boolean} [isAllRecurrence] 
         * @param {EquipmentReservationEntity} [equipmentReservationEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationEquipmentReservationProcessEditPost: async (isAllRecurrence?: boolean, equipmentReservationEntity?: EquipmentReservationEntity, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reservation/equipment/reservation/process/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (isAllRecurrence !== undefined) {
                localVarQueryParameter['isAllRecurrence'] = isAllRecurrence;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(equipmentReservationEntity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 장비 예약 정보를 삭제합니다.
         * @param {boolean} [removeAllRecurrence] 
         * @param {EquipmentReservationEntity} [equipmentReservationEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationEquipmentReservationRemovePost: async (removeAllRecurrence?: boolean, equipmentReservationEntity?: EquipmentReservationEntity, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reservation/equipment/reservation/remove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (removeAllRecurrence !== undefined) {
                localVarQueryParameter['removeAllRecurrence'] = removeAllRecurrence;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(equipmentReservationEntity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 장비 예약 정보를 가져옵니다.
         * @param {number} reservationID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationEquipmentReservationReservationIDGet: async (reservationID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reservationID' is not null or undefined
            assertParamExists('v1ReservationEquipmentReservationReservationIDGet', 'reservationID', reservationID)
            const localVarPath = `/v1/reservation/equipment/reservation/{reservationID}`
                .replace(`{${"reservationID"}}`, encodeURIComponent(String(reservationID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 장비 예약 정보를 저장합니다.
         * @param {EquipmentReservationEntity} [equipmentReservationEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationEquipmentReservationSavePost: async (equipmentReservationEntity?: EquipmentReservationEntity, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reservation/equipment/reservation/save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(equipmentReservationEntity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 장비 예약내역을 반환합니다.
         * @param {number} equipmentID 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [startDatetime] 
         * @param {string} [endDatetime] 
         * @param {string} [timeZoneID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationEquipmentReservationsEquipmentIDGet: async (equipmentID: number, pageNum?: number, pageSize?: number, startDatetime?: string, endDatetime?: string, timeZoneID?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'equipmentID' is not null or undefined
            assertParamExists('v1ReservationEquipmentReservationsEquipmentIDGet', 'equipmentID', equipmentID)
            const localVarPath = `/v1/reservation/equipment/reservations/{equipmentID}`
                .replace(`{${"equipmentID"}}`, encodeURIComponent(String(equipmentID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (startDatetime !== undefined) {
                localVarQueryParameter['startDatetime'] = (startDatetime as any instanceof Date) ?
                    (startDatetime as any).toISOString() :
                    startDatetime;
            }

            if (endDatetime !== undefined) {
                localVarQueryParameter['endDatetime'] = (endDatetime as any instanceof Date) ?
                    (endDatetime as any).toISOString() :
                    endDatetime;
            }

            if (timeZoneID !== undefined) {
                localVarQueryParameter['timeZoneID'] = timeZoneID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 장비 상세 예약에 필요한  리소스 데이터를 반환합니다.
         * @param {number} equipmentID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationEquipmentResourceEquipmentIDGet: async (equipmentID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'equipmentID' is not null or undefined
            assertParamExists('v1ReservationEquipmentResourceEquipmentIDGet', 'equipmentID', equipmentID)
            const localVarPath = `/v1/reservation/equipment/resource/{equipmentID}`
                .replace(`{${"equipmentID"}}`, encodeURIComponent(String(equipmentID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 사옥 상세 예약 시 예약가능한 장비 목록을 반환합니다.
         * @param {AvailableEquipmentRequest} [availableEquipmentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationEqupmentAvailablePost: async (availableEquipmentRequest?: AvailableEquipmentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reservation/equpment/available`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(availableEquipmentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 장비 예약 타임페이블 렌더링 시 필요한 리소스 목록을 반환합니다.
         * @param {string} [targetDate] 
         * @param {number} [buildingID] 
         * @param {boolean} [useDefaultTimeZone] 
         * @param {string} [timeZoneID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationEqupmentResourcesGet: async (targetDate?: string, buildingID?: number, useDefaultTimeZone?: boolean, timeZoneID?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reservation/equpment/resources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (targetDate !== undefined) {
                localVarQueryParameter['targetDate'] = (targetDate as any instanceof Date) ?
                    (targetDate as any).toISOString() :
                    targetDate;
            }

            if (buildingID !== undefined) {
                localVarQueryParameter['buildingID'] = buildingID;
            }

            if (useDefaultTimeZone !== undefined) {
                localVarQueryParameter['useDefaultTimeZone'] = useDefaultTimeZone;
            }

            if (timeZoneID !== undefined) {
                localVarQueryParameter['timeZoneID'] = timeZoneID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 회의 참석자 목록을 반환합니다.
         * @param {string} tenantID 
         * @param {number} reservationID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationMeetingroomAttendantTenantIDReservationIDGet: async (tenantID: string, reservationID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantID' is not null or undefined
            assertParamExists('v1ReservationMeetingroomAttendantTenantIDReservationIDGet', 'tenantID', tenantID)
            // verify required parameter 'reservationID' is not null or undefined
            assertParamExists('v1ReservationMeetingroomAttendantTenantIDReservationIDGet', 'reservationID', reservationID)
            const localVarPath = `/v1/reservation/meetingroom/attendant/{tenantID}/{reservationID}`
                .replace(`{${"tenantID"}}`, encodeURIComponent(String(tenantID)))
                .replace(`{${"reservationID"}}`, encodeURIComponent(String(reservationID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 예약가능 회의실 목록을 가져옵니다.
         * @param {MeetingRoomResourceRequest} [meetingRoomResourceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationMeetingroomReservationAvailablePost: async (meetingRoomResourceRequest?: MeetingRoomResourceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reservation/meetingroom/reservation/available`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(meetingRoomResourceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 회의실 예약 전 중복된 예약 체크를 합니다.
         * @param {MeetingRoomReservationRequest} [meetingRoomReservationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationMeetingroomReservationCheckPost: async (meetingRoomReservationRequest?: MeetingRoomReservationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reservation/meetingroom/reservation/check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(meetingRoomReservationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 회의실 예약 정보를 수정합니다.
         * @param {string} [attachmentGuid] 첨부파일이 추가된 경우 attachmentGuid를 통해 추가된 첨부파일 데이터에 회의실 예약ID를 업데이트합니다.
         * @param {MeetingRoomReservationRequest} [meetingRoomReservationRequest] 회의실 예약 data를 body에 담아 전달합니다.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationMeetingroomReservationEditPost: async (attachmentGuid?: string, meetingRoomReservationRequest?: MeetingRoomReservationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reservation/meetingroom/reservation/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (attachmentGuid !== undefined) {
                localVarQueryParameter['attachmentGuid'] = attachmentGuid;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(meetingRoomReservationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 회의실 예약 상태를 업데이트합니다.
         * @param {boolean} [isAllRecurrence] 
         * @param {MeetingRoomReservationRequest} [meetingRoomReservationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationMeetingroomReservationProcessEditPost: async (isAllRecurrence?: boolean, meetingRoomReservationRequest?: MeetingRoomReservationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reservation/meetingroom/reservation/process/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (isAllRecurrence !== undefined) {
                localVarQueryParameter['isAllRecurrence'] = isAllRecurrence;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(meetingRoomReservationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 회의실 예약 정보를 삭제합니다.
         * @param {boolean} [removeAllRecurrence] 
         * @param {MeetingRoomReservationRequest} [meetingRoomReservationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationMeetingroomReservationRemovePost: async (removeAllRecurrence?: boolean, meetingRoomReservationRequest?: MeetingRoomReservationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reservation/meetingroom/reservation/remove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (removeAllRecurrence !== undefined) {
                localVarQueryParameter['removeAllRecurrence'] = removeAllRecurrence;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(meetingRoomReservationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 회의실 예약 정보를 가져옵니다.
         * @param {number} reservationID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationMeetingroomReservationReservationIDGet: async (reservationID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reservationID' is not null or undefined
            assertParamExists('v1ReservationMeetingroomReservationReservationIDGet', 'reservationID', reservationID)
            const localVarPath = `/v1/reservation/meetingroom/reservation/{reservationID}`
                .replace(`{${"reservationID"}}`, encodeURIComponent(String(reservationID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 회의실 예약 정보를 저장합니다.
         * @param {string} [attachmentGuid] 첨부파일이 추가된 경우 attachmentGuid를 통해 추가된 첨부파일 데이터에 회의실 예약ID를 업데이트합니다.
         * @param {MeetingRoomReservationRequest} [meetingRoomReservationRequest] 회의실 예약 data를 body에 담아 전달합니다.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationMeetingroomReservationSavePost: async (attachmentGuid?: string, meetingRoomReservationRequest?: MeetingRoomReservationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reservation/meetingroom/reservation/save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (attachmentGuid !== undefined) {
                localVarQueryParameter['attachmentGuid'] = attachmentGuid;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(meetingRoomReservationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 회의실 예약내역을 반환합니다.
         * @param {number} roomID 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [startDatetime] 
         * @param {string} [endDatetime] 
         * @param {string} [timeZoneID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationMeetingroomReservationsRoomIDGet: async (roomID: number, pageNum?: number, pageSize?: number, startDatetime?: string, endDatetime?: string, timeZoneID?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roomID' is not null or undefined
            assertParamExists('v1ReservationMeetingroomReservationsRoomIDGet', 'roomID', roomID)
            const localVarPath = `/v1/reservation/meetingroom/reservations/{roomID}`
                .replace(`{${"roomID"}}`, encodeURIComponent(String(roomID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (startDatetime !== undefined) {
                localVarQueryParameter['startDatetime'] = (startDatetime as any instanceof Date) ?
                    (startDatetime as any).toISOString() :
                    startDatetime;
            }

            if (endDatetime !== undefined) {
                localVarQueryParameter['endDatetime'] = (endDatetime as any instanceof Date) ?
                    (endDatetime as any).toISOString() :
                    endDatetime;
            }

            if (timeZoneID !== undefined) {
                localVarQueryParameter['timeZoneID'] = timeZoneID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 회의실 상세 예약에 필요한  리소스 데이터를 반환합니다.
         * @param {number} roomID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationMeetingroomResourceRoomIDGet: async (roomID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roomID' is not null or undefined
            assertParamExists('v1ReservationMeetingroomResourceRoomIDGet', 'roomID', roomID)
            const localVarPath = `/v1/reservation/meetingroom/resource/{roomID}`
                .replace(`{${"roomID"}}`, encodeURIComponent(String(roomID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 회의실 예약 타임페이블 렌더링 시 필요한 리소스 목록을 반환합니다.
         * @param {MeetingRoomResourceRequest} [meetingRoomResourceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationMeetingroomResourcesPost: async (meetingRoomResourceRequest?: MeetingRoomResourceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reservation/meetingroom/resources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(meetingRoomResourceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReservationApi - functional programming interface
 * @export
 */
export const ReservationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReservationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 회의실 수정 가능 목록을 반환합니다.
         * @param {number} [buildingID] 
         * @param {number} [buildingFloorID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReservationBuildingFloorEditableMeetingroomsGet(buildingID?: number, buildingFloorID?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeetingRoomResourceEntityListApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReservationBuildingFloorEditableMeetingroomsGet(buildingID, buildingFloorID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 회의실 목록을 반환합니다.
         * @param {string} [tenantID] 
         * @param {number} [buildingID] 
         * @param {number} [buildingFloorID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReservationBuildingFloorMeetingroomsGet(tenantID?: string, buildingID?: number, buildingFloorID?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeetingRoomEntityListApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReservationBuildingFloorMeetingroomsGet(tenantID, buildingID, buildingFloorID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 층 목록을 반환합니다.
         * @param {string} [tenantID] 
         * @param {number} [buildingID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReservationBuildingFloorsGet(tenantID?: string, buildingID?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuildingFloorEntityListApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReservationBuildingFloorsGet(tenantID, buildingID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 사옥 목록을 반환합니다.
         * @param {string} [tenantID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReservationBuildingsGet(tenantID?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuildingEntityListApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReservationBuildingsGet(tenantID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 장비 예약 전 중복된 예약 체크를 합니다.
         * @param {EquipmentReservationEntity} [equipmentReservationEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReservationEquipmentReservationCheckPost(equipmentReservationEntity?: EquipmentReservationEntity, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReservationCheckStatusResponseApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReservationEquipmentReservationCheckPost(equipmentReservationEntity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 장비 예약 정보를 수정합니다.
         * @param {EquipmentReservationEntity} [equipmentReservationEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReservationEquipmentReservationEditPost(equipmentReservationEntity?: EquipmentReservationEntity, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReservationCheckStatusResponseApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReservationEquipmentReservationEditPost(equipmentReservationEntity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 장비 예약 상태를 업데이트합니다.
         * @param {boolean} [isAllRecurrence] 
         * @param {EquipmentReservationEntity} [equipmentReservationEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReservationEquipmentReservationProcessEditPost(isAllRecurrence?: boolean, equipmentReservationEntity?: EquipmentReservationEntity, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReservationEquipmentReservationProcessEditPost(isAllRecurrence, equipmentReservationEntity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 장비 예약 정보를 삭제합니다.
         * @param {boolean} [removeAllRecurrence] 
         * @param {EquipmentReservationEntity} [equipmentReservationEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReservationEquipmentReservationRemovePost(removeAllRecurrence?: boolean, equipmentReservationEntity?: EquipmentReservationEntity, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReservationEquipmentReservationRemovePost(removeAllRecurrence, equipmentReservationEntity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 장비 예약 정보를 가져옵니다.
         * @param {number} reservationID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReservationEquipmentReservationReservationIDGet(reservationID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentReservationEntityApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReservationEquipmentReservationReservationIDGet(reservationID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 장비 예약 정보를 저장합니다.
         * @param {EquipmentReservationEntity} [equipmentReservationEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReservationEquipmentReservationSavePost(equipmentReservationEntity?: EquipmentReservationEntity, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReservationCheckStatusResponseApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReservationEquipmentReservationSavePost(equipmentReservationEntity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 장비 예약내역을 반환합니다.
         * @param {number} equipmentID 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [startDatetime] 
         * @param {string} [endDatetime] 
         * @param {string} [timeZoneID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReservationEquipmentReservationsEquipmentIDGet(equipmentID: number, pageNum?: number, pageSize?: number, startDatetime?: string, endDatetime?: string, timeZoneID?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentReservationEntityPagedApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReservationEquipmentReservationsEquipmentIDGet(equipmentID, pageNum, pageSize, startDatetime, endDatetime, timeZoneID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 장비 상세 예약에 필요한  리소스 데이터를 반환합니다.
         * @param {number} equipmentID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReservationEquipmentResourceEquipmentIDGet(equipmentID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentResourceEntityApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReservationEquipmentResourceEquipmentIDGet(equipmentID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 사옥 상세 예약 시 예약가능한 장비 목록을 반환합니다.
         * @param {AvailableEquipmentRequest} [availableEquipmentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReservationEqupmentAvailablePost(availableEquipmentRequest?: AvailableEquipmentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentEntityListApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReservationEqupmentAvailablePost(availableEquipmentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 장비 예약 타임페이블 렌더링 시 필요한 리소스 목록을 반환합니다.
         * @param {string} [targetDate] 
         * @param {number} [buildingID] 
         * @param {boolean} [useDefaultTimeZone] 
         * @param {string} [timeZoneID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReservationEqupmentResourcesGet(targetDate?: string, buildingID?: number, useDefaultTimeZone?: boolean, timeZoneID?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentResourceEntityListApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReservationEqupmentResourcesGet(targetDate, buildingID, useDefaultTimeZone, timeZoneID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 회의 참석자 목록을 반환합니다.
         * @param {string} tenantID 
         * @param {number} reservationID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReservationMeetingroomAttendantTenantIDReservationIDGet(tenantID: string, reservationID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AttendantContainerApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReservationMeetingroomAttendantTenantIDReservationIDGet(tenantID, reservationID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 예약가능 회의실 목록을 가져옵니다.
         * @param {MeetingRoomResourceRequest} [meetingRoomResourceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReservationMeetingroomReservationAvailablePost(meetingRoomResourceRequest?: MeetingRoomResourceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeetingRoomEntityListApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReservationMeetingroomReservationAvailablePost(meetingRoomResourceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 회의실 예약 전 중복된 예약 체크를 합니다.
         * @param {MeetingRoomReservationRequest} [meetingRoomReservationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReservationMeetingroomReservationCheckPost(meetingRoomReservationRequest?: MeetingRoomReservationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReservationCheckStatusResponseApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReservationMeetingroomReservationCheckPost(meetingRoomReservationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 회의실 예약 정보를 수정합니다.
         * @param {string} [attachmentGuid] 첨부파일이 추가된 경우 attachmentGuid를 통해 추가된 첨부파일 데이터에 회의실 예약ID를 업데이트합니다.
         * @param {MeetingRoomReservationRequest} [meetingRoomReservationRequest] 회의실 예약 data를 body에 담아 전달합니다.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReservationMeetingroomReservationEditPost(attachmentGuid?: string, meetingRoomReservationRequest?: MeetingRoomReservationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReservationCheckStatusResponseApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReservationMeetingroomReservationEditPost(attachmentGuid, meetingRoomReservationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 회의실 예약 상태를 업데이트합니다.
         * @param {boolean} [isAllRecurrence] 
         * @param {MeetingRoomReservationRequest} [meetingRoomReservationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReservationMeetingroomReservationProcessEditPost(isAllRecurrence?: boolean, meetingRoomReservationRequest?: MeetingRoomReservationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReservationMeetingroomReservationProcessEditPost(isAllRecurrence, meetingRoomReservationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 회의실 예약 정보를 삭제합니다.
         * @param {boolean} [removeAllRecurrence] 
         * @param {MeetingRoomReservationRequest} [meetingRoomReservationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReservationMeetingroomReservationRemovePost(removeAllRecurrence?: boolean, meetingRoomReservationRequest?: MeetingRoomReservationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReservationMeetingroomReservationRemovePost(removeAllRecurrence, meetingRoomReservationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 회의실 예약 정보를 가져옵니다.
         * @param {number} reservationID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReservationMeetingroomReservationReservationIDGet(reservationID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeetingRoomReservationEntityApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReservationMeetingroomReservationReservationIDGet(reservationID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 회의실 예약 정보를 저장합니다.
         * @param {string} [attachmentGuid] 첨부파일이 추가된 경우 attachmentGuid를 통해 추가된 첨부파일 데이터에 회의실 예약ID를 업데이트합니다.
         * @param {MeetingRoomReservationRequest} [meetingRoomReservationRequest] 회의실 예약 data를 body에 담아 전달합니다.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReservationMeetingroomReservationSavePost(attachmentGuid?: string, meetingRoomReservationRequest?: MeetingRoomReservationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReservationCheckStatusResponseApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReservationMeetingroomReservationSavePost(attachmentGuid, meetingRoomReservationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 회의실 예약내역을 반환합니다.
         * @param {number} roomID 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [startDatetime] 
         * @param {string} [endDatetime] 
         * @param {string} [timeZoneID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReservationMeetingroomReservationsRoomIDGet(roomID: number, pageNum?: number, pageSize?: number, startDatetime?: string, endDatetime?: string, timeZoneID?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeetingRoomReservationEntityPagedApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReservationMeetingroomReservationsRoomIDGet(roomID, pageNum, pageSize, startDatetime, endDatetime, timeZoneID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 회의실 상세 예약에 필요한  리소스 데이터를 반환합니다.
         * @param {number} roomID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReservationMeetingroomResourceRoomIDGet(roomID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeetingRoomResourceEntityApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReservationMeetingroomResourceRoomIDGet(roomID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 회의실 예약 타임페이블 렌더링 시 필요한 리소스 목록을 반환합니다.
         * @param {MeetingRoomResourceRequest} [meetingRoomResourceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReservationMeetingroomResourcesPost(meetingRoomResourceRequest?: MeetingRoomResourceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeetingRoomResourceEntityListApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReservationMeetingroomResourcesPost(meetingRoomResourceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReservationApi - factory interface
 * @export
 */
export const ReservationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReservationApiFp(configuration)
    return {
        /**
         * 
         * @summary 회의실 수정 가능 목록을 반환합니다.
         * @param {number} [buildingID] 
         * @param {number} [buildingFloorID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationBuildingFloorEditableMeetingroomsGet(buildingID?: number, buildingFloorID?: number, options?: any): AxiosPromise<MeetingRoomResourceEntityListApiResult> {
            return localVarFp.v1ReservationBuildingFloorEditableMeetingroomsGet(buildingID, buildingFloorID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 회의실 목록을 반환합니다.
         * @param {string} [tenantID] 
         * @param {number} [buildingID] 
         * @param {number} [buildingFloorID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationBuildingFloorMeetingroomsGet(tenantID?: string, buildingID?: number, buildingFloorID?: number, options?: any): AxiosPromise<MeetingRoomEntityListApiResult> {
            return localVarFp.v1ReservationBuildingFloorMeetingroomsGet(tenantID, buildingID, buildingFloorID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 층 목록을 반환합니다.
         * @param {string} [tenantID] 
         * @param {number} [buildingID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationBuildingFloorsGet(tenantID?: string, buildingID?: number, options?: any): AxiosPromise<BuildingFloorEntityListApiResult> {
            return localVarFp.v1ReservationBuildingFloorsGet(tenantID, buildingID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 사옥 목록을 반환합니다.
         * @param {string} [tenantID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationBuildingsGet(tenantID?: string, options?: any): AxiosPromise<BuildingEntityListApiResult> {
            return localVarFp.v1ReservationBuildingsGet(tenantID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 장비 예약 전 중복된 예약 체크를 합니다.
         * @param {EquipmentReservationEntity} [equipmentReservationEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationEquipmentReservationCheckPost(equipmentReservationEntity?: EquipmentReservationEntity, options?: any): AxiosPromise<ReservationCheckStatusResponseApiResult> {
            return localVarFp.v1ReservationEquipmentReservationCheckPost(equipmentReservationEntity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 장비 예약 정보를 수정합니다.
         * @param {EquipmentReservationEntity} [equipmentReservationEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationEquipmentReservationEditPost(equipmentReservationEntity?: EquipmentReservationEntity, options?: any): AxiosPromise<ReservationCheckStatusResponseApiResult> {
            return localVarFp.v1ReservationEquipmentReservationEditPost(equipmentReservationEntity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 장비 예약 상태를 업데이트합니다.
         * @param {boolean} [isAllRecurrence] 
         * @param {EquipmentReservationEntity} [equipmentReservationEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationEquipmentReservationProcessEditPost(isAllRecurrence?: boolean, equipmentReservationEntity?: EquipmentReservationEntity, options?: any): AxiosPromise<ApiResult> {
            return localVarFp.v1ReservationEquipmentReservationProcessEditPost(isAllRecurrence, equipmentReservationEntity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 장비 예약 정보를 삭제합니다.
         * @param {boolean} [removeAllRecurrence] 
         * @param {EquipmentReservationEntity} [equipmentReservationEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationEquipmentReservationRemovePost(removeAllRecurrence?: boolean, equipmentReservationEntity?: EquipmentReservationEntity, options?: any): AxiosPromise<ApiResult> {
            return localVarFp.v1ReservationEquipmentReservationRemovePost(removeAllRecurrence, equipmentReservationEntity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 장비 예약 정보를 가져옵니다.
         * @param {number} reservationID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationEquipmentReservationReservationIDGet(reservationID: number, options?: any): AxiosPromise<EquipmentReservationEntityApiResult> {
            return localVarFp.v1ReservationEquipmentReservationReservationIDGet(reservationID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 장비 예약 정보를 저장합니다.
         * @param {EquipmentReservationEntity} [equipmentReservationEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationEquipmentReservationSavePost(equipmentReservationEntity?: EquipmentReservationEntity, options?: any): AxiosPromise<ReservationCheckStatusResponseApiResult> {
            return localVarFp.v1ReservationEquipmentReservationSavePost(equipmentReservationEntity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 장비 예약내역을 반환합니다.
         * @param {number} equipmentID 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [startDatetime] 
         * @param {string} [endDatetime] 
         * @param {string} [timeZoneID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationEquipmentReservationsEquipmentIDGet(equipmentID: number, pageNum?: number, pageSize?: number, startDatetime?: string, endDatetime?: string, timeZoneID?: string, options?: any): AxiosPromise<EquipmentReservationEntityPagedApiResult> {
            return localVarFp.v1ReservationEquipmentReservationsEquipmentIDGet(equipmentID, pageNum, pageSize, startDatetime, endDatetime, timeZoneID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 장비 상세 예약에 필요한  리소스 데이터를 반환합니다.
         * @param {number} equipmentID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationEquipmentResourceEquipmentIDGet(equipmentID: number, options?: any): AxiosPromise<EquipmentResourceEntityApiResult> {
            return localVarFp.v1ReservationEquipmentResourceEquipmentIDGet(equipmentID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 사옥 상세 예약 시 예약가능한 장비 목록을 반환합니다.
         * @param {AvailableEquipmentRequest} [availableEquipmentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationEqupmentAvailablePost(availableEquipmentRequest?: AvailableEquipmentRequest, options?: any): AxiosPromise<EquipmentEntityListApiResult> {
            return localVarFp.v1ReservationEqupmentAvailablePost(availableEquipmentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 장비 예약 타임페이블 렌더링 시 필요한 리소스 목록을 반환합니다.
         * @param {string} [targetDate] 
         * @param {number} [buildingID] 
         * @param {boolean} [useDefaultTimeZone] 
         * @param {string} [timeZoneID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationEqupmentResourcesGet(targetDate?: string, buildingID?: number, useDefaultTimeZone?: boolean, timeZoneID?: string, options?: any): AxiosPromise<EquipmentResourceEntityListApiResult> {
            return localVarFp.v1ReservationEqupmentResourcesGet(targetDate, buildingID, useDefaultTimeZone, timeZoneID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 회의 참석자 목록을 반환합니다.
         * @param {string} tenantID 
         * @param {number} reservationID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationMeetingroomAttendantTenantIDReservationIDGet(tenantID: string, reservationID: number, options?: any): AxiosPromise<AttendantContainerApiResult> {
            return localVarFp.v1ReservationMeetingroomAttendantTenantIDReservationIDGet(tenantID, reservationID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 예약가능 회의실 목록을 가져옵니다.
         * @param {MeetingRoomResourceRequest} [meetingRoomResourceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationMeetingroomReservationAvailablePost(meetingRoomResourceRequest?: MeetingRoomResourceRequest, options?: any): AxiosPromise<MeetingRoomEntityListApiResult> {
            return localVarFp.v1ReservationMeetingroomReservationAvailablePost(meetingRoomResourceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 회의실 예약 전 중복된 예약 체크를 합니다.
         * @param {MeetingRoomReservationRequest} [meetingRoomReservationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationMeetingroomReservationCheckPost(meetingRoomReservationRequest?: MeetingRoomReservationRequest, options?: any): AxiosPromise<ReservationCheckStatusResponseApiResult> {
            return localVarFp.v1ReservationMeetingroomReservationCheckPost(meetingRoomReservationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 회의실 예약 정보를 수정합니다.
         * @param {string} [attachmentGuid] 첨부파일이 추가된 경우 attachmentGuid를 통해 추가된 첨부파일 데이터에 회의실 예약ID를 업데이트합니다.
         * @param {MeetingRoomReservationRequest} [meetingRoomReservationRequest] 회의실 예약 data를 body에 담아 전달합니다.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationMeetingroomReservationEditPost(attachmentGuid?: string, meetingRoomReservationRequest?: MeetingRoomReservationRequest, options?: any): AxiosPromise<ReservationCheckStatusResponseApiResult> {
            return localVarFp.v1ReservationMeetingroomReservationEditPost(attachmentGuid, meetingRoomReservationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 회의실 예약 상태를 업데이트합니다.
         * @param {boolean} [isAllRecurrence] 
         * @param {MeetingRoomReservationRequest} [meetingRoomReservationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationMeetingroomReservationProcessEditPost(isAllRecurrence?: boolean, meetingRoomReservationRequest?: MeetingRoomReservationRequest, options?: any): AxiosPromise<ApiResult> {
            return localVarFp.v1ReservationMeetingroomReservationProcessEditPost(isAllRecurrence, meetingRoomReservationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 회의실 예약 정보를 삭제합니다.
         * @param {boolean} [removeAllRecurrence] 
         * @param {MeetingRoomReservationRequest} [meetingRoomReservationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationMeetingroomReservationRemovePost(removeAllRecurrence?: boolean, meetingRoomReservationRequest?: MeetingRoomReservationRequest, options?: any): AxiosPromise<ApiResult> {
            return localVarFp.v1ReservationMeetingroomReservationRemovePost(removeAllRecurrence, meetingRoomReservationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 회의실 예약 정보를 가져옵니다.
         * @param {number} reservationID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationMeetingroomReservationReservationIDGet(reservationID: number, options?: any): AxiosPromise<MeetingRoomReservationEntityApiResult> {
            return localVarFp.v1ReservationMeetingroomReservationReservationIDGet(reservationID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 회의실 예약 정보를 저장합니다.
         * @param {string} [attachmentGuid] 첨부파일이 추가된 경우 attachmentGuid를 통해 추가된 첨부파일 데이터에 회의실 예약ID를 업데이트합니다.
         * @param {MeetingRoomReservationRequest} [meetingRoomReservationRequest] 회의실 예약 data를 body에 담아 전달합니다.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationMeetingroomReservationSavePost(attachmentGuid?: string, meetingRoomReservationRequest?: MeetingRoomReservationRequest, options?: any): AxiosPromise<ReservationCheckStatusResponseApiResult> {
            return localVarFp.v1ReservationMeetingroomReservationSavePost(attachmentGuid, meetingRoomReservationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 회의실 예약내역을 반환합니다.
         * @param {number} roomID 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [startDatetime] 
         * @param {string} [endDatetime] 
         * @param {string} [timeZoneID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationMeetingroomReservationsRoomIDGet(roomID: number, pageNum?: number, pageSize?: number, startDatetime?: string, endDatetime?: string, timeZoneID?: string, options?: any): AxiosPromise<MeetingRoomReservationEntityPagedApiResult> {
            return localVarFp.v1ReservationMeetingroomReservationsRoomIDGet(roomID, pageNum, pageSize, startDatetime, endDatetime, timeZoneID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 회의실 상세 예약에 필요한  리소스 데이터를 반환합니다.
         * @param {number} roomID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationMeetingroomResourceRoomIDGet(roomID: number, options?: any): AxiosPromise<MeetingRoomResourceEntityApiResult> {
            return localVarFp.v1ReservationMeetingroomResourceRoomIDGet(roomID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 회의실 예약 타임페이블 렌더링 시 필요한 리소스 목록을 반환합니다.
         * @param {MeetingRoomResourceRequest} [meetingRoomResourceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReservationMeetingroomResourcesPost(meetingRoomResourceRequest?: MeetingRoomResourceRequest, options?: any): AxiosPromise<MeetingRoomResourceEntityListApiResult> {
            return localVarFp.v1ReservationMeetingroomResourcesPost(meetingRoomResourceRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReservationApi - object-oriented interface
 * @export
 * @class ReservationApi
 * @extends {BaseAPI}
 */
export class ReservationApi extends BaseAPI {
    /**
     * 
     * @summary 회의실 수정 가능 목록을 반환합니다.
     * @param {number} [buildingID] 
     * @param {number} [buildingFloorID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationApi
     */
    public v1ReservationBuildingFloorEditableMeetingroomsGet(buildingID?: number, buildingFloorID?: number, options?: AxiosRequestConfig) {
        return ReservationApiFp(this.configuration).v1ReservationBuildingFloorEditableMeetingroomsGet(buildingID, buildingFloorID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 회의실 목록을 반환합니다.
     * @param {string} [tenantID] 
     * @param {number} [buildingID] 
     * @param {number} [buildingFloorID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationApi
     */
    public v1ReservationBuildingFloorMeetingroomsGet(tenantID?: string, buildingID?: number, buildingFloorID?: number, options?: AxiosRequestConfig) {
        return ReservationApiFp(this.configuration).v1ReservationBuildingFloorMeetingroomsGet(tenantID, buildingID, buildingFloorID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 층 목록을 반환합니다.
     * @param {string} [tenantID] 
     * @param {number} [buildingID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationApi
     */
    public v1ReservationBuildingFloorsGet(tenantID?: string, buildingID?: number, options?: AxiosRequestConfig) {
        return ReservationApiFp(this.configuration).v1ReservationBuildingFloorsGet(tenantID, buildingID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 사옥 목록을 반환합니다.
     * @param {string} [tenantID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationApi
     */
    public v1ReservationBuildingsGet(tenantID?: string, options?: AxiosRequestConfig) {
        return ReservationApiFp(this.configuration).v1ReservationBuildingsGet(tenantID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 장비 예약 전 중복된 예약 체크를 합니다.
     * @param {EquipmentReservationEntity} [equipmentReservationEntity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationApi
     */
    public v1ReservationEquipmentReservationCheckPost(equipmentReservationEntity?: EquipmentReservationEntity, options?: AxiosRequestConfig) {
        return ReservationApiFp(this.configuration).v1ReservationEquipmentReservationCheckPost(equipmentReservationEntity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 장비 예약 정보를 수정합니다.
     * @param {EquipmentReservationEntity} [equipmentReservationEntity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationApi
     */
    public v1ReservationEquipmentReservationEditPost(equipmentReservationEntity?: EquipmentReservationEntity, options?: AxiosRequestConfig) {
        return ReservationApiFp(this.configuration).v1ReservationEquipmentReservationEditPost(equipmentReservationEntity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 장비 예약 상태를 업데이트합니다.
     * @param {boolean} [isAllRecurrence] 
     * @param {EquipmentReservationEntity} [equipmentReservationEntity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationApi
     */
    public v1ReservationEquipmentReservationProcessEditPost(isAllRecurrence?: boolean, equipmentReservationEntity?: EquipmentReservationEntity, options?: AxiosRequestConfig) {
        return ReservationApiFp(this.configuration).v1ReservationEquipmentReservationProcessEditPost(isAllRecurrence, equipmentReservationEntity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 장비 예약 정보를 삭제합니다.
     * @param {boolean} [removeAllRecurrence] 
     * @param {EquipmentReservationEntity} [equipmentReservationEntity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationApi
     */
    public v1ReservationEquipmentReservationRemovePost(removeAllRecurrence?: boolean, equipmentReservationEntity?: EquipmentReservationEntity, options?: AxiosRequestConfig) {
        return ReservationApiFp(this.configuration).v1ReservationEquipmentReservationRemovePost(removeAllRecurrence, equipmentReservationEntity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 장비 예약 정보를 가져옵니다.
     * @param {number} reservationID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationApi
     */
    public v1ReservationEquipmentReservationReservationIDGet(reservationID: number, options?: AxiosRequestConfig) {
        return ReservationApiFp(this.configuration).v1ReservationEquipmentReservationReservationIDGet(reservationID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 장비 예약 정보를 저장합니다.
     * @param {EquipmentReservationEntity} [equipmentReservationEntity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationApi
     */
    public v1ReservationEquipmentReservationSavePost(equipmentReservationEntity?: EquipmentReservationEntity, options?: AxiosRequestConfig) {
        return ReservationApiFp(this.configuration).v1ReservationEquipmentReservationSavePost(equipmentReservationEntity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 장비 예약내역을 반환합니다.
     * @param {number} equipmentID 
     * @param {number} [pageNum] 
     * @param {number} [pageSize] 
     * @param {string} [startDatetime] 
     * @param {string} [endDatetime] 
     * @param {string} [timeZoneID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationApi
     */
    public v1ReservationEquipmentReservationsEquipmentIDGet(equipmentID: number, pageNum?: number, pageSize?: number, startDatetime?: string, endDatetime?: string, timeZoneID?: string, options?: AxiosRequestConfig) {
        return ReservationApiFp(this.configuration).v1ReservationEquipmentReservationsEquipmentIDGet(equipmentID, pageNum, pageSize, startDatetime, endDatetime, timeZoneID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 장비 상세 예약에 필요한  리소스 데이터를 반환합니다.
     * @param {number} equipmentID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationApi
     */
    public v1ReservationEquipmentResourceEquipmentIDGet(equipmentID: number, options?: AxiosRequestConfig) {
        return ReservationApiFp(this.configuration).v1ReservationEquipmentResourceEquipmentIDGet(equipmentID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 사옥 상세 예약 시 예약가능한 장비 목록을 반환합니다.
     * @param {AvailableEquipmentRequest} [availableEquipmentRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationApi
     */
    public v1ReservationEqupmentAvailablePost(availableEquipmentRequest?: AvailableEquipmentRequest, options?: AxiosRequestConfig) {
        return ReservationApiFp(this.configuration).v1ReservationEqupmentAvailablePost(availableEquipmentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 장비 예약 타임페이블 렌더링 시 필요한 리소스 목록을 반환합니다.
     * @param {string} [targetDate] 
     * @param {number} [buildingID] 
     * @param {boolean} [useDefaultTimeZone] 
     * @param {string} [timeZoneID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationApi
     */
    public v1ReservationEqupmentResourcesGet(targetDate?: string, buildingID?: number, useDefaultTimeZone?: boolean, timeZoneID?: string, options?: AxiosRequestConfig) {
        return ReservationApiFp(this.configuration).v1ReservationEqupmentResourcesGet(targetDate, buildingID, useDefaultTimeZone, timeZoneID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 회의 참석자 목록을 반환합니다.
     * @param {string} tenantID 
     * @param {number} reservationID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationApi
     */
    public v1ReservationMeetingroomAttendantTenantIDReservationIDGet(tenantID: string, reservationID: number, options?: AxiosRequestConfig) {
        return ReservationApiFp(this.configuration).v1ReservationMeetingroomAttendantTenantIDReservationIDGet(tenantID, reservationID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 예약가능 회의실 목록을 가져옵니다.
     * @param {MeetingRoomResourceRequest} [meetingRoomResourceRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationApi
     */
    public v1ReservationMeetingroomReservationAvailablePost(meetingRoomResourceRequest?: MeetingRoomResourceRequest, options?: AxiosRequestConfig) {
        return ReservationApiFp(this.configuration).v1ReservationMeetingroomReservationAvailablePost(meetingRoomResourceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 회의실 예약 전 중복된 예약 체크를 합니다.
     * @param {MeetingRoomReservationRequest} [meetingRoomReservationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationApi
     */
    public v1ReservationMeetingroomReservationCheckPost(meetingRoomReservationRequest?: MeetingRoomReservationRequest, options?: AxiosRequestConfig) {
        return ReservationApiFp(this.configuration).v1ReservationMeetingroomReservationCheckPost(meetingRoomReservationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 회의실 예약 정보를 수정합니다.
     * @param {string} [attachmentGuid] 첨부파일이 추가된 경우 attachmentGuid를 통해 추가된 첨부파일 데이터에 회의실 예약ID를 업데이트합니다.
     * @param {MeetingRoomReservationRequest} [meetingRoomReservationRequest] 회의실 예약 data를 body에 담아 전달합니다.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationApi
     */
    public v1ReservationMeetingroomReservationEditPost(attachmentGuid?: string, meetingRoomReservationRequest?: MeetingRoomReservationRequest, options?: AxiosRequestConfig) {
        return ReservationApiFp(this.configuration).v1ReservationMeetingroomReservationEditPost(attachmentGuid, meetingRoomReservationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 회의실 예약 상태를 업데이트합니다.
     * @param {boolean} [isAllRecurrence] 
     * @param {MeetingRoomReservationRequest} [meetingRoomReservationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationApi
     */
    public v1ReservationMeetingroomReservationProcessEditPost(isAllRecurrence?: boolean, meetingRoomReservationRequest?: MeetingRoomReservationRequest, options?: AxiosRequestConfig) {
        return ReservationApiFp(this.configuration).v1ReservationMeetingroomReservationProcessEditPost(isAllRecurrence, meetingRoomReservationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 회의실 예약 정보를 삭제합니다.
     * @param {boolean} [removeAllRecurrence] 
     * @param {MeetingRoomReservationRequest} [meetingRoomReservationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationApi
     */
    public v1ReservationMeetingroomReservationRemovePost(removeAllRecurrence?: boolean, meetingRoomReservationRequest?: MeetingRoomReservationRequest, options?: AxiosRequestConfig) {
        return ReservationApiFp(this.configuration).v1ReservationMeetingroomReservationRemovePost(removeAllRecurrence, meetingRoomReservationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 회의실 예약 정보를 가져옵니다.
     * @param {number} reservationID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationApi
     */
    public v1ReservationMeetingroomReservationReservationIDGet(reservationID: number, options?: AxiosRequestConfig) {
        return ReservationApiFp(this.configuration).v1ReservationMeetingroomReservationReservationIDGet(reservationID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 회의실 예약 정보를 저장합니다.
     * @param {string} [attachmentGuid] 첨부파일이 추가된 경우 attachmentGuid를 통해 추가된 첨부파일 데이터에 회의실 예약ID를 업데이트합니다.
     * @param {MeetingRoomReservationRequest} [meetingRoomReservationRequest] 회의실 예약 data를 body에 담아 전달합니다.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationApi
     */
    public v1ReservationMeetingroomReservationSavePost(attachmentGuid?: string, meetingRoomReservationRequest?: MeetingRoomReservationRequest, options?: AxiosRequestConfig) {
        return ReservationApiFp(this.configuration).v1ReservationMeetingroomReservationSavePost(attachmentGuid, meetingRoomReservationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 회의실 예약내역을 반환합니다.
     * @param {number} roomID 
     * @param {number} [pageNum] 
     * @param {number} [pageSize] 
     * @param {string} [startDatetime] 
     * @param {string} [endDatetime] 
     * @param {string} [timeZoneID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationApi
     */
    public v1ReservationMeetingroomReservationsRoomIDGet(roomID: number, pageNum?: number, pageSize?: number, startDatetime?: string, endDatetime?: string, timeZoneID?: string, options?: AxiosRequestConfig) {
        return ReservationApiFp(this.configuration).v1ReservationMeetingroomReservationsRoomIDGet(roomID, pageNum, pageSize, startDatetime, endDatetime, timeZoneID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 회의실 상세 예약에 필요한  리소스 데이터를 반환합니다.
     * @param {number} roomID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationApi
     */
    public v1ReservationMeetingroomResourceRoomIDGet(roomID: number, options?: AxiosRequestConfig) {
        return ReservationApiFp(this.configuration).v1ReservationMeetingroomResourceRoomIDGet(roomID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 회의실 예약 타임페이블 렌더링 시 필요한 리소스 목록을 반환합니다.
     * @param {MeetingRoomResourceRequest} [meetingRoomResourceRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationApi
     */
    public v1ReservationMeetingroomResourcesPost(meetingRoomResourceRequest?: MeetingRoomResourceRequest, options?: AxiosRequestConfig) {
        return ReservationApiFp(this.configuration).v1ReservationMeetingroomResourcesPost(meetingRoomResourceRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
