import { StaticApi } from 'mik-common/src/api-autogen/portal/api'
import {
  StaticPageModel,
  StaticPageModelApiResult,
  StaticPageMenuTypes
} from 'mik-common/src/api-autogen/portal/models'
import { UserAuthorityConstant } from 'mik-common/src/api-autogen/portal/models'
import { AxiosResponse } from 'axios'

export interface IStatic {
  staticPage: StaticPageModel
  authLevel: UserAuthorityConstant
}

export const useStaticStore = defineStore('static', {
  state: (): IStatic => {
    return {
      staticPage: {} as StaticPageModel,
      authLevel: UserAuthorityConstant.USER_AUTH_NONE
    }
  },
  getters: {
    isRead(state): boolean {
      return state.authLevel >= UserAuthorityConstant.USER_AUTH_READONLY
    },
    menuType(state): StaticPageMenuTypes {
      if (state.staticPage.menuTypes) {
        return state.staticPage.menuTypes
      } else {
        return StaticPageMenuTypes.NONE
      }
    },
    siteStaticName(state): string {
      if (state.staticPage.siteStaticName) {
        return state.staticPage.siteStaticName
      } else {
        return ''
      }
    },
    sectionStaticName(state): string {
      if (state.staticPage.sectionStaticName) {
        return state.staticPage.sectionStaticName
      } else {
        return ''
      }
    }
  },
  actions: {
    async getStaticPageInfo(siteStaticName: string, sectionStaticName: string) {
      const api = new StaticApi()
      const response: AxiosResponse<StaticPageModelApiResult> =
        await api.v1StaticViewContentSiteStaticNameSectionStaticNameGet(siteStaticName, sectionStaticName)

      if (response.data.success) {
        Object.assign(this.staticPage, response.data.data)
        this.authLevel = response.data.userAuthority ?? UserAuthorityConstant.USER_AUTH_NONE
      } else {
        Object.assign(this.staticPage, {})
        this.authLevel = UserAuthorityConstant.USER_AUTH_NONE
      }
    }
  }
})
