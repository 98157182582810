import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'
import { UserAuthorityConstant } from 'mik-common/src/api-autogen/companyrule'
import { useConfigStore } from 'mik-companyrule/src/store/modules/useConfigStore'

const guards = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
  useRouterMerge: boolean
) => {
  //작성, 수정 페이지일 경우
  const configStore = useConfigStore()
  //작성 페이지 권한 확인
  if (to.name == 'Edit') {
    //EditType별 권한 확인이 달라서 Router에 명시가 안된 EditType일 경우 Error 페이지 이동
    if (to.params.editType != 'NEW' && to.params.editType != 'MODIFY' && to.params.editType != 'ADD') {
      next({
        path: '/Error/403'
      })
    }
    //작성은 관리자만 가능하며, 수정/새 버전 작성 권한은 관리자와 담당자만 권한 확인은 Edit 페이지에서 Check
    if (to.params.editType == 'NEW') {
      if (configStore.authorityLevel < UserAuthorityConstant.USER_AUTH_MANAGER) {
        next({
          path: '/Error/401'
        })
      }
    }
  } else if (!useRouterMerge) {
    if (to.name) {
      next()
    } else {
      next(false)
    }
  }
}

export default guards
