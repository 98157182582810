import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { Quasar, Notify, Dialog, Cookies } from 'quasar'
import quasarLang from 'quasar/lang/ko-KR'
import { VueClipboard } from '@soerenmartius/vue3-clipboard'

import router from './router'
import { ApiResult } from 'mik-common/src/api-autogen/portal'
import { useAxiosMiddlewareWithErrorCallback } from 'mik-common/src/common/auth'
import { useToast } from 'mik-common/src/common/toast'

import i18n from 'mik-common/src/lang/lang'

/** Gnb import */
import mikGnb, { installOptions } from '@gnb/modules/menus/main'

import App from './pages/Index.vue'

// Import Quasar css
import 'mik-common/src/assets/styles/index.sass'
import { PortalGlobalSubscriptionHandler } from 'mik-common/src/utils/portal-global-subscription-handler'

const app = createApp(App)

app.use(VueClipboard)

/**add Pinia */
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
app.use(pinia)

// @ts-ignore
app.use(Quasar, {
  plugins: {
    Notify,
    Dialog,
    Cookies
  }, // import Quasar plugins and add here
  lang: quasarLang
})

app.use(i18n)
app.use(router)

installOptions.router = router
installOptions.i18n = i18n
installOptions.bucketId = import.meta.env.V_MENU_PUBLISH_BUCKETID

/** Gnb 추가 */
app.use(mikGnb, installOptions)

// API 호출은 성공했지만, 서버에서 실패 flag를 전송하였을 경우
// 401일 경우 로그인 페이지로 이동
useAxiosMiddlewareWithErrorCallback((error: ApiResult) => {
  if (error.errorId) {
    switch (error.errorId) {
      case '400':
      case '401':
      case '403':
      case '404':
      case '500':
        router.replace({
          path: `/Error/${error.errorId}`,
          query: router.currentRoute.value.query
        })
        break
      default:
        if (error.message) {
          useToast().error(error.message)
        } else {
          router.replace({
            path: `/Error/${error.errorId}`,
            query: router.currentRoute.value.query
          })
        }
        break
    }
  } else if (error.message) {
    useToast().error(error.message)
  }
})

app.mount('#app')
if ('Y' == import.meta.env.V_GLOBAL_SUBSCRIPTION_USE_YN) {
  setTimeout(() => {
    PortalGlobalSubscriptionHandler.subscribe()
  }, 5000)
}
