import { createRouter, createWebHistory, RouteRecordRaw, NavigationFailureType, isNavigationFailure } from 'vue-router'
import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'

import BoardRouter from 'mik-board/src/router/routers'
import FrontRouter from 'mik-front/src/router/routers'
import CompanyRuleRouter from 'mik-companyrule/src/router/routers'
import ConsentformRouter from 'mik-consentform/src/router/routers'
import NotificationRouter from 'mik-notification/src/router/routers'
import CommunityRouter from 'mik-community/src/router/routers'
import SurveyRouter from 'mik-survey/src/router/routers'
import SubportalRouter from 'mik-subportal/src/router/routers'
import WorkPlannerRouter from 'mik-workplanner/src/router/routers'
import ReservationRouter from 'mik-reservation/src/router/routers'

import BoardGuard from 'mik-board/src/router/guard'
import CompanyRuleGuard from 'mik-companyrule/src/router/guard'
import ConsentformGuard from 'mik-consentform/src/router/guard'
import CommunityGuard from 'mik-community/src/router/guard'
import ReservationGuard from 'mik-reservation/src/router/guard'
import WorkplannerGuard from 'mik-workplanner/src/router/guard'

import { useModeStore } from 'mik-common/src/store/mode'
import { loadLanguageForModule } from 'mik-common/src/common/lang'
import { useUserInfoStore } from '@gnb/store/modules/user-info/user-info'

import { withModules } from 'mik-common/src/common/portal-module'

const portalBasePath = import.meta.env.V_PORTAL_PATH.toLowerCase()
const frontBasePath = import.meta.env.V_FRONT_PATH.toLowerCase()
const companyruleBasePath = import.meta.env.V_COMPANYRULE_PATH.toLowerCase()
const consentformBasePath = import.meta.env.V_CONSENTFORM_PATH.toLowerCase()
const notificationBasePath = import.meta.env.V_NOTIFICATIONCENTER_PATH.toLowerCase()
const communityBasePath = import.meta.env.V_COMMUNITY_PATH.toLowerCase()
const surveyBasePath = import.meta.env.V_SURVEY_PATH.toLowerCase()
const subportalBasePath = import.meta.env.V_SUBPORTAL_PATH.toLowerCase()
const workplannerBasePath = import.meta.env.V_WORKPLANNER_PATH.toLowerCase()
const reservationBasePath = import.meta.env.V_RESERVATION_PATH.toLowerCase()

/// 포함 될 모듈
withModules[portalBasePath] = {
  basePath: portalBasePath,
  langPath: '/board',
  loadedLanguage: false,
  routers: BoardRouter
}
withModules[frontBasePath] = {
  basePath: frontBasePath,
  langPath: '/front',
  loadedLanguage: false,
  routers: FrontRouter
}
withModules[companyruleBasePath] = {
  basePath: companyruleBasePath,
  langPath: '/companyrule',
  loadedLanguage: false,
  routers: CompanyRuleRouter
}
withModules[consentformBasePath] = {
  basePath: consentformBasePath,
  langPath: '/consentform',
  loadedLanguage: false,
  routers: ConsentformRouter
}
withModules[notificationBasePath] = {
  basePath: notificationBasePath,
  langPath: '/notification',
  loadedLanguage: false,
  routers: ConsentformRouter
}
withModules[communityBasePath] = {
  basePath: communityBasePath,
  langPath: '/community',
  loadedLanguage: false,
  routers: CommunityRouter
}
withModules[surveyBasePath] = {
  basePath: surveyBasePath,
  langPath: '/survey',
  loadedLanguage: false,
  routers: SurveyRouter
}
withModules[subportalBasePath] = {
  basePath: subportalBasePath,
  langPath: '/subportal',
  loadedLanguage: false,
  routers: SubportalRouter
}
withModules[workplannerBasePath] = {
  basePath: workplannerBasePath,
  langPath: '/workplanner',
  loadedLanguage: false,
  routers: WorkPlannerRouter
}
withModules[reservationBasePath] = {
  basePath: reservationBasePath,
  langPath: '/reservation',
  loadedLanguage: false,
  routers: ReservationRouter
}

const routers: RouteRecordRaw[] = []

// 메인화면
if (withModules[frontBasePath]) {
  FrontRouter.forEach((item) => {
    item.path = `${frontBasePath}${item.path}`
  })

  routers.push({
    path: frontBasePath,
    children: FrontRouter,
    meta: {
      baseUrl: frontBasePath,
      useLocalMenu: false,
      bodyClass: 'main'
    }
  })
}

// 표준 게시판
if (withModules[portalBasePath]) {
  BoardRouter.forEach((item) => {
    item.path = `${portalBasePath}${item.path}`
  })

  routers.push({
    path: portalBasePath,
    children: BoardRouter,
    meta: {
      baseUrl: portalBasePath,
      useLocalMenu: true,
      bodyClass: 'board'
    }
  })
}

// 사규
if (withModules[companyruleBasePath]) {
  CompanyRuleRouter.forEach((item) => {
    item.path = `${companyruleBasePath}${item.path}`
  })

  routers.push({
    path: companyruleBasePath,
    children: CompanyRuleRouter,
    meta: {
      baseUrl: companyruleBasePath,
      useLocalMenu: false,
      bodyClass: 'companyrule'
    }
  })
}

// 동의서
if (withModules[consentformBasePath]) {
  ConsentformRouter.forEach((item) => {
    item.path = `${consentformBasePath}${item.path}`
  })

  routers.push({
    path: consentformBasePath,
    children: ConsentformRouter,
    meta: {
      baseUrl: consentformBasePath,
      useLocalMenu: true,
      bodyClass: 'consentform'
    }
  })
}

// 알림센터
if (withModules[notificationBasePath]) {
  NotificationRouter.forEach((item) => {
    item.path = `${notificationBasePath}${item.path}`
  })

  routers.push({
    path: notificationBasePath,
    children: NotificationRouter,
    meta: {
      baseUrl: notificationBasePath,
      useLocalMenu: true,
      bodyClass: 'notification'
    }
  })
}

// 커뮤니티
if (withModules[communityBasePath]) {
  CommunityRouter.forEach((item) => {
    item.path = `${communityBasePath}${item.path}`
  })

  routers.push({
    path: communityBasePath,
    children: CommunityRouter,
    meta: {
      baseUrl: communityBasePath,
      useLocalMenu: true,
      bodyClass: 'community'
    }
  })
}

// 설문
if (withModules[surveyBasePath]) {
  SurveyRouter.forEach((item) => {
    item.path = `${surveyBasePath}${item.path}`
    if (item.children) {
      item.children.forEach((childItem) => {
        childItem.path = `${surveyBasePath}${childItem.path}`
      })
    }
  })

  routers.push({
    path: surveyBasePath,
    children: SurveyRouter,
    meta: {
      baseUrl: surveyBasePath,
      useLocalMenu: true,
      bodyClass: 'survey'
    }
  })
}

// 서브포탈
if (withModules[subportalBasePath]) {
  SubportalRouter.forEach((item) => {
    item.path = `${subportalBasePath}${item.path}`
    if (item.children) {
      item.children.forEach((childItem) => {
        childItem.path = `${subportalBasePath}${childItem.path}`
      })
    }
  })

  routers.push({
    path: subportalBasePath,
    children: SubportalRouter,
    meta: {
      baseUrl: subportalBasePath,
      useLocalMenu: true,
      bodyClass: 'subportal'
    }
  })
}

// 근태관리
if (withModules[workplannerBasePath]) {
  WorkPlannerRouter.forEach((item) => {
    item.path = `${workplannerBasePath}${item.path}`
  })

  routers.push({
    path: workplannerBasePath,
    children: WorkPlannerRouter,
    meta: {
      baseUrl: workplannerBasePath,
      useLocalMenu: true,
      bodyClass: 'workplanner'
    }
  })
}

// 자원예약
if (withModules[reservationBasePath]) {
  ReservationRouter.forEach((item) => {
    item.path = `${reservationBasePath}${item.path}`
  })

  routers.push({
    path: reservationBasePath,
    children: ReservationRouter,
    meta: {
      baseUrl: reservationBasePath,
      useLocalMenu: true,
      bodyClass: 'reservation'
    }
  })
}

const router = createRouter({
  history: createWebHistory(),
  routes: [
    ...routers,
    {
      path: '',
      redirect: { name: 'Main' }
    },
    {
      name: 'Error',
      path: '/Error/:code',
      component: () => import('mik-common/src/components/error/Index.vue')
    }
  ]
})

router.beforeResolve(async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  await useUserInfoStore().userInfoInit()

  // 다국어 로드
  await loadLanguageForModule(to)

  switch (to.meta.baseUrl) {
    // 커뮤니티
    case communityBasePath:
      await CommunityGuard(to, from, next)
      break
    case portalBasePath:
      // 표준 게시판
      await BoardGuard(to, from, next, true)
      break
    case companyruleBasePath:
      // 사규
      CompanyRuleGuard(to, from, next, true)
      break
    case consentformBasePath:
      // 동의서
      await ConsentformGuard(to, from, next, true)
      break
    case reservationBasePath:
      // 자원예약
      ReservationGuard(to, from, next, true)
      break
    case workplannerBasePath:
      // 근태관리
      WorkplannerGuard(to, from, next, true)
      break
  }

  // LNG, GNB, 팝업 사용여부 판단
  const store = useModeStore()
  store.setMode(to, from)

  if (to.name) {
    // Body에 모듈별 class를 붙인다.
    // Postcss와 연관있는 코드
    if (from.meta.bodyClass) document.body.classList.remove(`${from.meta.bodyClass}`)
    document.body.classList.add(`${to.meta.bodyClass}`)

    next()
  } else {
    // router 실패로 이동하는 로직
    next(false)
  }
})

// router 실패 시 404페이지 이동
router.afterEach((to, from, failure) => {
  if (isNavigationFailure(failure, NavigationFailureType.aborted)) {
    setTimeout(() => {
      router.replace({ path: '/Error/404', force: true })
    }, 500)
  }
})

export default router
