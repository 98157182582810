const routers = [
  {
    name: 'Main',
    path: '/',
    component: () => import('mik-front/src/pages/user/Main.vue')
  },
  {
    name: 'Main.Sub',
    path: '/Main',
    component: () => import('mik-front/src/pages/user/Main.vue')
  },
  {
    name: 'Main.Preview',
    path: '/Main/Preview',
    component: () => import('mik-front/src/pages/user/MainPreview.vue')
  }
]

export default routers
