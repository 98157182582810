<template>
  <q-avatar size="40px">
    <q-img no-native-menu :src="profileImageUrl" style="width: 40px; height: 40px">
      <template #error>
        <div class="absolute-full flex flex-center bg-negative text-white">{{ props.userId }}</div>
      </template>
    </q-img>
    <!-- <UserProfileCard :model-value="isHO" :user-id="props.userId"> </UserProfileCard> -->
  </q-avatar>
</template>

<script setup lang="ts">
import { useCommunityStore } from 'mik-community/src/store/community'
import { MemberApi, MemberModel } from 'mik-common/src/api-autogen/community'

const api = new MemberApi()
const store = useCommunityStore()
interface Props {
  userId: string
}

const props = withDefaults(defineProps<Props>(), {
  userId: ''
})

const profileImageUrl = computed(() => {
  return `${import.meta.env.V_API_GLOBALMENU_PATH}/v1/resource/image/profile/${props.userId}`
})
</script>

<style scoped>
.user-profile-card {
  width: 100%;
  max-width: 250px;
}
</style>
