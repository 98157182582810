const routers = [
  {
    path: '/',
    redirect: { name: 'ItemList' }
  },
  {
    path: '/Message/Items',
    name: 'ItemList',
    component: () => import('mik-notification/src/pages/messages/ItemList.vue')
  },
  {
    path: '/Configuration/Personal',
    name: 'ConfigurePersonalSettings',
    component: () => import('mik-notification/src/pages/configurations/ConfigurePersonalSettings.vue')
  },
  {
    path: '/ManualGuideline',
    name: 'ManualGuideline',
    component: () => import('mik-notification/src/pages/manual/GuideLine.vue')
  },
  {
    path: '/SystemConsole',
    name: 'SystemConsole',
    component: () => import('mik-notification/src/pages/configurations/SystemConsole.vue')
  }
]

export default routers
