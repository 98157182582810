/* tslint:disable */
/* eslint-disable */
/**
 * Mik.SmartPortal.WorkPlanner.App
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import type { RequestArgs } from '../base';
// @ts-ignore
import type { ApvStatusHistoryEntityApiResult } from '../models';
// @ts-ignore
import type { BooleanApiResult } from '../models';
// @ts-ignore
import type { MonthlyClosingResultApiResult } from '../models';
// @ts-ignore
import type { ObjectApiResult } from '../models';
// @ts-ignore
import type { StringApiResult } from '../models';
// @ts-ignore
import type { UserProfileCardResultApiResult } from '../models';
// @ts-ignore
import type { WorkTypeEntityListApiResult } from '../models';
/**
 * CommonApi - axios parameter creator
 * @export
 */
export const CommonApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {string} [userId] 
         * @param {number} [year] 
         * @param {number} [month] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CommonApvStatusHistorySelectGet: async (userId?: string, year?: number, month?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Common/ApvStatusHistorySelect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 선택한날짜가 일정수정가능한 날짜인지 판단하는 서비스
         * @param {string} [selectDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CommonCheckIsEditableDayPost: async (selectDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Common/CheckIsEditableDay`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (selectDate !== undefined) {
                localVarQueryParameter['selectDate'] = (selectDate as any instanceof Date) ?
                    (selectDate as any).toISOString() :
                    selectDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 선택한 날짜가 수정가능한 계획인지 확인
         * @param {string} [selectDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CommonCheckIsEditablePlanPost: async (selectDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Common/CheckIsEditablePlan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (selectDate !== undefined) {
                localVarQueryParameter['selectDate'] = (selectDate as any instanceof Date) ?
                    (selectDate as any).toISOString() :
                    selectDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 해당 유저의 전자결재 상태를 조회합니다.
         * @param {string} [userID] 
         * @param {number} [year] 
         * @param {number} [month] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CommonGetApvStatusByUserGet: async (userID?: string, year?: number, month?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Common/GetApvStatusByUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userID !== undefined) {
                localVarQueryParameter['userID'] = userID;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary config 테이블에 등록 된 데이터를 조회합니다.
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CommonGetConfigGet: async (key?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Common/GetConfig`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 월마감 계산 처리 목록을 조회합니다.
         * @param {number} [year] 
         * @param {number} [month] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CommonMonthlyClosingVersionListGet: async (year?: number, month?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Common/MonthlyClosingVersionList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 사용자 프로필 카드에서 사용할 데이터 조회합니다.
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CommonUserProfileUserIdGet: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('v1CommonUserProfileUserIdGet', 'userId', userId)
            const localVarPath = `/v1/Common/UserProfile/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary WorkType 전체를 조회합니다.
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CommonWorkTypeSelectAllGet: async (langCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Common/WorkTypeSelectAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (langCode !== undefined) {
                localVarQueryParameter['langCode'] = langCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommonApi - functional programming interface
 * @export
 */
export const CommonApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommonApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} [userId] 
         * @param {number} [year] 
         * @param {number} [month] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1CommonApvStatusHistorySelectGet(userId?: string, year?: number, month?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1CommonApvStatusHistorySelectGet(userId, year, month, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 선택한날짜가 일정수정가능한 날짜인지 판단하는 서비스
         * @param {string} [selectDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1CommonCheckIsEditableDayPost(selectDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1CommonCheckIsEditableDayPost(selectDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 선택한 날짜가 수정가능한 계획인지 확인
         * @param {string} [selectDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1CommonCheckIsEditablePlanPost(selectDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1CommonCheckIsEditablePlanPost(selectDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 해당 유저의 전자결재 상태를 조회합니다.
         * @param {string} [userID] 
         * @param {number} [year] 
         * @param {number} [month] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1CommonGetApvStatusByUserGet(userID?: string, year?: number, month?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApvStatusHistoryEntityApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1CommonGetApvStatusByUserGet(userID, year, month, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary config 테이블에 등록 된 데이터를 조회합니다.
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1CommonGetConfigGet(key?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1CommonGetConfigGet(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 월마감 계산 처리 목록을 조회합니다.
         * @param {number} [year] 
         * @param {number} [month] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1CommonMonthlyClosingVersionListGet(year?: number, month?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MonthlyClosingResultApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1CommonMonthlyClosingVersionListGet(year, month, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 사용자 프로필 카드에서 사용할 데이터 조회합니다.
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1CommonUserProfileUserIdGet(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfileCardResultApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1CommonUserProfileUserIdGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary WorkType 전체를 조회합니다.
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1CommonWorkTypeSelectAllGet(langCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkTypeEntityListApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1CommonWorkTypeSelectAllGet(langCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommonApi - factory interface
 * @export
 */
export const CommonApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommonApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} [userId] 
         * @param {number} [year] 
         * @param {number} [month] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CommonApvStatusHistorySelectGet(userId?: string, year?: number, month?: number, options?: any): AxiosPromise<ObjectApiResult> {
            return localVarFp.v1CommonApvStatusHistorySelectGet(userId, year, month, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 선택한날짜가 일정수정가능한 날짜인지 판단하는 서비스
         * @param {string} [selectDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CommonCheckIsEditableDayPost(selectDate?: string, options?: any): AxiosPromise<BooleanApiResult> {
            return localVarFp.v1CommonCheckIsEditableDayPost(selectDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 선택한 날짜가 수정가능한 계획인지 확인
         * @param {string} [selectDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CommonCheckIsEditablePlanPost(selectDate?: string, options?: any): AxiosPromise<BooleanApiResult> {
            return localVarFp.v1CommonCheckIsEditablePlanPost(selectDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 해당 유저의 전자결재 상태를 조회합니다.
         * @param {string} [userID] 
         * @param {number} [year] 
         * @param {number} [month] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CommonGetApvStatusByUserGet(userID?: string, year?: number, month?: number, options?: any): AxiosPromise<ApvStatusHistoryEntityApiResult> {
            return localVarFp.v1CommonGetApvStatusByUserGet(userID, year, month, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary config 테이블에 등록 된 데이터를 조회합니다.
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CommonGetConfigGet(key?: string, options?: any): AxiosPromise<StringApiResult> {
            return localVarFp.v1CommonGetConfigGet(key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 월마감 계산 처리 목록을 조회합니다.
         * @param {number} [year] 
         * @param {number} [month] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CommonMonthlyClosingVersionListGet(year?: number, month?: number, options?: any): AxiosPromise<MonthlyClosingResultApiResult> {
            return localVarFp.v1CommonMonthlyClosingVersionListGet(year, month, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 사용자 프로필 카드에서 사용할 데이터 조회합니다.
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CommonUserProfileUserIdGet(userId: string, options?: any): AxiosPromise<UserProfileCardResultApiResult> {
            return localVarFp.v1CommonUserProfileUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary WorkType 전체를 조회합니다.
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CommonWorkTypeSelectAllGet(langCode?: string, options?: any): AxiosPromise<WorkTypeEntityListApiResult> {
            return localVarFp.v1CommonWorkTypeSelectAllGet(langCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommonApi - object-oriented interface
 * @export
 * @class CommonApi
 * @extends {BaseAPI}
 */
export class CommonApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {string} [userId] 
     * @param {number} [year] 
     * @param {number} [month] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public v1CommonApvStatusHistorySelectGet(userId?: string, year?: number, month?: number, options?: AxiosRequestConfig) {
        return CommonApiFp(this.configuration).v1CommonApvStatusHistorySelectGet(userId, year, month, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 선택한날짜가 일정수정가능한 날짜인지 판단하는 서비스
     * @param {string} [selectDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public v1CommonCheckIsEditableDayPost(selectDate?: string, options?: AxiosRequestConfig) {
        return CommonApiFp(this.configuration).v1CommonCheckIsEditableDayPost(selectDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 선택한 날짜가 수정가능한 계획인지 확인
     * @param {string} [selectDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public v1CommonCheckIsEditablePlanPost(selectDate?: string, options?: AxiosRequestConfig) {
        return CommonApiFp(this.configuration).v1CommonCheckIsEditablePlanPost(selectDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 해당 유저의 전자결재 상태를 조회합니다.
     * @param {string} [userID] 
     * @param {number} [year] 
     * @param {number} [month] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public v1CommonGetApvStatusByUserGet(userID?: string, year?: number, month?: number, options?: AxiosRequestConfig) {
        return CommonApiFp(this.configuration).v1CommonGetApvStatusByUserGet(userID, year, month, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary config 테이블에 등록 된 데이터를 조회합니다.
     * @param {string} [key] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public v1CommonGetConfigGet(key?: string, options?: AxiosRequestConfig) {
        return CommonApiFp(this.configuration).v1CommonGetConfigGet(key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 월마감 계산 처리 목록을 조회합니다.
     * @param {number} [year] 
     * @param {number} [month] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public v1CommonMonthlyClosingVersionListGet(year?: number, month?: number, options?: AxiosRequestConfig) {
        return CommonApiFp(this.configuration).v1CommonMonthlyClosingVersionListGet(year, month, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 사용자 프로필 카드에서 사용할 데이터 조회합니다.
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public v1CommonUserProfileUserIdGet(userId: string, options?: AxiosRequestConfig) {
        return CommonApiFp(this.configuration).v1CommonUserProfileUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary WorkType 전체를 조회합니다.
     * @param {string} [langCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public v1CommonWorkTypeSelectAllGet(langCode?: string, options?: AxiosRequestConfig) {
        return CommonApiFp(this.configuration).v1CommonWorkTypeSelectAllGet(langCode, options).then((request) => request(this.axios, this.basePath));
    }
}
