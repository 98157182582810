<template>
  <q-card class="q-py-md" flat>
    <q-item>
      <q-item-section avatar>
        <q-avatar size="60px" class="cursor-pointer" @click="handleTitleClick">
          <q-img
            no-native-menu
            style="width: 120px; height: 120px"
            :src="store.communityInfo.bannerImageUrl ?? ''"
            :title="store.communityInfo.title ?? ''">
            <div class="absolute-bottom text-center cursor-pointer">
              <i class="material-icons" style="font-size: 20px">camera_alt</i>
            </div>
            <template #error>
              <q-img
                :title="store.communityInfo.title ?? ''"
                no-native-menu
                style="width: 120px; height: 120px"
                :src="defaultImg">
                <div class="absolute-bottom text-center cursor-pointer">
                  <i class="material-icons" style="font-size: 20px">camera_alt</i>
                </div>
              </q-img>
            </template>
          </q-img>
        </q-avatar>
      </q-item-section>

      <q-item-section>
        <q-item-label class="text-h6 cursor-pointer" @click="handleTitleClick">{{
          store.communityInfo.title
        }}</q-item-label>
        <q-item-label class="text-subtitle2">
          {{ store.communityInfo.sysopDisplayname }}
          <q-icon name="school" :color="getThemeClass('icon')" />
        </q-item-label>
        <!-- Only for Admin -->
        <q-item-label v-if="store.isAdmin" class="text-body2">
          <div class="cursor-pointer">
            <span @click="handleManagementClick">
              <q-icon class="q-mr-xs" name="settings" :color="getThemeClass('icon')"></q-icon>
              <span>{{ $t('Management') }}</span>
            </span>
            <span @click="settingCoverImage">
              <q-icon class="q-mr-xs q-ml-sm" name="image_search" :color="getThemeClass('icon')"></q-icon>
              <span>{{ $t('Management_Cover_Image') }}</span>
            </span>
          </div>
        </q-item-label>
        <q-item-label caption>
          {{ formatDate(store.communityInfo.created) }}
          <span class="cursor-pointer">
            <q-icon name="group" class="q-ml-xs"></q-icon>
            {{ store.communityInfo.memberCount }}
            <q-tooltip transition-show="flip-right" transition-hide="flip-left">{{
              $t('MemberList', '회원 목록')
            }}</q-tooltip>
            <member-list :model-value="memberListOpen" />
          </span>
        </q-item-label>
      </q-item-section>
    </q-item>
  </q-card>
</template>

<script setup lang="ts">
import MemberList from 'mik-community/src/components/member/MemberList.vue'
import { formatDate } from 'mik-common/src/common/common'
import { useCommunityStore } from 'mik-community/src/store/community'
import { getThemeClass } from 'mik-community/src/utils/util'
import defaultImg from 'mik-community/src/assets/images/community_default.png'

const props = defineProps<{
  isAdmin?: boolean
}>()

const router = useRouter()
const $emit = defineEmits(['go-management', 'set-cover'])
const store = useCommunityStore()

const handleManagementClick = () => {
  $emit('go-management')
}
const settingCoverImage = () => {
  $emit('set-cover')
}

const handleTitleClick = () => {
  router.push({ name: 'Community.Home' })
}

const memberListOpen = ref<boolean>()
</script>

<style scoped></style>
