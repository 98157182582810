/* tslint:disable */
/* eslint-disable */
/**
 * Mik.SmartPortal.Portal.App
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import type { RequestArgs } from '../base';
// @ts-ignore
import type { ApiResult } from '../models';
// @ts-ignore
import type { BoardInfoModelApiResult } from '../models';
// @ts-ignore
import type { BooleanApiResult } from '../models';
// @ts-ignore
import type { ContentsClientDataModel } from '../models';
// @ts-ignore
import type { GuidApiResult } from '../models';
// @ts-ignore
import type { MigrateBoardItemModel } from '../models';
// @ts-ignore
import type { PostInfoIntegratedModelApiResult } from '../models';
// @ts-ignore
import type { PostInfoManagedModel } from '../models';
// @ts-ignore
import type { PostInfoManagedModelApiResult } from '../models';
// @ts-ignore
import type { PostInfoModelApiResult } from '../models';
// @ts-ignore
import type { PostInfoModelItemListInfoModelApiResult } from '../models';
// @ts-ignore
import type { PostInfoModelListApiResult } from '../models';
// @ts-ignore
import type { RSAKeyTextInfoModelApiResult } from '../models';
// @ts-ignore
import type { RequestSearchModel } from '../models';
// @ts-ignore
import type { ResponseReadingHistoryModelApiResult } from '../models';
// @ts-ignore
import type { SettingPasswordModel } from '../models';
// @ts-ignore
import type { StringApiResult } from '../models';
/**
 * BoardPostApi - axios parameter creator
 * @export
 */
export const BoardPostApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary staticName으로 작성화면의 설정부분을 조회합니다.
         * @param {string} boardStaticName 
         * @param {string} [postId] 
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardPostBoardStaticNameEditinfoGet: async (boardStaticName: string, postId?: string, langCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1BoardPostBoardStaticNameEditinfoGet', 'boardStaticName', boardStaticName)
            const localVarPath = `/v1/board/post/{boardStaticName}/Editinfo`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (postId !== undefined) {
                localVarQueryParameter['postId'] = postId;
            }

            if (langCode !== undefined) {
                localVarQueryParameter['langCode'] = langCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Password 관련 암호화 키 생성
         * @param {string} boardStaticName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardPostBoardStaticNameEncryptionGet: async (boardStaticName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1BoardPostBoardStaticNameEncryptionGet', 'boardStaticName', boardStaticName)
            const localVarPath = `/v1/board/post/{boardStaticName}/encryption`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 게시글 목록을 조회합니다.
         * @param {string} boardStaticName 
         * @param {string} [databaseId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {number} [pageNumCount] 
         * @param {string} [searchType] 
         * @param {string} [searchKeyword] 
         * @param {string} [folderId] 
         * @param {string} [langCode] 
         * @param {string} [searchPeriod] 
         * @param {string} [datePeriod] 
         * @param {string} [fieldInfo] 
         * @param {string} [itemFilter] 
         * @param {string} [rootParentID] 
         * @param {string} [fieldDatetimeInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardPostBoardStaticNameGet: async (boardStaticName: string, databaseId?: string, page?: number, size?: number, pageNumCount?: number, searchType?: string, searchKeyword?: string, folderId?: string, langCode?: string, searchPeriod?: string, datePeriod?: string, fieldInfo?: string, itemFilter?: string, rootParentID?: string, fieldDatetimeInfo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1BoardPostBoardStaticNameGet', 'boardStaticName', boardStaticName)
            const localVarPath = `/v1/board/post/{boardStaticName}`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (databaseId !== undefined) {
                localVarQueryParameter['databaseId'] = databaseId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (pageNumCount !== undefined) {
                localVarQueryParameter['pageNumCount'] = pageNumCount;
            }

            if (searchType !== undefined) {
                localVarQueryParameter['searchType'] = searchType;
            }

            if (searchKeyword !== undefined) {
                localVarQueryParameter['searchKeyword'] = searchKeyword;
            }

            if (folderId !== undefined) {
                localVarQueryParameter['folderId'] = folderId;
            }

            if (langCode !== undefined) {
                localVarQueryParameter['langCode'] = langCode;
            }

            if (searchPeriod !== undefined) {
                localVarQueryParameter['searchPeriod'] = searchPeriod;
            }

            if (datePeriod !== undefined) {
                localVarQueryParameter['datePeriod'] = datePeriod;
            }

            if (fieldInfo !== undefined) {
                localVarQueryParameter['fieldInfo'] = fieldInfo;
            }

            if (itemFilter !== undefined) {
                localVarQueryParameter['itemFilter'] = itemFilter;
            }

            if (rootParentID !== undefined) {
                localVarQueryParameter['rootParentID'] = rootParentID;
            }

            if (fieldDatetimeInfo !== undefined) {
                localVarQueryParameter['fieldDatetimeInfo'] = fieldDatetimeInfo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 게시글 링크 목록을 조회합니다.
         * @param {string} boardStaticName 
         * @param {string} [databaseId] 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardPostBoardStaticNameLinkListPost: async (boardStaticName: string, databaseId?: string, requestBody?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1BoardPostBoardStaticNameLinkListPost', 'boardStaticName', boardStaticName)
            const localVarPath = `/v1/board/post/{boardStaticName}/LinkList`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (databaseId !== undefined) {
                localVarQueryParameter['databaseId'] = databaseId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 게시글을 등록합니다.
         * @param {string} boardStaticName 
         * @param {string} [databaseId] 
         * @param {ContentsClientDataModel} [contentsClientDataModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardPostBoardStaticNamePost: async (boardStaticName: string, databaseId?: string, contentsClientDataModel?: ContentsClientDataModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1BoardPostBoardStaticNamePost', 'boardStaticName', boardStaticName)
            const localVarPath = `/v1/board/post/{boardStaticName}`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (databaseId !== undefined) {
                localVarQueryParameter['databaseId'] = databaseId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contentsClientDataModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 게시글 승인 - 승인게시판
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardPostBoardStaticNamePostIdApprovePost: async (boardStaticName: string, postId: string, langCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1BoardPostBoardStaticNamePostIdApprovePost', 'boardStaticName', boardStaticName)
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('v1BoardPostBoardStaticNamePostIdApprovePost', 'postId', postId)
            const localVarPath = `/v1/board/post/{boardStaticName}/{postId}/approve`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)))
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (langCode !== undefined) {
                localVarQueryParameter['langCode'] = langCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 패스워드 변경
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {SettingPasswordModel} [settingPasswordModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardPostBoardStaticNamePostIdChangePasswordPost: async (boardStaticName: string, postId: string, settingPasswordModel?: SettingPasswordModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1BoardPostBoardStaticNamePostIdChangePasswordPost', 'boardStaticName', boardStaticName)
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('v1BoardPostBoardStaticNamePostIdChangePasswordPost', 'postId', postId)
            const localVarPath = `/v1/board/post/{boardStaticName}/{postId}/changePassword`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)))
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(settingPasswordModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 패스워드 체크
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardPostBoardStaticNamePostIdCheckPasswordPost: async (boardStaticName: string, postId: string, body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1BoardPostBoardStaticNamePostIdCheckPasswordPost', 'boardStaticName', boardStaticName)
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('v1BoardPostBoardStaticNamePostIdCheckPasswordPost', 'postId', postId)
            const localVarPath = `/v1/board/post/{boardStaticName}/{postId}/checkPassword`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)))
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 게시글을 삭제합니다.
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {string} [databaseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardPostBoardStaticNamePostIdDelete: async (boardStaticName: string, postId: string, databaseId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1BoardPostBoardStaticNamePostIdDelete', 'boardStaticName', boardStaticName)
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('v1BoardPostBoardStaticNamePostIdDelete', 'postId', postId)
            const localVarPath = `/v1/board/post/{boardStaticName}/{postId}`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)))
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (databaseId !== undefined) {
                localVarQueryParameter['databaseId'] = databaseId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 삭제 요청
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardPostBoardStaticNamePostIdDeleteRequestPost: async (boardStaticName: string, postId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1BoardPostBoardStaticNamePostIdDeleteRequestPost', 'boardStaticName', boardStaticName)
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('v1BoardPostBoardStaticNamePostIdDeleteRequestPost', 'postId', postId)
            const localVarPath = `/v1/board/post/{boardStaticName}/{postId}/delete/request`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)))
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 게시글을 조회합니다.  조회용
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {string} [databaseId] 
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardPostBoardStaticNamePostIdGet: async (boardStaticName: string, postId: string, databaseId?: string, langCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1BoardPostBoardStaticNamePostIdGet', 'boardStaticName', boardStaticName)
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('v1BoardPostBoardStaticNamePostIdGet', 'postId', postId)
            const localVarPath = `/v1/board/post/{boardStaticName}/{postId}`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)))
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (databaseId !== undefined) {
                localVarQueryParameter['databaseId'] = databaseId;
            }

            if (langCode !== undefined) {
                localVarQueryParameter['langCode'] = langCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 게시글 잠금 정보 저장/갱신
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {string} editType 
         * @param {PostInfoManagedModel} [postInfoManagedModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardPostBoardStaticNamePostIdLockApplyEditTypePost: async (boardStaticName: string, postId: string, editType: string, postInfoManagedModel?: PostInfoManagedModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1BoardPostBoardStaticNamePostIdLockApplyEditTypePost', 'boardStaticName', boardStaticName)
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('v1BoardPostBoardStaticNamePostIdLockApplyEditTypePost', 'postId', postId)
            // verify required parameter 'editType' is not null or undefined
            assertParamExists('v1BoardPostBoardStaticNamePostIdLockApplyEditTypePost', 'editType', editType)
            const localVarPath = `/v1/board/post/{boardStaticName}/{postId}/lock/apply/{editType}`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)))
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)))
                .replace(`{${"editType"}}`, encodeURIComponent(String(editType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postInfoManagedModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 게시글 잠금 정보 조회
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardPostBoardStaticNamePostIdLockInfoGet: async (boardStaticName: string, postId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1BoardPostBoardStaticNamePostIdLockInfoGet', 'boardStaticName', boardStaticName)
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('v1BoardPostBoardStaticNamePostIdLockInfoGet', 'postId', postId)
            const localVarPath = `/v1/board/post/{boardStaticName}/{postId}/lock/info`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)))
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 게시글을 타 게시판에 이관합니다.
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {string} [databaseId] 
         * @param {MigrateBoardItemModel} [migrateBoardItemModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardPostBoardStaticNamePostIdMigratePost: async (boardStaticName: string, postId: string, databaseId?: string, migrateBoardItemModel?: MigrateBoardItemModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1BoardPostBoardStaticNamePostIdMigratePost', 'boardStaticName', boardStaticName)
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('v1BoardPostBoardStaticNamePostIdMigratePost', 'postId', postId)
            const localVarPath = `/v1/board/post/{boardStaticName}/{postId}/Migrate`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)))
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (databaseId !== undefined) {
                localVarQueryParameter['databaseId'] = databaseId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(migrateBoardItemModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 게시글에 답글을 등록합니다.
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {string} [databaseId] 
         * @param {ContentsClientDataModel} [contentsClientDataModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardPostBoardStaticNamePostIdPost: async (boardStaticName: string, postId: string, databaseId?: string, contentsClientDataModel?: ContentsClientDataModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1BoardPostBoardStaticNamePostIdPost', 'boardStaticName', boardStaticName)
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('v1BoardPostBoardStaticNamePostIdPost', 'postId', postId)
            const localVarPath = `/v1/board/post/{boardStaticName}/{postId}`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)))
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (databaseId !== undefined) {
                localVarQueryParameter['databaseId'] = databaseId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contentsClientDataModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 게시글을 수정합니다.
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {string} [databaseId] 
         * @param {ContentsClientDataModel} [contentsClientDataModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardPostBoardStaticNamePostIdPut: async (boardStaticName: string, postId: string, databaseId?: string, contentsClientDataModel?: ContentsClientDataModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1BoardPostBoardStaticNamePostIdPut', 'boardStaticName', boardStaticName)
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('v1BoardPostBoardStaticNamePostIdPut', 'postId', postId)
            const localVarPath = `/v1/board/post/{boardStaticName}/{postId}`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)))
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (databaseId !== undefined) {
                localVarQueryParameter['databaseId'] = databaseId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contentsClientDataModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 게시글의 조회이력을 조회합니다.
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {string} boardType 
         * @param {RequestSearchModel} [requestSearchModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardPostBoardStaticNamePostIdReadingHistoryPost: async (boardStaticName: string, postId: string, boardType: string, requestSearchModel?: RequestSearchModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1BoardPostBoardStaticNamePostIdReadingHistoryPost', 'boardStaticName', boardStaticName)
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('v1BoardPostBoardStaticNamePostIdReadingHistoryPost', 'postId', postId)
            // verify required parameter 'boardType' is not null or undefined
            assertParamExists('v1BoardPostBoardStaticNamePostIdReadingHistoryPost', 'boardType', boardType)
            const localVarPath = `/v1/board/post/{boardStaticName}/{postId}/ReadingHistory`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)))
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)))
                .replace(`{${"boardType"}}`, encodeURIComponent(String(boardType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestSearchModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 게시글 반려 - 승인게시판
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardPostBoardStaticNamePostIdRejectPost: async (boardStaticName: string, postId: string, langCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1BoardPostBoardStaticNamePostIdRejectPost', 'boardStaticName', boardStaticName)
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('v1BoardPostBoardStaticNamePostIdRejectPost', 'postId', postId)
            const localVarPath = `/v1/board/post/{boardStaticName}/{postId}/reject`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)))
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (langCode !== undefined) {
                localVarQueryParameter['langCode'] = langCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 게시글을 조회합니다.  게시글 조회화면 전용
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {string} [langCode] 
         * @param {boolean} [hasSearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardPostBoardStaticNamePostIdViewInfoGet: async (boardStaticName: string, postId: string, langCode?: string, hasSearchFilter?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1BoardPostBoardStaticNamePostIdViewInfoGet', 'boardStaticName', boardStaticName)
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('v1BoardPostBoardStaticNamePostIdViewInfoGet', 'postId', postId)
            const localVarPath = `/v1/board/post/{boardStaticName}/{postId}/ViewInfo`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)))
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (langCode !== undefined) {
                localVarQueryParameter['langCode'] = langCode;
            }

            if (hasSearchFilter !== undefined) {
                localVarQueryParameter['hasSearchFilter'] = hasSearchFilter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BoardPostApi - functional programming interface
 * @export
 */
export const BoardPostApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BoardPostApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary staticName으로 작성화면의 설정부분을 조회합니다.
         * @param {string} boardStaticName 
         * @param {string} [postId] 
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BoardPostBoardStaticNameEditinfoGet(boardStaticName: string, postId?: string, langCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoardInfoModelApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BoardPostBoardStaticNameEditinfoGet(boardStaticName, postId, langCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Password 관련 암호화 키 생성
         * @param {string} boardStaticName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BoardPostBoardStaticNameEncryptionGet(boardStaticName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RSAKeyTextInfoModelApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BoardPostBoardStaticNameEncryptionGet(boardStaticName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 게시글 목록을 조회합니다.
         * @param {string} boardStaticName 
         * @param {string} [databaseId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {number} [pageNumCount] 
         * @param {string} [searchType] 
         * @param {string} [searchKeyword] 
         * @param {string} [folderId] 
         * @param {string} [langCode] 
         * @param {string} [searchPeriod] 
         * @param {string} [datePeriod] 
         * @param {string} [fieldInfo] 
         * @param {string} [itemFilter] 
         * @param {string} [rootParentID] 
         * @param {string} [fieldDatetimeInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BoardPostBoardStaticNameGet(boardStaticName: string, databaseId?: string, page?: number, size?: number, pageNumCount?: number, searchType?: string, searchKeyword?: string, folderId?: string, langCode?: string, searchPeriod?: string, datePeriod?: string, fieldInfo?: string, itemFilter?: string, rootParentID?: string, fieldDatetimeInfo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostInfoModelItemListInfoModelApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BoardPostBoardStaticNameGet(boardStaticName, databaseId, page, size, pageNumCount, searchType, searchKeyword, folderId, langCode, searchPeriod, datePeriod, fieldInfo, itemFilter, rootParentID, fieldDatetimeInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 게시글 링크 목록을 조회합니다.
         * @param {string} boardStaticName 
         * @param {string} [databaseId] 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BoardPostBoardStaticNameLinkListPost(boardStaticName: string, databaseId?: string, requestBody?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostInfoModelListApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BoardPostBoardStaticNameLinkListPost(boardStaticName, databaseId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 게시글을 등록합니다.
         * @param {string} boardStaticName 
         * @param {string} [databaseId] 
         * @param {ContentsClientDataModel} [contentsClientDataModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BoardPostBoardStaticNamePost(boardStaticName: string, databaseId?: string, contentsClientDataModel?: ContentsClientDataModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BoardPostBoardStaticNamePost(boardStaticName, databaseId, contentsClientDataModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 게시글 승인 - 승인게시판
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BoardPostBoardStaticNamePostIdApprovePost(boardStaticName: string, postId: string, langCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BoardPostBoardStaticNamePostIdApprovePost(boardStaticName, postId, langCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 패스워드 변경
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {SettingPasswordModel} [settingPasswordModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BoardPostBoardStaticNamePostIdChangePasswordPost(boardStaticName: string, postId: string, settingPasswordModel?: SettingPasswordModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BoardPostBoardStaticNamePostIdChangePasswordPost(boardStaticName, postId, settingPasswordModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 패스워드 체크
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BoardPostBoardStaticNamePostIdCheckPasswordPost(boardStaticName: string, postId: string, body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BoardPostBoardStaticNamePostIdCheckPasswordPost(boardStaticName, postId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 게시글을 삭제합니다.
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {string} [databaseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BoardPostBoardStaticNamePostIdDelete(boardStaticName: string, postId: string, databaseId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BoardPostBoardStaticNamePostIdDelete(boardStaticName, postId, databaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 삭제 요청
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BoardPostBoardStaticNamePostIdDeleteRequestPost(boardStaticName: string, postId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BoardPostBoardStaticNamePostIdDeleteRequestPost(boardStaticName, postId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 게시글을 조회합니다.  조회용
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {string} [databaseId] 
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BoardPostBoardStaticNamePostIdGet(boardStaticName: string, postId: string, databaseId?: string, langCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostInfoModelApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BoardPostBoardStaticNamePostIdGet(boardStaticName, postId, databaseId, langCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 게시글 잠금 정보 저장/갱신
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {string} editType 
         * @param {PostInfoManagedModel} [postInfoManagedModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BoardPostBoardStaticNamePostIdLockApplyEditTypePost(boardStaticName: string, postId: string, editType: string, postInfoManagedModel?: PostInfoManagedModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostInfoManagedModelApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BoardPostBoardStaticNamePostIdLockApplyEditTypePost(boardStaticName, postId, editType, postInfoManagedModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 게시글 잠금 정보 조회
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BoardPostBoardStaticNamePostIdLockInfoGet(boardStaticName: string, postId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostInfoManagedModelApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BoardPostBoardStaticNamePostIdLockInfoGet(boardStaticName, postId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 게시글을 타 게시판에 이관합니다.
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {string} [databaseId] 
         * @param {MigrateBoardItemModel} [migrateBoardItemModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BoardPostBoardStaticNamePostIdMigratePost(boardStaticName: string, postId: string, databaseId?: string, migrateBoardItemModel?: MigrateBoardItemModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BoardPostBoardStaticNamePostIdMigratePost(boardStaticName, postId, databaseId, migrateBoardItemModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 게시글에 답글을 등록합니다.
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {string} [databaseId] 
         * @param {ContentsClientDataModel} [contentsClientDataModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BoardPostBoardStaticNamePostIdPost(boardStaticName: string, postId: string, databaseId?: string, contentsClientDataModel?: ContentsClientDataModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BoardPostBoardStaticNamePostIdPost(boardStaticName, postId, databaseId, contentsClientDataModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 게시글을 수정합니다.
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {string} [databaseId] 
         * @param {ContentsClientDataModel} [contentsClientDataModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BoardPostBoardStaticNamePostIdPut(boardStaticName: string, postId: string, databaseId?: string, contentsClientDataModel?: ContentsClientDataModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BoardPostBoardStaticNamePostIdPut(boardStaticName, postId, databaseId, contentsClientDataModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 게시글의 조회이력을 조회합니다.
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {string} boardType 
         * @param {RequestSearchModel} [requestSearchModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BoardPostBoardStaticNamePostIdReadingHistoryPost(boardStaticName: string, postId: string, boardType: string, requestSearchModel?: RequestSearchModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseReadingHistoryModelApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BoardPostBoardStaticNamePostIdReadingHistoryPost(boardStaticName, postId, boardType, requestSearchModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 게시글 반려 - 승인게시판
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BoardPostBoardStaticNamePostIdRejectPost(boardStaticName: string, postId: string, langCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BoardPostBoardStaticNamePostIdRejectPost(boardStaticName, postId, langCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 게시글을 조회합니다.  게시글 조회화면 전용
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {string} [langCode] 
         * @param {boolean} [hasSearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BoardPostBoardStaticNamePostIdViewInfoGet(boardStaticName: string, postId: string, langCode?: string, hasSearchFilter?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostInfoIntegratedModelApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BoardPostBoardStaticNamePostIdViewInfoGet(boardStaticName, postId, langCode, hasSearchFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BoardPostApi - factory interface
 * @export
 */
export const BoardPostApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BoardPostApiFp(configuration)
    return {
        /**
         * 
         * @summary staticName으로 작성화면의 설정부분을 조회합니다.
         * @param {string} boardStaticName 
         * @param {string} [postId] 
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardPostBoardStaticNameEditinfoGet(boardStaticName: string, postId?: string, langCode?: string, options?: any): AxiosPromise<BoardInfoModelApiResult> {
            return localVarFp.v1BoardPostBoardStaticNameEditinfoGet(boardStaticName, postId, langCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Password 관련 암호화 키 생성
         * @param {string} boardStaticName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardPostBoardStaticNameEncryptionGet(boardStaticName: string, options?: any): AxiosPromise<RSAKeyTextInfoModelApiResult> {
            return localVarFp.v1BoardPostBoardStaticNameEncryptionGet(boardStaticName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 게시글 목록을 조회합니다.
         * @param {string} boardStaticName 
         * @param {string} [databaseId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {number} [pageNumCount] 
         * @param {string} [searchType] 
         * @param {string} [searchKeyword] 
         * @param {string} [folderId] 
         * @param {string} [langCode] 
         * @param {string} [searchPeriod] 
         * @param {string} [datePeriod] 
         * @param {string} [fieldInfo] 
         * @param {string} [itemFilter] 
         * @param {string} [rootParentID] 
         * @param {string} [fieldDatetimeInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardPostBoardStaticNameGet(boardStaticName: string, databaseId?: string, page?: number, size?: number, pageNumCount?: number, searchType?: string, searchKeyword?: string, folderId?: string, langCode?: string, searchPeriod?: string, datePeriod?: string, fieldInfo?: string, itemFilter?: string, rootParentID?: string, fieldDatetimeInfo?: string, options?: any): AxiosPromise<PostInfoModelItemListInfoModelApiResult> {
            return localVarFp.v1BoardPostBoardStaticNameGet(boardStaticName, databaseId, page, size, pageNumCount, searchType, searchKeyword, folderId, langCode, searchPeriod, datePeriod, fieldInfo, itemFilter, rootParentID, fieldDatetimeInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 게시글 링크 목록을 조회합니다.
         * @param {string} boardStaticName 
         * @param {string} [databaseId] 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardPostBoardStaticNameLinkListPost(boardStaticName: string, databaseId?: string, requestBody?: Array<string>, options?: any): AxiosPromise<PostInfoModelListApiResult> {
            return localVarFp.v1BoardPostBoardStaticNameLinkListPost(boardStaticName, databaseId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 게시글을 등록합니다.
         * @param {string} boardStaticName 
         * @param {string} [databaseId] 
         * @param {ContentsClientDataModel} [contentsClientDataModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardPostBoardStaticNamePost(boardStaticName: string, databaseId?: string, contentsClientDataModel?: ContentsClientDataModel, options?: any): AxiosPromise<GuidApiResult> {
            return localVarFp.v1BoardPostBoardStaticNamePost(boardStaticName, databaseId, contentsClientDataModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 게시글 승인 - 승인게시판
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardPostBoardStaticNamePostIdApprovePost(boardStaticName: string, postId: string, langCode?: string, options?: any): AxiosPromise<BooleanApiResult> {
            return localVarFp.v1BoardPostBoardStaticNamePostIdApprovePost(boardStaticName, postId, langCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 패스워드 변경
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {SettingPasswordModel} [settingPasswordModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardPostBoardStaticNamePostIdChangePasswordPost(boardStaticName: string, postId: string, settingPasswordModel?: SettingPasswordModel, options?: any): AxiosPromise<ApiResult> {
            return localVarFp.v1BoardPostBoardStaticNamePostIdChangePasswordPost(boardStaticName, postId, settingPasswordModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 패스워드 체크
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardPostBoardStaticNamePostIdCheckPasswordPost(boardStaticName: string, postId: string, body?: string, options?: any): AxiosPromise<ApiResult> {
            return localVarFp.v1BoardPostBoardStaticNamePostIdCheckPasswordPost(boardStaticName, postId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 게시글을 삭제합니다.
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {string} [databaseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardPostBoardStaticNamePostIdDelete(boardStaticName: string, postId: string, databaseId?: string, options?: any): AxiosPromise<BooleanApiResult> {
            return localVarFp.v1BoardPostBoardStaticNamePostIdDelete(boardStaticName, postId, databaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 삭제 요청
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardPostBoardStaticNamePostIdDeleteRequestPost(boardStaticName: string, postId: string, options?: any): AxiosPromise<StringApiResult> {
            return localVarFp.v1BoardPostBoardStaticNamePostIdDeleteRequestPost(boardStaticName, postId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 게시글을 조회합니다.  조회용
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {string} [databaseId] 
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardPostBoardStaticNamePostIdGet(boardStaticName: string, postId: string, databaseId?: string, langCode?: string, options?: any): AxiosPromise<PostInfoModelApiResult> {
            return localVarFp.v1BoardPostBoardStaticNamePostIdGet(boardStaticName, postId, databaseId, langCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 게시글 잠금 정보 저장/갱신
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {string} editType 
         * @param {PostInfoManagedModel} [postInfoManagedModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardPostBoardStaticNamePostIdLockApplyEditTypePost(boardStaticName: string, postId: string, editType: string, postInfoManagedModel?: PostInfoManagedModel, options?: any): AxiosPromise<PostInfoManagedModelApiResult> {
            return localVarFp.v1BoardPostBoardStaticNamePostIdLockApplyEditTypePost(boardStaticName, postId, editType, postInfoManagedModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 게시글 잠금 정보 조회
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardPostBoardStaticNamePostIdLockInfoGet(boardStaticName: string, postId: string, options?: any): AxiosPromise<PostInfoManagedModelApiResult> {
            return localVarFp.v1BoardPostBoardStaticNamePostIdLockInfoGet(boardStaticName, postId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 게시글을 타 게시판에 이관합니다.
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {string} [databaseId] 
         * @param {MigrateBoardItemModel} [migrateBoardItemModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardPostBoardStaticNamePostIdMigratePost(boardStaticName: string, postId: string, databaseId?: string, migrateBoardItemModel?: MigrateBoardItemModel, options?: any): AxiosPromise<GuidApiResult> {
            return localVarFp.v1BoardPostBoardStaticNamePostIdMigratePost(boardStaticName, postId, databaseId, migrateBoardItemModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 게시글에 답글을 등록합니다.
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {string} [databaseId] 
         * @param {ContentsClientDataModel} [contentsClientDataModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardPostBoardStaticNamePostIdPost(boardStaticName: string, postId: string, databaseId?: string, contentsClientDataModel?: ContentsClientDataModel, options?: any): AxiosPromise<GuidApiResult> {
            return localVarFp.v1BoardPostBoardStaticNamePostIdPost(boardStaticName, postId, databaseId, contentsClientDataModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 게시글을 수정합니다.
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {string} [databaseId] 
         * @param {ContentsClientDataModel} [contentsClientDataModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardPostBoardStaticNamePostIdPut(boardStaticName: string, postId: string, databaseId?: string, contentsClientDataModel?: ContentsClientDataModel, options?: any): AxiosPromise<ApiResult> {
            return localVarFp.v1BoardPostBoardStaticNamePostIdPut(boardStaticName, postId, databaseId, contentsClientDataModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 게시글의 조회이력을 조회합니다.
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {string} boardType 
         * @param {RequestSearchModel} [requestSearchModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardPostBoardStaticNamePostIdReadingHistoryPost(boardStaticName: string, postId: string, boardType: string, requestSearchModel?: RequestSearchModel, options?: any): AxiosPromise<ResponseReadingHistoryModelApiResult> {
            return localVarFp.v1BoardPostBoardStaticNamePostIdReadingHistoryPost(boardStaticName, postId, boardType, requestSearchModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 게시글 반려 - 승인게시판
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardPostBoardStaticNamePostIdRejectPost(boardStaticName: string, postId: string, langCode?: string, options?: any): AxiosPromise<BooleanApiResult> {
            return localVarFp.v1BoardPostBoardStaticNamePostIdRejectPost(boardStaticName, postId, langCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 게시글을 조회합니다.  게시글 조회화면 전용
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {string} [langCode] 
         * @param {boolean} [hasSearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BoardPostBoardStaticNamePostIdViewInfoGet(boardStaticName: string, postId: string, langCode?: string, hasSearchFilter?: boolean, options?: any): AxiosPromise<PostInfoIntegratedModelApiResult> {
            return localVarFp.v1BoardPostBoardStaticNamePostIdViewInfoGet(boardStaticName, postId, langCode, hasSearchFilter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BoardPostApi - object-oriented interface
 * @export
 * @class BoardPostApi
 * @extends {BaseAPI}
 */
export class BoardPostApi extends BaseAPI {
    /**
     * 
     * @summary staticName으로 작성화면의 설정부분을 조회합니다.
     * @param {string} boardStaticName 
     * @param {string} [postId] 
     * @param {string} [langCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardPostApi
     */
    public v1BoardPostBoardStaticNameEditinfoGet(boardStaticName: string, postId?: string, langCode?: string, options?: AxiosRequestConfig) {
        return BoardPostApiFp(this.configuration).v1BoardPostBoardStaticNameEditinfoGet(boardStaticName, postId, langCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Password 관련 암호화 키 생성
     * @param {string} boardStaticName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardPostApi
     */
    public v1BoardPostBoardStaticNameEncryptionGet(boardStaticName: string, options?: AxiosRequestConfig) {
        return BoardPostApiFp(this.configuration).v1BoardPostBoardStaticNameEncryptionGet(boardStaticName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 게시글 목록을 조회합니다.
     * @param {string} boardStaticName 
     * @param {string} [databaseId] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {number} [pageNumCount] 
     * @param {string} [searchType] 
     * @param {string} [searchKeyword] 
     * @param {string} [folderId] 
     * @param {string} [langCode] 
     * @param {string} [searchPeriod] 
     * @param {string} [datePeriod] 
     * @param {string} [fieldInfo] 
     * @param {string} [itemFilter] 
     * @param {string} [rootParentID] 
     * @param {string} [fieldDatetimeInfo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardPostApi
     */
    public v1BoardPostBoardStaticNameGet(boardStaticName: string, databaseId?: string, page?: number, size?: number, pageNumCount?: number, searchType?: string, searchKeyword?: string, folderId?: string, langCode?: string, searchPeriod?: string, datePeriod?: string, fieldInfo?: string, itemFilter?: string, rootParentID?: string, fieldDatetimeInfo?: string, options?: AxiosRequestConfig) {
        return BoardPostApiFp(this.configuration).v1BoardPostBoardStaticNameGet(boardStaticName, databaseId, page, size, pageNumCount, searchType, searchKeyword, folderId, langCode, searchPeriod, datePeriod, fieldInfo, itemFilter, rootParentID, fieldDatetimeInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 게시글 링크 목록을 조회합니다.
     * @param {string} boardStaticName 
     * @param {string} [databaseId] 
     * @param {Array<string>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardPostApi
     */
    public v1BoardPostBoardStaticNameLinkListPost(boardStaticName: string, databaseId?: string, requestBody?: Array<string>, options?: AxiosRequestConfig) {
        return BoardPostApiFp(this.configuration).v1BoardPostBoardStaticNameLinkListPost(boardStaticName, databaseId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 게시글을 등록합니다.
     * @param {string} boardStaticName 
     * @param {string} [databaseId] 
     * @param {ContentsClientDataModel} [contentsClientDataModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardPostApi
     */
    public v1BoardPostBoardStaticNamePost(boardStaticName: string, databaseId?: string, contentsClientDataModel?: ContentsClientDataModel, options?: AxiosRequestConfig) {
        return BoardPostApiFp(this.configuration).v1BoardPostBoardStaticNamePost(boardStaticName, databaseId, contentsClientDataModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 게시글 승인 - 승인게시판
     * @param {string} boardStaticName 
     * @param {string} postId 
     * @param {string} [langCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardPostApi
     */
    public v1BoardPostBoardStaticNamePostIdApprovePost(boardStaticName: string, postId: string, langCode?: string, options?: AxiosRequestConfig) {
        return BoardPostApiFp(this.configuration).v1BoardPostBoardStaticNamePostIdApprovePost(boardStaticName, postId, langCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 패스워드 변경
     * @param {string} boardStaticName 
     * @param {string} postId 
     * @param {SettingPasswordModel} [settingPasswordModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardPostApi
     */
    public v1BoardPostBoardStaticNamePostIdChangePasswordPost(boardStaticName: string, postId: string, settingPasswordModel?: SettingPasswordModel, options?: AxiosRequestConfig) {
        return BoardPostApiFp(this.configuration).v1BoardPostBoardStaticNamePostIdChangePasswordPost(boardStaticName, postId, settingPasswordModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 패스워드 체크
     * @param {string} boardStaticName 
     * @param {string} postId 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardPostApi
     */
    public v1BoardPostBoardStaticNamePostIdCheckPasswordPost(boardStaticName: string, postId: string, body?: string, options?: AxiosRequestConfig) {
        return BoardPostApiFp(this.configuration).v1BoardPostBoardStaticNamePostIdCheckPasswordPost(boardStaticName, postId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 게시글을 삭제합니다.
     * @param {string} boardStaticName 
     * @param {string} postId 
     * @param {string} [databaseId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardPostApi
     */
    public v1BoardPostBoardStaticNamePostIdDelete(boardStaticName: string, postId: string, databaseId?: string, options?: AxiosRequestConfig) {
        return BoardPostApiFp(this.configuration).v1BoardPostBoardStaticNamePostIdDelete(boardStaticName, postId, databaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 삭제 요청
     * @param {string} boardStaticName 
     * @param {string} postId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardPostApi
     */
    public v1BoardPostBoardStaticNamePostIdDeleteRequestPost(boardStaticName: string, postId: string, options?: AxiosRequestConfig) {
        return BoardPostApiFp(this.configuration).v1BoardPostBoardStaticNamePostIdDeleteRequestPost(boardStaticName, postId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 게시글을 조회합니다.  조회용
     * @param {string} boardStaticName 
     * @param {string} postId 
     * @param {string} [databaseId] 
     * @param {string} [langCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardPostApi
     */
    public v1BoardPostBoardStaticNamePostIdGet(boardStaticName: string, postId: string, databaseId?: string, langCode?: string, options?: AxiosRequestConfig) {
        return BoardPostApiFp(this.configuration).v1BoardPostBoardStaticNamePostIdGet(boardStaticName, postId, databaseId, langCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 게시글 잠금 정보 저장/갱신
     * @param {string} boardStaticName 
     * @param {string} postId 
     * @param {string} editType 
     * @param {PostInfoManagedModel} [postInfoManagedModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardPostApi
     */
    public v1BoardPostBoardStaticNamePostIdLockApplyEditTypePost(boardStaticName: string, postId: string, editType: string, postInfoManagedModel?: PostInfoManagedModel, options?: AxiosRequestConfig) {
        return BoardPostApiFp(this.configuration).v1BoardPostBoardStaticNamePostIdLockApplyEditTypePost(boardStaticName, postId, editType, postInfoManagedModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 게시글 잠금 정보 조회
     * @param {string} boardStaticName 
     * @param {string} postId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardPostApi
     */
    public v1BoardPostBoardStaticNamePostIdLockInfoGet(boardStaticName: string, postId: string, options?: AxiosRequestConfig) {
        return BoardPostApiFp(this.configuration).v1BoardPostBoardStaticNamePostIdLockInfoGet(boardStaticName, postId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 게시글을 타 게시판에 이관합니다.
     * @param {string} boardStaticName 
     * @param {string} postId 
     * @param {string} [databaseId] 
     * @param {MigrateBoardItemModel} [migrateBoardItemModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardPostApi
     */
    public v1BoardPostBoardStaticNamePostIdMigratePost(boardStaticName: string, postId: string, databaseId?: string, migrateBoardItemModel?: MigrateBoardItemModel, options?: AxiosRequestConfig) {
        return BoardPostApiFp(this.configuration).v1BoardPostBoardStaticNamePostIdMigratePost(boardStaticName, postId, databaseId, migrateBoardItemModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 게시글에 답글을 등록합니다.
     * @param {string} boardStaticName 
     * @param {string} postId 
     * @param {string} [databaseId] 
     * @param {ContentsClientDataModel} [contentsClientDataModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardPostApi
     */
    public v1BoardPostBoardStaticNamePostIdPost(boardStaticName: string, postId: string, databaseId?: string, contentsClientDataModel?: ContentsClientDataModel, options?: AxiosRequestConfig) {
        return BoardPostApiFp(this.configuration).v1BoardPostBoardStaticNamePostIdPost(boardStaticName, postId, databaseId, contentsClientDataModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 게시글을 수정합니다.
     * @param {string} boardStaticName 
     * @param {string} postId 
     * @param {string} [databaseId] 
     * @param {ContentsClientDataModel} [contentsClientDataModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardPostApi
     */
    public v1BoardPostBoardStaticNamePostIdPut(boardStaticName: string, postId: string, databaseId?: string, contentsClientDataModel?: ContentsClientDataModel, options?: AxiosRequestConfig) {
        return BoardPostApiFp(this.configuration).v1BoardPostBoardStaticNamePostIdPut(boardStaticName, postId, databaseId, contentsClientDataModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 게시글의 조회이력을 조회합니다.
     * @param {string} boardStaticName 
     * @param {string} postId 
     * @param {string} boardType 
     * @param {RequestSearchModel} [requestSearchModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardPostApi
     */
    public v1BoardPostBoardStaticNamePostIdReadingHistoryPost(boardStaticName: string, postId: string, boardType: string, requestSearchModel?: RequestSearchModel, options?: AxiosRequestConfig) {
        return BoardPostApiFp(this.configuration).v1BoardPostBoardStaticNamePostIdReadingHistoryPost(boardStaticName, postId, boardType, requestSearchModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 게시글 반려 - 승인게시판
     * @param {string} boardStaticName 
     * @param {string} postId 
     * @param {string} [langCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardPostApi
     */
    public v1BoardPostBoardStaticNamePostIdRejectPost(boardStaticName: string, postId: string, langCode?: string, options?: AxiosRequestConfig) {
        return BoardPostApiFp(this.configuration).v1BoardPostBoardStaticNamePostIdRejectPost(boardStaticName, postId, langCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 게시글을 조회합니다.  게시글 조회화면 전용
     * @param {string} boardStaticName 
     * @param {string} postId 
     * @param {string} [langCode] 
     * @param {boolean} [hasSearchFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardPostApi
     */
    public v1BoardPostBoardStaticNamePostIdViewInfoGet(boardStaticName: string, postId: string, langCode?: string, hasSearchFilter?: boolean, options?: AxiosRequestConfig) {
        return BoardPostApiFp(this.configuration).v1BoardPostBoardStaticNamePostIdViewInfoGet(boardStaticName, postId, langCode, hasSearchFilter, options).then((request) => request(this.axios, this.basePath));
    }
}
