<template>
  <div class="community-home-lnb">
    <community-info @go-management="handleManagementClick" @set-cover="settingCoverImage" />
    <!-- start of 커뮤니티 목록 -->
    <div class="col select_area" :class="themeClass">
      <q-card class="my-card text-white">
        <mik-drop-down
          v-model="dropDownModel"
          :label="$t('Community_Menu_MyCommunity', '내가 속한 커뮤니티')"
          :drop-down-options="store.myCommunityDropDownOption"
          :value="'테스트'"
          @update:model-value="changeMyCommunityDropDown"
      /></q-card>
    </div>
    <!-- end of 커뮤니티 목록 -->

    <!-- 가입 -->
    <div v-if="store.communityInfo.isJoinable?.toLowerCase() == 'y'" class="col q-ma-sm">
      <mik-button
        :button-txt="$t('JoinRequest', '커뮤니티 가입요청')"
        :click-function="handleJoinMember"
        size="md"
        :class="themeClass"
        :style="{ width: '100%' }" />
    </div>

    <!-- start of 위젯 -->
    <div>
      <!--CategoryTemplate, RecentItemTemplate 표시-->
      <lnb-widget v-for="(item, idx) in lnbWidgets" :key="idx" :lnb="item" :is-last="idx == lnbWidgets.length - 1" />
    </div>
  </div>

  <common-popup
    v-model="isJoinMemberVisible"
    :title="$t('JoinRequest', '커뮤니티 가입요청')"
    size="m"
    :on-cancel="handleJoinMemberClose">
    <join-member-popup
      :community-info="store.communityInfo"
      @on-hide-popup="handleJoinMemberClose"
      @on-join-request-popup="handleJoinMemberRequest" />
  </common-popup>
</template>

<script setup lang="ts">
import CommunityInfo from 'mik-community/src/components/lnb/CommunityInfo.vue'
import JoinMemberPopup from 'mik-community/src/components/popup/JoinMemberPopup.vue'

import MikDropDown, { DropDownOption } from 'mik-common/src/components/common/MikSelectDropdown.vue'
import { useCommunityStore } from 'mik-community/src/store/community'
import LnbWidget from 'mik-community/src/controls/LnbWidget.vue'
import { getThemeClass } from 'mik-community/src/utils/util'

import { CommunityInfoModel } from 'mik-common/src/api-autogen/community'

const store = useCommunityStore()
const $emit = defineEmits(['go-management', 'set-cover'])
const lnbWidgets = computed(() => store.lnbWidgetList)
const themeClass = ref(getThemeClass('lnb'))

const router = useRouter()

const dropDownModel = ref<DropDownOption>({
  value: '',
  label: store.communityInfo.title ?? ''
})

const changeMyCommunityDropDown = (option: DropDownOption): void => {
  dropDownModel.value.value = option.value
  dropDownModel.value.label = option.label

  /**
   * To-Do
   * 현재는 새창 띄우기로 동작 (as-is 동일)
   * 페이지 이동으로 변경 시 router.push를 사용하는 경우 Gnb, Lnb 영역 Refresh가 되지 않음 (필요시 개선)
   */

  const url = `${import.meta.env.V_COMMUNITY_PATH}/${option.value}`
  window.open(url, '_blank')
}

const handleManagementClick = () => {
  $emit('go-management')
}

const settingCoverImage = () => {
  $emit('set-cover')
}

watch(
  () => store.theme,
  (newVal, oldVal) => {
    themeClass.value = getThemeClass('lnb')
  }
)

/** 가입요청 popup*/
const isJoinMemberVisible = ref<boolean>(false)

/** 가입요청 팝업 open */
const handleJoinMember = async () => {
  isJoinMemberVisible.value = true
}

/** 가입요청 팝업 close */
const handleJoinMemberRequest = async () => {
  isJoinMemberVisible.value = false
}

/** 가입요청 팝업 close */
const handleJoinMemberClose = async () => {
  isJoinMemberVisible.value = false
}
</script>
<style scoped lang="scss">
.community-home-lnb {
  .select_area {
    padding: 15px 20px 15px 20px;
    background-color: #444;

    .q-field {
      padding: 0px 10px 5px 10px;
    }
  }

  .q-expansion-item {
    padding: 10px 5px 5px 5px;

    .lnb_menu_header {
      width: 100%;
      line-height: 1.33em;
      font-size: 14px;
      font-weight: 700;
    }

    .q-scrollarea {
      padding: 0px 20px 0px 10px;

      .q-tree {
        line-height: 1.4;
        font-size: 15px;
        font-weight: 400;
        white-space: nowrap;
      }
    }

    .q-list {
      padding: 0px 20px 0px 0px;

      .q-item {
        line-height: 1.4;
        font-size: 15px;
        font-weight: 400;
        white-space: nowrap;
      }
    }
  }

  .q-separator {
    margin: 0px 15px 0px 15px;
  }
}
</style>
