<template>
  <component :is="refLnb" class="q-mt-sm" />
  <q-separator v-if="!props.isLast" :color="getThemeClass('lnb-separator')" />
</template>

<script setup lang="ts">
import { LnbWidgetModel } from 'mik-common/src/api-autogen/community'
import LnbCategory from 'mik-community/src/components/lnb/Category.vue'
import LnbRecentList from 'mik-community/src/components/lnb/RecentList.vue'
import LnbNotice from 'mik-community/src/components/lnb/Notice.vue'
import { getThemeClass } from 'mik-community/src/utils/util'

const props = defineProps<{
  lnb: LnbWidgetModel
  isLast: boolean
}>()
const refLnb = shallowRef()
/**
 * 100: 카테고리
 * 101: 최근 게시글
 * 102: 공지사항
 */
switch (props.lnb.lnbWidgetId) {
  case '100':
    refLnb.value = LnbCategory
    break
  case '101':
    refLnb.value = LnbRecentList
    break
  case '102':
    refLnb.value = LnbNotice
    break
}
</script>

<style scoped></style>
