import { GalleryApi, GalleryPostApi } from 'mik-common/src/api-autogen/portal/api'
import {
  BoardInfoModel,
  BoardInfoModelApiResult,
  UserAuthorityConstant
} from 'mik-common/src/api-autogen/portal/models'
import { AxiosResponse } from 'axios'
import { SearchOption } from 'mik-board/src/models/board/header/search-option'
import { useUserInfoStore } from '@gnb/modules/menus/main'
import { Cookies } from 'quasar'

export const useGalleryStore = defineStore('gallery', {
  state: (): IBoard => {
    return {
      postId: '',
      // 목록 게시판 정보
      boardInfo: {} as BoardInfoModel,
      // 수정이나, 조회 게시판 정보
      boardInfoByView: {} as BoardInfoModel,
      authLevel: 0 as UserAuthorityConstant,
      searchOption: { pageNumber: 1 } as SearchOption
    }
  },
  getters: {
    boardStaticName(state): string {
      if (state.boardInfo.boardUrl) {
        return state.boardInfo.boardUrl
      } else if (state.boardInfoByView.boardUrl) {
        return state.boardInfoByView.boardUrl
      } else {
        return ''
      }
    },
    isEdit(state): boolean {
      return state.authLevel >= UserAuthorityConstant.USER_AUTH_READWRITE
    },
    isAdmin(state): boolean {
      return state.authLevel >= UserAuthorityConstant.USER_AUTH_MANAGER
    },
    enableNotification(state): boolean {
      return state.boardInfo?.enableNotification ?? false
    }
  },
  actions: {
    async getBoardInfo(boardStaticName: string, forceRefresh: boolean) {
      if (this.boardInfo.boardUrl !== boardStaticName || forceRefresh) {
        const galleryApi = new GalleryApi()
        const response: AxiosResponse<BoardInfoModelApiResult> =
          await galleryApi.v1GalleryBoardStaticNameGet(boardStaticName)

        if (response.data.success) {
          Object.assign(this.boardInfo, response.data.data)
          this.authLevel = response.data.userAuthority ?? UserAuthorityConstant.USER_AUTH_NONE
        } else {
          Object.assign(this.boardInfo, {})
          this.authLevel = UserAuthorityConstant.USER_AUTH_NONE
        }

        this.initSearchOption()
      }
    },
    async getBoardInfoByView(boardStaticName: string, postId: string) {
      if (this.boardInfoByView.boardUrl !== boardStaticName || this.postId !== postId || postId == '') {
        const galleryApi = new GalleryPostApi()
        const response: AxiosResponse<BoardInfoModelApiResult> =
          await galleryApi.v1GalleryPostBoardStaticNameEditinfoGet(
            boardStaticName,
            postId,
            Cookies.get('GWP_LANGUAGE_CODE') ?? 'ko'
          )

        if (response.data.success) {
          Object.assign(this.boardInfoByView, response.data.data)
          this.postId = postId
          this.authLevel = response.data.userAuthority ?? UserAuthorityConstant.USER_AUTH_NONE
        } else {
          Object.assign(this.boardInfoByView, {})
          this.postId = ''
          this.authLevel = UserAuthorityConstant.USER_AUTH_NONE
        }
      }
    },
    initSearchOption() {
      // 검색조건 초기화
      Object.assign(this.searchOption, {
        pageNumber: 1,
        searchKeyword: '',
        searchType: '',
        searchPeriod: '',
        fieldInfo: '',
        datePeriod: undefined
      })
    }
  }
})

export interface IBoard {
  postId: string
  boardInfo: BoardInfoModel
  boardInfoByView: BoardInfoModel
  authLevel: number
  searchOption: SearchOption
}
