/* tslint:disable */
/* eslint-disable */
/**
 * Mik.SmartPortal.CompanyRule.App
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import type { RequestArgs } from '../base';
// @ts-ignore
import type { ApiResult } from '../models';
// @ts-ignore
import type { BooleanApiResult } from '../models';
// @ts-ignore
import type { CategoryModelListApiResult } from '../models';
// @ts-ignore
import type { DocParamModel } from '../models';
// @ts-ignore
import type { DocumentModelApiResult } from '../models';
// @ts-ignore
import type { DocumentModelListApiResult } from '../models';
// @ts-ignore
import type { DocumentModelPagedApiResult } from '../models';
// @ts-ignore
import type { Int32ApiResult } from '../models';
// @ts-ignore
import type { RevisionHistoryModelPagedApiResult } from '../models';
// @ts-ignore
import type { StringApiResult } from '../models';
/**
 * CompanyRuleApi - axios parameter creator
 * @export
 */
export const CompanyRuleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 권한 Level
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CompanyRuleAuthorityLevelGetGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/companyRule/authorityLevel/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 전체 카테고리 리스트를 반환한다.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CompanyRuleCategoryListGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/companyRule/category/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 사규 문서를 생성한다.
         * @param {DocParamModel} [docParamModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CompanyRuleDocAddPost: async (docParamModel?: DocParamModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/companyRule/doc/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(docParamModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 문서를 삭제한다.
         * @param {string} [docID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CompanyRuleDocDeleteDelete: async (docID?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/companyRule/doc/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (docID !== undefined) {
                localVarQueryParameter['docID'] = docID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 사규 정보를 반환한다.
         * @param {string} [docID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CompanyRuleDocGetGet: async (docID?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/companyRule/doc/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (docID !== undefined) {
                localVarQueryParameter['docID'] = docID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 전체 사규 리스트를 반환한다.
         * @param {boolean} [isAdminCheck] 
         * @param {string} [searchType] 
         * @param {string} [searchKeyword] 
         * @param {string} [categoryID] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CompanyRuleDocListGet: async (isAdminCheck?: boolean, searchType?: string, searchKeyword?: string, categoryID?: string, pageNumber?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/companyRule/doc/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (isAdminCheck !== undefined) {
                localVarQueryParameter['isAdminCheck'] = isAdminCheck;
            }

            if (searchType !== undefined) {
                localVarQueryParameter['searchType'] = searchType;
            }

            if (searchKeyword !== undefined) {
                localVarQueryParameter['searchKeyword'] = searchKeyword;
            }

            if (categoryID !== undefined) {
                localVarQueryParameter['categoryID'] = categoryID;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 사규 문서를 개정한다.
         * @param {string} [docID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CompanyRuleDocPublishPut: async (docID?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/companyRule/doc/publish`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (docID !== undefined) {
                localVarQueryParameter['docID'] = docID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 사규 문서를 수정한다.
         * @param {DocParamModel} [docParamModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CompanyRuleDocUpdatePut: async (docParamModel?: DocParamModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/companyRule/doc/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(docParamModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 사규 상세 - 개정 이력
         * @param {string} [ruleID] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CompanyRuleRevisionHistoryListGet: async (ruleID?: string, pageNumber?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/companyRule/revisionHistory/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ruleID !== undefined) {
                localVarQueryParameter['ruleID'] = ruleID;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 사규 상세 - 재개정 이력
         * @param {string} [ruleID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CompanyRuleRuleDocListGet: async (ruleID?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/companyRule/ruleDoc/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ruleID !== undefined) {
                localVarQueryParameter['ruleID'] = ruleID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyRuleApi - functional programming interface
 * @export
 */
export const CompanyRuleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyRuleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 권한 Level
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CompanyRuleAuthorityLevelGetGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Int32ApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CompanyRuleAuthorityLevelGetGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 전체 카테고리 리스트를 반환한다.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CompanyRuleCategoryListGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryModelListApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CompanyRuleCategoryListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 사규 문서를 생성한다.
         * @param {DocParamModel} [docParamModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CompanyRuleDocAddPost(docParamModel?: DocParamModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CompanyRuleDocAddPost(docParamModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 문서를 삭제한다.
         * @param {string} [docID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CompanyRuleDocDeleteDelete(docID?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CompanyRuleDocDeleteDelete(docID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 사규 정보를 반환한다.
         * @param {string} [docID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CompanyRuleDocGetGet(docID?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentModelApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CompanyRuleDocGetGet(docID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 전체 사규 리스트를 반환한다.
         * @param {boolean} [isAdminCheck] 
         * @param {string} [searchType] 
         * @param {string} [searchKeyword] 
         * @param {string} [categoryID] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CompanyRuleDocListGet(isAdminCheck?: boolean, searchType?: string, searchKeyword?: string, categoryID?: string, pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentModelPagedApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CompanyRuleDocListGet(isAdminCheck, searchType, searchKeyword, categoryID, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 사규 문서를 개정한다.
         * @param {string} [docID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CompanyRuleDocPublishPut(docID?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CompanyRuleDocPublishPut(docID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 사규 문서를 수정한다.
         * @param {DocParamModel} [docParamModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CompanyRuleDocUpdatePut(docParamModel?: DocParamModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CompanyRuleDocUpdatePut(docParamModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 사규 상세 - 개정 이력
         * @param {string} [ruleID] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CompanyRuleRevisionHistoryListGet(ruleID?: string, pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RevisionHistoryModelPagedApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CompanyRuleRevisionHistoryListGet(ruleID, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 사규 상세 - 재개정 이력
         * @param {string} [ruleID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CompanyRuleRuleDocListGet(ruleID?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentModelListApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CompanyRuleRuleDocListGet(ruleID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanyRuleApi - factory interface
 * @export
 */
export const CompanyRuleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyRuleApiFp(configuration)
    return {
        /**
         * 
         * @summary 권한 Level
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CompanyRuleAuthorityLevelGetGet(options?: any): AxiosPromise<Int32ApiResult> {
            return localVarFp.apiV1CompanyRuleAuthorityLevelGetGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 전체 카테고리 리스트를 반환한다.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CompanyRuleCategoryListGet(options?: any): AxiosPromise<CategoryModelListApiResult> {
            return localVarFp.apiV1CompanyRuleCategoryListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 사규 문서를 생성한다.
         * @param {DocParamModel} [docParamModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CompanyRuleDocAddPost(docParamModel?: DocParamModel, options?: any): AxiosPromise<StringApiResult> {
            return localVarFp.apiV1CompanyRuleDocAddPost(docParamModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 문서를 삭제한다.
         * @param {string} [docID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CompanyRuleDocDeleteDelete(docID?: string, options?: any): AxiosPromise<BooleanApiResult> {
            return localVarFp.apiV1CompanyRuleDocDeleteDelete(docID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 사규 정보를 반환한다.
         * @param {string} [docID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CompanyRuleDocGetGet(docID?: string, options?: any): AxiosPromise<DocumentModelApiResult> {
            return localVarFp.apiV1CompanyRuleDocGetGet(docID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 전체 사규 리스트를 반환한다.
         * @param {boolean} [isAdminCheck] 
         * @param {string} [searchType] 
         * @param {string} [searchKeyword] 
         * @param {string} [categoryID] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CompanyRuleDocListGet(isAdminCheck?: boolean, searchType?: string, searchKeyword?: string, categoryID?: string, pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<DocumentModelPagedApiResult> {
            return localVarFp.apiV1CompanyRuleDocListGet(isAdminCheck, searchType, searchKeyword, categoryID, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 사규 문서를 개정한다.
         * @param {string} [docID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CompanyRuleDocPublishPut(docID?: string, options?: any): AxiosPromise<BooleanApiResult> {
            return localVarFp.apiV1CompanyRuleDocPublishPut(docID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 사규 문서를 수정한다.
         * @param {DocParamModel} [docParamModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CompanyRuleDocUpdatePut(docParamModel?: DocParamModel, options?: any): AxiosPromise<ApiResult> {
            return localVarFp.apiV1CompanyRuleDocUpdatePut(docParamModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 사규 상세 - 개정 이력
         * @param {string} [ruleID] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CompanyRuleRevisionHistoryListGet(ruleID?: string, pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<RevisionHistoryModelPagedApiResult> {
            return localVarFp.apiV1CompanyRuleRevisionHistoryListGet(ruleID, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 사규 상세 - 재개정 이력
         * @param {string} [ruleID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CompanyRuleRuleDocListGet(ruleID?: string, options?: any): AxiosPromise<DocumentModelListApiResult> {
            return localVarFp.apiV1CompanyRuleRuleDocListGet(ruleID, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyRuleApi - object-oriented interface
 * @export
 * @class CompanyRuleApi
 * @extends {BaseAPI}
 */
export class CompanyRuleApi extends BaseAPI {
    /**
     * 
     * @summary 권한 Level
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyRuleApi
     */
    public apiV1CompanyRuleAuthorityLevelGetGet(options?: AxiosRequestConfig) {
        return CompanyRuleApiFp(this.configuration).apiV1CompanyRuleAuthorityLevelGetGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 전체 카테고리 리스트를 반환한다.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyRuleApi
     */
    public apiV1CompanyRuleCategoryListGet(options?: AxiosRequestConfig) {
        return CompanyRuleApiFp(this.configuration).apiV1CompanyRuleCategoryListGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 사규 문서를 생성한다.
     * @param {DocParamModel} [docParamModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyRuleApi
     */
    public apiV1CompanyRuleDocAddPost(docParamModel?: DocParamModel, options?: AxiosRequestConfig) {
        return CompanyRuleApiFp(this.configuration).apiV1CompanyRuleDocAddPost(docParamModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 문서를 삭제한다.
     * @param {string} [docID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyRuleApi
     */
    public apiV1CompanyRuleDocDeleteDelete(docID?: string, options?: AxiosRequestConfig) {
        return CompanyRuleApiFp(this.configuration).apiV1CompanyRuleDocDeleteDelete(docID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 사규 정보를 반환한다.
     * @param {string} [docID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyRuleApi
     */
    public apiV1CompanyRuleDocGetGet(docID?: string, options?: AxiosRequestConfig) {
        return CompanyRuleApiFp(this.configuration).apiV1CompanyRuleDocGetGet(docID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 전체 사규 리스트를 반환한다.
     * @param {boolean} [isAdminCheck] 
     * @param {string} [searchType] 
     * @param {string} [searchKeyword] 
     * @param {string} [categoryID] 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyRuleApi
     */
    public apiV1CompanyRuleDocListGet(isAdminCheck?: boolean, searchType?: string, searchKeyword?: string, categoryID?: string, pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return CompanyRuleApiFp(this.configuration).apiV1CompanyRuleDocListGet(isAdminCheck, searchType, searchKeyword, categoryID, pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 사규 문서를 개정한다.
     * @param {string} [docID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyRuleApi
     */
    public apiV1CompanyRuleDocPublishPut(docID?: string, options?: AxiosRequestConfig) {
        return CompanyRuleApiFp(this.configuration).apiV1CompanyRuleDocPublishPut(docID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 사규 문서를 수정한다.
     * @param {DocParamModel} [docParamModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyRuleApi
     */
    public apiV1CompanyRuleDocUpdatePut(docParamModel?: DocParamModel, options?: AxiosRequestConfig) {
        return CompanyRuleApiFp(this.configuration).apiV1CompanyRuleDocUpdatePut(docParamModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 사규 상세 - 개정 이력
     * @param {string} [ruleID] 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyRuleApi
     */
    public apiV1CompanyRuleRevisionHistoryListGet(ruleID?: string, pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return CompanyRuleApiFp(this.configuration).apiV1CompanyRuleRevisionHistoryListGet(ruleID, pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 사규 상세 - 재개정 이력
     * @param {string} [ruleID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyRuleApi
     */
    public apiV1CompanyRuleRuleDocListGet(ruleID?: string, options?: AxiosRequestConfig) {
        return CompanyRuleApiFp(this.configuration).apiV1CompanyRuleRuleDocListGet(ruleID, options).then((request) => request(this.axios, this.basePath));
    }
}
