// @ts-check
import { defineStore } from 'pinia'

import { CommonApi, WorkTypeEntity } from 'mik-common/src/api-autogen/workplanner'

const commonApi = new CommonApi()

export const useWorkPlannerStore = defineStore('workplanner', {
  persist: [
    {
      paths: ['workType', 'apvInfo'],
      storage: sessionStorage
    }
  ],
  state: () => {
    return {
      workType: {
        userAuthority: 0 as Number,
        workTypeList: [] as WorkTypeEntity[]
      },
      apvInfo: {
        hostUrl: '' as string
      },
      calendarBoardInfo: {
        boardName: '' as string
      }
    }
  },
  getters: {
    getWorkType: (state) => {
      return state.workType
    },
    getApvInfo: (state) => {
      return state.apvInfo
    }
  },
  actions: {
    async setWorkType(lang: string) {
      await commonApi.v1CommonWorkTypeSelectAllGet(lang).then((result) => {
        this.workType.userAuthority = result.data.userAuthority ?? 0
        this.workType.workTypeList = result.data.data ?? []
      })
    },
    async setApvInfo() {
      await commonApi.v1CommonGetConfigGet('ApvHost').then((result) => {
        if (result.data.success) {
          if (result.data.data) {
            this.apvInfo.hostUrl = result.data.data
          }
        }
      })
    },
    async setCalendarBoardNameInfo() {
      await commonApi.v1CommonGetConfigGet('CalendarBoardName').then((result) => {
        if (result.data.success) {
          if (result.data.data) {
            this.calendarBoardInfo.boardName = result.data.data
          }
        }
      })
    }
  }
})
