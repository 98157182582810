/* tslint:disable */
/* eslint-disable */
/**
 * Mik.SmartPortal.ConsentForm.App
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import type { RequestArgs } from '../base';
// @ts-ignore
import type { ApiResult } from '../models';
// @ts-ignore
import type { ConsentFormMailSendType } from '../models';
// @ts-ignore
import type { ConsentFormModel } from '../models';
// @ts-ignore
import type { ConsentFormModelApiResult } from '../models';
// @ts-ignore
import type { ConsentFormModelConsentItemListInfoModelApiResult } from '../models';
// @ts-ignore
import type { ConsentFormPeriodModel } from '../models';
// @ts-ignore
import type { ConsentFormRecipientModelListApiResult } from '../models';
// @ts-ignore
import type { ConsentFormRequestModel } from '../models';
// @ts-ignore
import type { ContentsHtmlModelApiResult } from '../models';
// @ts-ignore
import type { StringApiResult } from '../models';
/**
 * ConsentFormApi - axios parameter creator
 * @export
 */
export const ConsentFormApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Content View Handler
         * @param {string} formID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConsentFormContentViewFormIDGet: async (formID: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formID' is not null or undefined
            assertParamExists('v1ConsentFormContentViewFormIDGet', 'formID', formID)
            const localVarPath = `/v1/ConsentForm/Content/View/{formID}`
                .replace(`{${"formID"}}`, encodeURIComponent(String(formID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sample Html 조회
         * @param {string} [formID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConsentFormContentsGet: async (formID?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/ConsentForm/Contents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (formID !== undefined) {
                localVarQueryParameter['formID'] = formID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 동의서 생성
         * @param {ConsentFormModel} [consentFormModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConsentFormCreatePost: async (consentFormModel?: ConsentFormModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/ConsentForm/Create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(consentFormModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 동의서를 삭제합니다.
         * @param {string} formID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConsentFormDeleteFormIDPost: async (formID: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formID' is not null or undefined
            assertParamExists('v1ConsentFormDeleteFormIDPost', 'formID', formID)
            const localVarPath = `/v1/ConsentForm/Delete/{formID}`
                .replace(`{${"formID"}}`, encodeURIComponent(String(formID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 동의서를 상태를 발행상태로 바꾸고  전체 동의서 대상자에게 메일을 발송합니다.
         * @param {string} formID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConsentFormDistributeFormIDPost: async (formID: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formID' is not null or undefined
            assertParamExists('v1ConsentFormDistributeFormIDPost', 'formID', formID)
            const localVarPath = `/v1/ConsentForm/Distribute/{formID}`
                .replace(`{${"formID"}}`, encodeURIComponent(String(formID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 새 버전 동의서를 생성합니다(동의서 복사).
         * @param {ConsentFormModel} [consentFormModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConsentFormDuplicatePost: async (consentFormModel?: ConsentFormModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/ConsentForm/Duplicate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(consentFormModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Form ID를 기반으로 동의서 양식을 조회합니다.
         * @param {string} formID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConsentFormFormIDGet: async (formID: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formID' is not null or undefined
            assertParamExists('v1ConsentFormFormIDGet', 'formID', formID)
            const localVarPath = `/v1/ConsentForm/{formID}`
                .replace(`{${"formID"}}`, encodeURIComponent(String(formID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 동의서 목록 조회
         * @param {ConsentFormRequestModel} [consentFormRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConsentFormListPost: async (consentFormRequestModel?: ConsentFormRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/ConsentForm/List`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(consentFormRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 선택된 메일 수신자에게 메일을 발송합니다.  All : 전체 발송  Unresponsive : 미응답자 발송
         * @param {string} formID 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConsentFormNotificationSendFormIDPost: async (formID: string, body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formID' is not null or undefined
            assertParamExists('v1ConsentFormNotificationSendFormIDPost', 'formID', formID)
            const localVarPath = `/v1/ConsentForm/Notification/Send/{formID}`
                .replace(`{${"formID"}}`, encodeURIComponent(String(formID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 동의서 수집 기간을 수정합니다.
         * @param {ConsentFormPeriodModel} [consentFormPeriodModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConsentFormPeriodUpdatePost: async (consentFormPeriodModel?: ConsentFormPeriodModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/ConsentForm/Period/Update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(consentFormPeriodModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 발송된 동의서 페이지에서 수신자만 추가 할 수 있는 함수입니다.  이미 발송된 동의서의 content는 수정 할 수 없으며 수신자 추가 혹은 수집일자 변경만 가능합니다.
         * @param {ConsentFormModel} [consentFormModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConsentFormRecipientsAddPost: async (consentFormModel?: ConsentFormModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/ConsentForm/Recipients/Add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(consentFormModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 수신자 조회
         * @param {string} formID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConsentFormRecipientsFormIDGet: async (formID: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formID' is not null or undefined
            assertParamExists('v1ConsentFormRecipientsFormIDGet', 'formID', formID)
            const localVarPath = `/v1/ConsentForm/Recipients/{formID}`
                .replace(`{${"formID"}}`, encodeURIComponent(String(formID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 동의서를 수정합니다.
         * @param {ConsentFormModel} [consentFormModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConsentFormUpdatePost: async (consentFormModel?: ConsentFormModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/ConsentForm/Update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(consentFormModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary User Consent View Handler  동의서 수신자 인쇄 팝업
         * @param {string} formID 
         * @param {string} userType 
         * @param {string} [userID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConsentFormUserConsentViewFormIDUserTypeGet: async (formID: string, userType: string, userID?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formID' is not null or undefined
            assertParamExists('v1ConsentFormUserConsentViewFormIDUserTypeGet', 'formID', formID)
            // verify required parameter 'userType' is not null or undefined
            assertParamExists('v1ConsentFormUserConsentViewFormIDUserTypeGet', 'userType', userType)
            const localVarPath = `/v1/ConsentForm/User/Consent/View/{formID}/{userType}`
                .replace(`{${"formID"}}`, encodeURIComponent(String(formID)))
                .replace(`{${"userType"}}`, encodeURIComponent(String(userType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userID !== undefined) {
                localVarQueryParameter['userID'] = userID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConsentFormApi - functional programming interface
 * @export
 */
export const ConsentFormApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConsentFormApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Content View Handler
         * @param {string} formID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ConsentFormContentViewFormIDGet(formID: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ConsentFormContentViewFormIDGet(formID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sample Html 조회
         * @param {string} [formID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ConsentFormContentsGet(formID?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentsHtmlModelApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ConsentFormContentsGet(formID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 동의서 생성
         * @param {ConsentFormModel} [consentFormModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ConsentFormCreatePost(consentFormModel?: ConsentFormModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ConsentFormCreatePost(consentFormModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 동의서를 삭제합니다.
         * @param {string} formID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ConsentFormDeleteFormIDPost(formID: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ConsentFormDeleteFormIDPost(formID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 동의서를 상태를 발행상태로 바꾸고  전체 동의서 대상자에게 메일을 발송합니다.
         * @param {string} formID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ConsentFormDistributeFormIDPost(formID: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ConsentFormDistributeFormIDPost(formID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 새 버전 동의서를 생성합니다(동의서 복사).
         * @param {ConsentFormModel} [consentFormModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ConsentFormDuplicatePost(consentFormModel?: ConsentFormModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ConsentFormDuplicatePost(consentFormModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Form ID를 기반으로 동의서 양식을 조회합니다.
         * @param {string} formID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ConsentFormFormIDGet(formID: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsentFormModelApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ConsentFormFormIDGet(formID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 동의서 목록 조회
         * @param {ConsentFormRequestModel} [consentFormRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ConsentFormListPost(consentFormRequestModel?: ConsentFormRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsentFormModelConsentItemListInfoModelApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ConsentFormListPost(consentFormRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 선택된 메일 수신자에게 메일을 발송합니다.  All : 전체 발송  Unresponsive : 미응답자 발송
         * @param {string} formID 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ConsentFormNotificationSendFormIDPost(formID: string, body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ConsentFormNotificationSendFormIDPost(formID, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 동의서 수집 기간을 수정합니다.
         * @param {ConsentFormPeriodModel} [consentFormPeriodModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ConsentFormPeriodUpdatePost(consentFormPeriodModel?: ConsentFormPeriodModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ConsentFormPeriodUpdatePost(consentFormPeriodModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 발송된 동의서 페이지에서 수신자만 추가 할 수 있는 함수입니다.  이미 발송된 동의서의 content는 수정 할 수 없으며 수신자 추가 혹은 수집일자 변경만 가능합니다.
         * @param {ConsentFormModel} [consentFormModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ConsentFormRecipientsAddPost(consentFormModel?: ConsentFormModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsentFormModelApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ConsentFormRecipientsAddPost(consentFormModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 수신자 조회
         * @param {string} formID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ConsentFormRecipientsFormIDGet(formID: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsentFormRecipientModelListApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ConsentFormRecipientsFormIDGet(formID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 동의서를 수정합니다.
         * @param {ConsentFormModel} [consentFormModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ConsentFormUpdatePost(consentFormModel?: ConsentFormModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ConsentFormUpdatePost(consentFormModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary User Consent View Handler  동의서 수신자 인쇄 팝업
         * @param {string} formID 
         * @param {string} userType 
         * @param {string} [userID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ConsentFormUserConsentViewFormIDUserTypeGet(formID: string, userType: string, userID?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ConsentFormUserConsentViewFormIDUserTypeGet(formID, userType, userID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConsentFormApi - factory interface
 * @export
 */
export const ConsentFormApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConsentFormApiFp(configuration)
    return {
        /**
         * 
         * @summary Content View Handler
         * @param {string} formID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConsentFormContentViewFormIDGet(formID: string, options?: any): AxiosPromise<void> {
            return localVarFp.v1ConsentFormContentViewFormIDGet(formID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sample Html 조회
         * @param {string} [formID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConsentFormContentsGet(formID?: string, options?: any): AxiosPromise<ContentsHtmlModelApiResult> {
            return localVarFp.v1ConsentFormContentsGet(formID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 동의서 생성
         * @param {ConsentFormModel} [consentFormModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConsentFormCreatePost(consentFormModel?: ConsentFormModel, options?: any): AxiosPromise<ApiResult> {
            return localVarFp.v1ConsentFormCreatePost(consentFormModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 동의서를 삭제합니다.
         * @param {string} formID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConsentFormDeleteFormIDPost(formID: string, options?: any): AxiosPromise<ApiResult> {
            return localVarFp.v1ConsentFormDeleteFormIDPost(formID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 동의서를 상태를 발행상태로 바꾸고  전체 동의서 대상자에게 메일을 발송합니다.
         * @param {string} formID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConsentFormDistributeFormIDPost(formID: string, options?: any): AxiosPromise<ApiResult> {
            return localVarFp.v1ConsentFormDistributeFormIDPost(formID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 새 버전 동의서를 생성합니다(동의서 복사).
         * @param {ConsentFormModel} [consentFormModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConsentFormDuplicatePost(consentFormModel?: ConsentFormModel, options?: any): AxiosPromise<ApiResult> {
            return localVarFp.v1ConsentFormDuplicatePost(consentFormModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Form ID를 기반으로 동의서 양식을 조회합니다.
         * @param {string} formID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConsentFormFormIDGet(formID: string, options?: any): AxiosPromise<ConsentFormModelApiResult> {
            return localVarFp.v1ConsentFormFormIDGet(formID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 동의서 목록 조회
         * @param {ConsentFormRequestModel} [consentFormRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConsentFormListPost(consentFormRequestModel?: ConsentFormRequestModel, options?: any): AxiosPromise<ConsentFormModelConsentItemListInfoModelApiResult> {
            return localVarFp.v1ConsentFormListPost(consentFormRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 선택된 메일 수신자에게 메일을 발송합니다.  All : 전체 발송  Unresponsive : 미응답자 발송
         * @param {string} formID 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConsentFormNotificationSendFormIDPost(formID: string, body?: number, options?: any): AxiosPromise<ApiResult> {
            return localVarFp.v1ConsentFormNotificationSendFormIDPost(formID, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 동의서 수집 기간을 수정합니다.
         * @param {ConsentFormPeriodModel} [consentFormPeriodModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConsentFormPeriodUpdatePost(consentFormPeriodModel?: ConsentFormPeriodModel, options?: any): AxiosPromise<ApiResult> {
            return localVarFp.v1ConsentFormPeriodUpdatePost(consentFormPeriodModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 발송된 동의서 페이지에서 수신자만 추가 할 수 있는 함수입니다.  이미 발송된 동의서의 content는 수정 할 수 없으며 수신자 추가 혹은 수집일자 변경만 가능합니다.
         * @param {ConsentFormModel} [consentFormModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConsentFormRecipientsAddPost(consentFormModel?: ConsentFormModel, options?: any): AxiosPromise<ConsentFormModelApiResult> {
            return localVarFp.v1ConsentFormRecipientsAddPost(consentFormModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 수신자 조회
         * @param {string} formID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConsentFormRecipientsFormIDGet(formID: string, options?: any): AxiosPromise<ConsentFormRecipientModelListApiResult> {
            return localVarFp.v1ConsentFormRecipientsFormIDGet(formID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 동의서를 수정합니다.
         * @param {ConsentFormModel} [consentFormModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConsentFormUpdatePost(consentFormModel?: ConsentFormModel, options?: any): AxiosPromise<ApiResult> {
            return localVarFp.v1ConsentFormUpdatePost(consentFormModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary User Consent View Handler  동의서 수신자 인쇄 팝업
         * @param {string} formID 
         * @param {string} userType 
         * @param {string} [userID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ConsentFormUserConsentViewFormIDUserTypeGet(formID: string, userType: string, userID?: string, options?: any): AxiosPromise<StringApiResult> {
            return localVarFp.v1ConsentFormUserConsentViewFormIDUserTypeGet(formID, userType, userID, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConsentFormApi - object-oriented interface
 * @export
 * @class ConsentFormApi
 * @extends {BaseAPI}
 */
export class ConsentFormApi extends BaseAPI {
    /**
     * 
     * @summary Content View Handler
     * @param {string} formID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentFormApi
     */
    public v1ConsentFormContentViewFormIDGet(formID: string, options?: AxiosRequestConfig) {
        return ConsentFormApiFp(this.configuration).v1ConsentFormContentViewFormIDGet(formID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sample Html 조회
     * @param {string} [formID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentFormApi
     */
    public v1ConsentFormContentsGet(formID?: string, options?: AxiosRequestConfig) {
        return ConsentFormApiFp(this.configuration).v1ConsentFormContentsGet(formID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 동의서 생성
     * @param {ConsentFormModel} [consentFormModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentFormApi
     */
    public v1ConsentFormCreatePost(consentFormModel?: ConsentFormModel, options?: AxiosRequestConfig) {
        return ConsentFormApiFp(this.configuration).v1ConsentFormCreatePost(consentFormModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 동의서를 삭제합니다.
     * @param {string} formID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentFormApi
     */
    public v1ConsentFormDeleteFormIDPost(formID: string, options?: AxiosRequestConfig) {
        return ConsentFormApiFp(this.configuration).v1ConsentFormDeleteFormIDPost(formID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 동의서를 상태를 발행상태로 바꾸고  전체 동의서 대상자에게 메일을 발송합니다.
     * @param {string} formID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentFormApi
     */
    public v1ConsentFormDistributeFormIDPost(formID: string, options?: AxiosRequestConfig) {
        return ConsentFormApiFp(this.configuration).v1ConsentFormDistributeFormIDPost(formID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 새 버전 동의서를 생성합니다(동의서 복사).
     * @param {ConsentFormModel} [consentFormModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentFormApi
     */
    public v1ConsentFormDuplicatePost(consentFormModel?: ConsentFormModel, options?: AxiosRequestConfig) {
        return ConsentFormApiFp(this.configuration).v1ConsentFormDuplicatePost(consentFormModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Form ID를 기반으로 동의서 양식을 조회합니다.
     * @param {string} formID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentFormApi
     */
    public v1ConsentFormFormIDGet(formID: string, options?: AxiosRequestConfig) {
        return ConsentFormApiFp(this.configuration).v1ConsentFormFormIDGet(formID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 동의서 목록 조회
     * @param {ConsentFormRequestModel} [consentFormRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentFormApi
     */
    public v1ConsentFormListPost(consentFormRequestModel?: ConsentFormRequestModel, options?: AxiosRequestConfig) {
        return ConsentFormApiFp(this.configuration).v1ConsentFormListPost(consentFormRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 선택된 메일 수신자에게 메일을 발송합니다.  All : 전체 발송  Unresponsive : 미응답자 발송
     * @param {string} formID 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentFormApi
     */
    public v1ConsentFormNotificationSendFormIDPost(formID: string, body?: number, options?: AxiosRequestConfig) {
        return ConsentFormApiFp(this.configuration).v1ConsentFormNotificationSendFormIDPost(formID, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 동의서 수집 기간을 수정합니다.
     * @param {ConsentFormPeriodModel} [consentFormPeriodModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentFormApi
     */
    public v1ConsentFormPeriodUpdatePost(consentFormPeriodModel?: ConsentFormPeriodModel, options?: AxiosRequestConfig) {
        return ConsentFormApiFp(this.configuration).v1ConsentFormPeriodUpdatePost(consentFormPeriodModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 발송된 동의서 페이지에서 수신자만 추가 할 수 있는 함수입니다.  이미 발송된 동의서의 content는 수정 할 수 없으며 수신자 추가 혹은 수집일자 변경만 가능합니다.
     * @param {ConsentFormModel} [consentFormModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentFormApi
     */
    public v1ConsentFormRecipientsAddPost(consentFormModel?: ConsentFormModel, options?: AxiosRequestConfig) {
        return ConsentFormApiFp(this.configuration).v1ConsentFormRecipientsAddPost(consentFormModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 수신자 조회
     * @param {string} formID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentFormApi
     */
    public v1ConsentFormRecipientsFormIDGet(formID: string, options?: AxiosRequestConfig) {
        return ConsentFormApiFp(this.configuration).v1ConsentFormRecipientsFormIDGet(formID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 동의서를 수정합니다.
     * @param {ConsentFormModel} [consentFormModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentFormApi
     */
    public v1ConsentFormUpdatePost(consentFormModel?: ConsentFormModel, options?: AxiosRequestConfig) {
        return ConsentFormApiFp(this.configuration).v1ConsentFormUpdatePost(consentFormModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary User Consent View Handler  동의서 수신자 인쇄 팝업
     * @param {string} formID 
     * @param {string} userType 
     * @param {string} [userID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentFormApi
     */
    public v1ConsentFormUserConsentViewFormIDUserTypeGet(formID: string, userType: string, userID?: string, options?: AxiosRequestConfig) {
        return ConsentFormApiFp(this.configuration).v1ConsentFormUserConsentViewFormIDUserTypeGet(formID, userType, userID, options).then((request) => request(this.axios, this.basePath));
    }
}
