/* tslint:disable */
/* eslint-disable */
/**
 * Mik.SmartPortal.ConsentForm.App
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import type { RequestArgs } from '../base';
// @ts-ignore
import type { ApiResult } from '../models';
// @ts-ignore
import type { ConsentDetailModelApiResult } from '../models';
// @ts-ignore
import type { ConsentFormRequestModel } from '../models';
// @ts-ignore
import type { MyConsentModelConsentItemListInfoModelApiResult } from '../models';
// @ts-ignore
import type { MyConsentRequestModel } from '../models';
/**
 * MyConsentApi - axios parameter creator
 * @export
 */
export const MyConsentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 나에게 전송 된 동의서를 동의처리 합니다.
         * @param {MyConsentRequestModel} [myConsentRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MyConsentAgreePost: async (myConsentRequestModel?: MyConsentRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/MyConsent/Agree`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(myConsentRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 동의서 ID로 동의서를 가져옵니다.
         * @param {string} formID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MyConsentGetDetailFormIDPost: async (formID: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formID' is not null or undefined
            assertParamExists('v1MyConsentGetDetailFormIDPost', 'formID', formID)
            const localVarPath = `/v1/MyConsent/Get/Detail/{formID}`
                .replace(`{${"formID"}}`, encodeURIComponent(String(formID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 나의 동의서 목록을 가져옵니다.
         * @param {ConsentFormRequestModel} [consentFormRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MyConsentListPost: async (consentFormRequestModel?: ConsentFormRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/MyConsent/List`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(consentFormRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MyConsentApi - functional programming interface
 * @export
 */
export const MyConsentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MyConsentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 나에게 전송 된 동의서를 동의처리 합니다.
         * @param {MyConsentRequestModel} [myConsentRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MyConsentAgreePost(myConsentRequestModel?: MyConsentRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1MyConsentAgreePost(myConsentRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 동의서 ID로 동의서를 가져옵니다.
         * @param {string} formID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MyConsentGetDetailFormIDPost(formID: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsentDetailModelApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1MyConsentGetDetailFormIDPost(formID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 나의 동의서 목록을 가져옵니다.
         * @param {ConsentFormRequestModel} [consentFormRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MyConsentListPost(consentFormRequestModel?: ConsentFormRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyConsentModelConsentItemListInfoModelApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1MyConsentListPost(consentFormRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MyConsentApi - factory interface
 * @export
 */
export const MyConsentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MyConsentApiFp(configuration)
    return {
        /**
         * 
         * @summary 나에게 전송 된 동의서를 동의처리 합니다.
         * @param {MyConsentRequestModel} [myConsentRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MyConsentAgreePost(myConsentRequestModel?: MyConsentRequestModel, options?: any): AxiosPromise<ApiResult> {
            return localVarFp.v1MyConsentAgreePost(myConsentRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 동의서 ID로 동의서를 가져옵니다.
         * @param {string} formID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MyConsentGetDetailFormIDPost(formID: string, options?: any): AxiosPromise<ConsentDetailModelApiResult> {
            return localVarFp.v1MyConsentGetDetailFormIDPost(formID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 나의 동의서 목록을 가져옵니다.
         * @param {ConsentFormRequestModel} [consentFormRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MyConsentListPost(consentFormRequestModel?: ConsentFormRequestModel, options?: any): AxiosPromise<MyConsentModelConsentItemListInfoModelApiResult> {
            return localVarFp.v1MyConsentListPost(consentFormRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MyConsentApi - object-oriented interface
 * @export
 * @class MyConsentApi
 * @extends {BaseAPI}
 */
export class MyConsentApi extends BaseAPI {
    /**
     * 
     * @summary 나에게 전송 된 동의서를 동의처리 합니다.
     * @param {MyConsentRequestModel} [myConsentRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyConsentApi
     */
    public v1MyConsentAgreePost(myConsentRequestModel?: MyConsentRequestModel, options?: AxiosRequestConfig) {
        return MyConsentApiFp(this.configuration).v1MyConsentAgreePost(myConsentRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 동의서 ID로 동의서를 가져옵니다.
     * @param {string} formID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyConsentApi
     */
    public v1MyConsentGetDetailFormIDPost(formID: string, options?: AxiosRequestConfig) {
        return MyConsentApiFp(this.configuration).v1MyConsentGetDetailFormIDPost(formID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 나의 동의서 목록을 가져옵니다.
     * @param {ConsentFormRequestModel} [consentFormRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyConsentApi
     */
    public v1MyConsentListPost(consentFormRequestModel?: ConsentFormRequestModel, options?: AxiosRequestConfig) {
        return MyConsentApiFp(this.configuration).v1MyConsentListPost(consentFormRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}
