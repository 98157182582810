import {
  ConsentDetailModel,
  ConsentDetailModelApiResult,
  ConsentFormRequestModel,
  ConsentStatusModelConsentItemListInfoModel,
  ConsentStatusModelConsentItemListInfoModelApiResult,
  StatusApi,
  UserAuthorityConstant
} from 'mik-common/src/api-autogen/consentform'

import { AxiosResponse } from 'axios'

export const useStatusStore = defineStore('status', {
  state: () => {
    return {
      statusList: {} as ConsentStatusModelConsentItemListInfoModel,
      statusDetail: {} as ConsentDetailModel,
      searchOption: {
        isAdmin: true,
        pageNum: 1,
        pageSize: 15,
        searchKeyword: '',
        searchType: 'All',
        categoryType: undefined
      } as ConsentFormRequestModel,
      authLevel: 0 as UserAuthorityConstant
    }
  },
  getters: {
    isEdit(state): boolean {
      return state.authLevel >= UserAuthorityConstant.USER_AUTH_READWRITE
    },
    isAdmin(state): boolean {
      return state.authLevel >= UserAuthorityConstant.USER_AUTH_MANAGER
    }
  },
  actions: {
    async getStatusView(formID: string, recipientID: string) {
      const api = new StatusApi()
      const response: AxiosResponse<ConsentDetailModelApiResult> = await api.v1StatusGetDetailFormIDPost(
        formID,
        recipientID
      )
      if (response.data.success) {
        if (response.data.data?.consentFormInfo) {
          this.authLevel = response.data.userAuthority ?? UserAuthorityConstant.USER_AUTH_NONE
          Object.assign(this.statusDetail, response.data.data ?? {})
        } else {
          this.authLevel = UserAuthorityConstant.USER_AUTH_NONE
        }
      } else {
        this.authLevel = UserAuthorityConstant.USER_AUTH_NONE
      }
    },
    async getStatusList(request: ConsentFormRequestModel) {
      const api = new StatusApi()
      const response: AxiosResponse<ConsentStatusModelConsentItemListInfoModelApiResult> =
        await api.v1StatusListPost(request)

      if (response.data.success) {
        this.authLevel = response.data.userAuthority ?? UserAuthorityConstant.USER_AUTH_NONE
        Object.assign(this.statusList, response.data.data ?? {})
      } else {
        this.authLevel = UserAuthorityConstant.USER_AUTH_NONE
      }
    }
  }
})
