<template>
  <component :is="layout"> <router-view /> </component>

  <mik-user-profile-card
    v-model="mikUserProfileCardParam.visible"
    :user-id="mikUserProfileCardParam.userId"
    :position="mikUserProfileCardParam.position" />
</template>

<script setup lang="ts">
import Default from '../layouts/Default.vue'
import Empty from '../layouts/Empty.vue'
import Popup from '../layouts/Popup.vue'
import { useRoute } from 'vue-router'
import { useModeStore } from 'mik-common/src/store/mode'

// 커뮤니티 레이아웃
import CommunityLayoutCommunity from 'mik-community/src/layouts/Community.vue'
import { useMikUserProfileCard } from 'mik-common/src/composables/MikUserProfileCard'

const layout = ref()
const route = useRoute()
const store = useModeStore()
const { param: mikUserProfileCardParam } = useMikUserProfileCard()

onBeforeMount(() => {
  //console.log('Current path: ', route.path)
  //console.log('Route meta:', route.meta)
})

watch(
  () => route,
  async (newRoute) => {
    switch (newRoute.meta.baseUrl) {
      // 커뮤니티 전용 레이아웃
      case import.meta.env.V_COMMUNITY_PATH.toLowerCase():
        try {
          switch (newRoute.meta.layout) {
            // 커뮤니티 개별
            case 'Community':
              layout.value = markRaw(CommunityLayoutCommunity)
              break
            // 그 외
            default:
              layout.value = markRaw(Default)
              break
          }
        } catch (e) {
          layout.value = markRaw(Default)
        }
        break
      // 전체 공통
      default:
        try {
          if (newRoute.path == '/' || store.isEmptyMode) {
            layout.value = markRaw(Empty)
          } else if (store.isPopup) {
            layout.value = markRaw(Popup)
          } else {
            layout.value = markRaw(Default)
          }
        } catch (e) {
          layout.value = markRaw(Empty)
        }
        break
    }
  },
  { deep: true }
)
</script>
