<template>
  <common-popup v-model="isOpenPopup" size="s" style="width: 100px !important" class="profile_image_change">
    <div class="live-demo">
      <q-file
        v-model="imgFile"
        outlined
        dense
        hide-details
        class="q-mb-md"
        label="Browse files..."
        accept="image/*"
        @input="selectFile" />

      <div v-if="isShowModal" class="modal-wrap">
        <div class="modal-mask"></div>
        <div class="modal-scroll-view">
          <div class="modal">
            <div class="modal-title">
              <div class="tools">
                <mik-button
                  class="btn"
                  outline
                  color="white"
                  text-color="secondary"
                  :label="$t('Common_Form_Button_Cancel')"
                  @click.prevent="isShowModal = false" />
                <mik-button class="btn" :label="$t('Common_Form_Button_Setting')" @click.prevent="getResult" />
              </div>
            </div>

            <div class="modal-content">
              <VuePictureCropper
                :box-style="{
                  width: '100%',
                  height: '100%',
                  backgroundColor: '#f8f8f8',
                  margin: 'auto'
                }"
                :img="pic"
                :options="{
                  viewMode: 1,
                  dragMode: 'move',
                  aspectRatio: props.ratio,
                  cropBoxResizable: props.resizable
                }"
                :preset-mode="{
                  mode: 'fixedSize',
                  height: props.height,
                  width: props.width
                }" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </common-popup>
</template>

<script setup lang="ts">
import VuePictureCropper, { cropper } from 'vue-picture-cropper'

/** Get parameter from Parent component */
const props = defineProps<{
  modelValue: File | undefined | null
  currentStatus: boolean
  base64String: string
  resizable: boolean
  ratio: number
  width: number
  height: number
}>()

/** 영역 설정 modal display */
const isShowModal = ref(false)
const imgFile: Ref<File | undefined | null> = ref()
watch(imgFile, (file) => {
  emit('update:modelValue', file)
})

const pic = ref('')
const result = reactive({
  dataURL: ''
})

const selectFile = (e: Event) => {
  // Reset last selection and results
  pic.value = ''
  result.dataURL = ''

  // Get selected files
  const { files } = e.target as HTMLInputElement
  if (!files || !files.length) return

  // Convert to dataURL and pass to the cropper component
  const file = files[0]
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => {
    // Update the picture source of the `img` prop
    pic.value = String(reader.result)
    // Show the modal
    isShowModal.value = true
  }
}

const getResult = async () => {
  if (!cropper) return
  const opt = {
    minWidth: 1024,
    minHeight: 1024,
    maxWidth: 4000,
    maxHeight: 4000,
    imageSmoothingEnabled: true,
    imageSmoothingQuality: 'high'
  }
  const base64 = cropper.getDataURL(opt)
  const blob: Blob | null = await cropper.getBlob()
  if (!blob) return

  result.dataURL = base64
  isShowModal.value = false
  isOpenPopup.value = false
}

/** [Total] 현재 Popup */
const isOpenPopup = computed({
  get() {
    return props.currentStatus
  },
  set(value) {
    emit('update:currentStatus', value)
  }
})

/** Props와 Result 상호간 관계가 있으므로 동기화 */
watch(result, (e) => {
  if (e.dataURL !== '') {
    emit('update:base64-string', e.dataURL)
  }
})

const emit = defineEmits<{
  (e: 'update:modelValue', value: File | undefined | null): void
  (e: 'update:currentStatus', value: boolean): void
  (e: 'update:base64-string', value: string): void
}>()
</script>

<style scoped>
.modal-wrap .modal-title .tools {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
}
.modal-wrap .modal-title .tools .btn {
  margin: 5px;
}
</style>
