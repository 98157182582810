import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'

import { useCommunityStore } from 'mik-community/src/store/community'
import { CommunityApi } from 'mik-common/src/api-autogen/community'
import { findBoardByBoardUrl } from 'mik-community/src/utils/util'

const guards = async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const store = useCommunityStore()
  let isInit = false
  // Front 페이지는 바로 이동
  if (to.meta.layout == 'Default') {
    next()
    return
  }

  // store 정보가 없는 경우
  const api = new CommunityApi()
  if (!store.communityInfo.communityId || store.communityInfo.relativeUrl !== to.params.communityUrl) {
    isInit = true
    await store.initCommunity(to.params.communityUrl.toString())
    if (to.name === 'Community.Home.Board') {
      // 게시판으로 이동하는 경우
      const boardId = findBoardByBoardUrl(store.boardLayoutInfo.boardLayoutList ?? [], history.state.boardUrl)
      if (boardId) api.v1CommunityVisitCommunityIdMikBoardIdPost(store.communityInfo.communityId ?? '', boardId)
      else api.v1CommunityVisitCommunityIdPost(store.communityInfo.communityId ?? '')
    } else {
      api.v1CommunityVisitCommunityIdPost(store.communityInfo.communityId ?? '')
    }
  }

  if (!isInit) {
    // 게시판 접근 시 로깅
    if (to.path === from.path && to.name === from.name && to.name === 'Community.Home.Board') {
      // do nothing
    } else if (to.name === 'Community.Home.Board') {
      const boardId = findBoardByBoardUrl(store.boardLayoutInfo.boardLayoutList ?? [], history.state.boardUrl)
      if (boardId) api.v1CommunityVisitCommunityIdMikBoardIdPost(store.communityInfo.communityId ?? '', boardId)
    }
  }

  // 관리 페이지는 권한 체크
  if (to.meta.communityManage) {
    if (store.isAdmin) next()
    else next('/Error/401')
  } else {
    next()
  }
}

export default guards
