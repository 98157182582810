<template>
  <div class="front_popup">
    <div class="board_write">
      <!-- table -->
      <div class="tbl_horiz">
        <table>
          <colgroup>
            <col width="145" />
            <col />
          </colgroup>
          <tbody>
            <tr class="tbl_row">
              <th class="tbl_head">
                <span class="asterisk">
                  <i18n-t keypath="Community_Div_Name" tag="span">
                    {{ $t('Community_Div_Community') }}
                  </i18n-t>
                </span>
              </th>
              <td class="tbl_data">
                <!--중복검사-->
                <div class="duplication_check">
                  {{ props.communityInfo?.title }}
                </div>
              </td>
            </tr>
            <tr class="tbl_row">
              <th class="tbl_head">
                <i18n-t keypath="Community_Div_Description" tag="span" class="asterisk">
                  {{ $t('Community_Div_Community') }}
                </i18n-t>
              </th>
              <td class="tbl_data">
                {{ props.communityInfo?.description }}
              </td>
            </tr>

            <!--회원수-->
            <tr class="tbl_row">
              <th class="tbl_head">
                {{ $t('MemberCount') }}
              </th>
              <td class="tbl_data">
                {{ props.communityInfo?.memberCount }}
              </td>
            </tr>

            <!--게시글 수-->
            <tr class="tbl_row">
              <th class="tbl_head">
                {{ $t('PostsCount') }}
              </th>
              <td class="tbl_data">{{ props.communityInfo?.boardItemCount ?? 0 }}</td>
            </tr>

            <!-- 커뮤니티 아이콘 등록-->
            <tr class="tbl_row">
              <th class="tbl_head">{{ $t('Icon') }}</th>
              <td class="tbl_data">
                <div class="profile_photo">
                  <div class="photo_area">
                    <img
                      v-show="isShowThumbnail"
                      id="uploadImage"
                      :src="props.communityInfo?.bannerImageUrl ?? ''"
                      data-image-buffer=""
                      alt=""
                      width="120px"
                      height="120px"
                      @error="handleThumnailBaseImageUrl" />
                    <img v-show="!isShowThumbnail" :src="defaultImg" width="120px" height="120px" />
                  </div>
                </div>
              </td>
            </tr>
            <!-- 요청사유-->
            <tr class="tbl_row">
              <th class="tbl_head">
                <span class="required-field-span">* </span>
                {{ $t('RequestReason', '요청사유') }}
              </th>
              <td class="tbl_data">
                <textarea v-model="joinIntention" style="width: 100%; min-height: 80px"></textarea>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- //table -->
    </div>
    <div class="sect_button">
      <div class="right_area">
        <!-- 용도를 알수 없어 주석처리. 추후 기능 필요 시 주석 해제 -->
        <!-- <mik-button :click-function="handlePrint" :button-txt="$t('Printing')" /> -->
        <mik-button
          :click-function="handleCancel"
          :button-txt="$t('Common_Form_Button_Cancel')"
          :button-type="'secondary'" />
        <mik-button :click-function="handleRequest" :button-txt="$t('JoinRequest')" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, reactive } from 'vue'
import { CommunityInfoModel } from 'mik-common/src/api-autogen/community/models/community-info-model'
import { JoinApi, RequestJoinMemberModel } from 'mik-common/src/api-autogen/community'
import { useToast } from 'mik-community/src/common/toast'
import defaultImg from 'mik-community/src/assets/images/community_default.png'

const api = new JoinApi()
const toast = useToast()
const { t } = useI18n()
/** 요청 사유 */
const joinIntention = ref('')

const props = defineProps<{
  communityInfo: CommunityInfoModel | null
}>()

/** 가입할 커뮤니티 정보 */
const state = reactive({
  joinInfo: props.communityInfo as CommunityInfoModel[]
})

/** Cancel */
const handleCancel = () => {
  emits('on-hide-popup')
}
/** 가입요청 */
const handleRequest = () => {
  const param: RequestJoinMemberModel = {
    description: joinIntention.value
  }

  api.v1JoinApplicationPost(props.communityInfo?.communityId, param).then((response) => {
    toast.info(t('Community_Member_Join_Success'))

    emits('on-join-request-popup')
  })
}
/** 인쇄 화면 */
const handlePrint = () => {
  window.print()
}

/** 404오류 발생시 기본이미지 교체 */
const isShowThumbnail = ref(true)
//: 기본이미지는 개설신청시 thumbnail image를 파일저장소에 업로드 하지 않기 때문에 404가 발생한다.
const handleThumnailBaseImageUrl = (event: any) => {
  isShowThumbnail.value = false
}
const emits = defineEmits<{
  (e: 'on-hide-popup'): void
  (e: 'on-join-request-popup'): void
}>()
</script>

<style scoped></style>
