import type { RouteLocationNormalized } from 'vue-router'

const routers = [
  {
    path: '/',
    redirect: 'Guide'
  },
  {
    name: 'Guide',
    path: '/Guide',
    component: () => import('mik-survey/src/pages/user/Guide.vue')
  },
  // children: [
  //   {
  //     path: "/group/information",
  //     name: "group.information",
  //     component: () => import("@/pages/group/GroupInformation.vue"),
  //   },
  // ],
  // {
  //   name: 'Main',
  //   path: '/Main',
  //   component: Main
  // },
  {
    name: 'List',
    path: '/List',
    redirect: { name: 'List.InProgress' },
    children: [
      {
        name: 'List.InProgress',
        path: '/List/InProgress',
        component: () => import('mik-survey/src/pages/user/list/InProgress.vue')
      },
      {
        name: 'List.Draft',
        path: '/List/Draft',
        component: () => import('mik-survey/src/pages/user/list/Draft.vue')
      },
      {
        name: 'List.Completed',
        path: '/List/Completed',
        component: () => import('mik-survey/src/pages/user/list/Completed.vue')
      },
      {
        name: 'List.Template',
        path: '/List/Template',
        component: () => import('mik-survey/src/pages/user/list/Template.vue')
      }
    ]
  },
  {
    name: 'View',
    path: '/View',
    children: [
      {
        name: 'View.View',
        path: '/View/:surveyID',
        component: () => import('mik-survey/src/pages/user/view/View.vue'),
        meta: {
          getBaseUrl: (route: RouteLocationNormalized) => `/Guide`
        }
      },
      {
        name: 'View.View.Content',
        path: '/View/:surveyID/Content',
        component: () => import('mik-survey/src/pages/user/view/components/Content.vue')
      },
      {
        name: 'View.Preview',
        path: '/View/Preview/:surveyID',
        component: () => import('mik-survey/src/pages/user/view/Preview.vue')
      },
      {
        name: 'View.Result',
        path: '/View/Result/:surveyID',
        component: () => import('mik-survey/src/pages/user/view/Result.vue'),
        meta: {
          getBaseUrl: (route: RouteLocationNormalized) => `/Guide`
        }
      },
      {
        name: 'View.Answer',
        path: '/View/Answer/:surveyID',
        component: () => import('mik-survey/src/pages/user/view/Answer.vue'),
        meta: {
          getBaseUrl: (route: RouteLocationNormalized) => `/List/InProgress`
        }
      }
    ]
  },
  {
    name: 'Registration',
    path: '/Registration',
    redirect: { name: 'Registration.Survey', params: { surveyID: '00000000-0000-0000-0000-000000000000' } },
    children: [
      {
        path: '/Registration/Survey',
        redirect: { name: 'Registration.Survey', params: { surveyID: '00000000-0000-0000-0000-000000000000' } }
      },
      {
        name: 'Registration.Survey',
        path: '/Registration/Survey/:surveyID',
        component: () => import('mik-survey/src/pages/user/registration/Survey.vue'),
        meta: {
          getBaseUrl: (route: RouteLocationNormalized) => `/Registration/Survey`
          // getBaseUrl: ((route: RouteLocationNormalized) =>
          //   `/Registration/Survey/${route.params.surveyID}`) as type
        }
      },
      {
        name: 'Registration.Question',
        path: '/Registration/Question/:surveyID',
        component: () => import('mik-survey/src/pages/user/registration/Question.vue'),
        meta: {
          getBaseUrl: (route: RouteLocationNormalized) => `/Registration/Survey`
        }
      },
      {
        name: 'Registration.Launch',
        path: '/Registration/Launch/:surveyID',
        component: () => import('mik-survey/src/pages/user/registration/Launch.vue'),
        meta: {
          getBaseUrl: (route: RouteLocationNormalized) => `/Registration/Survey`
        }
      }
    ]
  }
]

export default routers
