import {
  CategoryApi,
  ConsentDetailModel,
  ConsentFormCategoryModel,
  ConsentFormCategoryModelListApiResult,
  UserAuthorityConstant
} from 'mik-common/src/api-autogen/consentform'

import { AxiosResponse } from 'axios'

export const useCategoryStore = defineStore('category', {
  state: () => {
    return {
      categoryList: {} as ConsentFormCategoryModel[],
      authLevel: 0 as UserAuthorityConstant
    }
  },
  getters: {
    isEdit(state): boolean {
      return state.authLevel >= UserAuthorityConstant.USER_AUTH_READWRITE
    },
    isAdmin(state): boolean {
      return state.authLevel >= UserAuthorityConstant.USER_AUTH_MANAGER
    }
  },
  actions: {
    async getCategoryList() {
      const api = new CategoryApi()
      const response: AxiosResponse<ConsentFormCategoryModelListApiResult> = await api.v1CategoryListGet()
      if (response.data.success) {
        this.categoryList = response.data.data ?? []
        this.authLevel = response.data.userAuthority ?? UserAuthorityConstant.USER_AUTH_NONE
      } else {
        this.authLevel = UserAuthorityConstant.USER_AUTH_NONE
      }
    }
  }
})
