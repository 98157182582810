import {
  ConsentDetailModel,
  ConsentDetailModelApiResult,
  ConsentFormRequestModel,
  MyConsentApi,
  MyConsentModelConsentItemListInfoModel,
  UserAuthorityConstant
} from 'mik-common/src/api-autogen/consentform'

import { AxiosResponse } from 'axios'

export const useMyConsentFormStore = defineStore('myconsentform', {
  state: () => {
    return {
      myConsentList: {} as MyConsentModelConsentItemListInfoModel,
      myConsent: {} as ConsentDetailModel,
      searchOption: {
        isAdmin: false,
        pageNumber: 1,
        pageSize: 15,
        searchKeyword: '',
        searchType: 'All'
      } as ConsentFormRequestModel,
      authLevel: 0 as UserAuthorityConstant
    }
  },
  getters: {
    isEdit(state): boolean {
      return state.authLevel >= UserAuthorityConstant.USER_AUTH_READWRITE
    },
    isAdmin(state): boolean {
      return state.authLevel >= UserAuthorityConstant.USER_AUTH_MANAGER
    }
  },
  actions: {
    async getMyConsentView(formID: string | undefined) {
      const myApi = new MyConsentApi()
      const response: AxiosResponse<ConsentDetailModelApiResult> = await myApi.v1MyConsentGetDetailFormIDPost(
        formID ?? ''
      )
      if (response.data.success) {
        if (response.data.data?.myConsentInfo?.userID) {
          this.authLevel = response.data.userAuthority ?? UserAuthorityConstant.USER_AUTH_NONE
          Object.assign(this.myConsent, response.data.data ?? {})
        } else {
          this.authLevel = UserAuthorityConstant.USER_AUTH_NONE
        }
      } else {
        this.authLevel = UserAuthorityConstant.USER_AUTH_NONE
      }
    },
    async getMyConsentList(request: ConsentFormRequestModel) {
      const myApi = new MyConsentApi()
      const response = await myApi.v1MyConsentListPost(request)

      if (response.data.success) {
        this.authLevel = response.data.userAuthority ?? UserAuthorityConstant.USER_AUTH_NONE
        Object.assign(this.myConsentList, response.data.data ?? {})
      } else {
        this.authLevel = UserAuthorityConstant.USER_AUTH_NONE
      }
    }
  }
})
