const routers = [
  {
    name: 'Front',
    path: '/Front',
    children: [
      {
        name: 'Front.Main',
        path: '/Front/:subportalID',
        component: () => import('mik-subportal/src/pages/front/Main.vue')
        // meta: {
        //   getBaseUrl: (route: RouteLocationNormalized) => ``
        // }
      }
    ]
  },
  {
    name: 'Management',
    path: '/Management',
    children: [
      {
        name: 'Management.Main.Edit',
        path: '/Management/Main/:subportalID/Edit',
        component: () => import('mik-subportal/src/pages/management/main/Edit.vue')
        // meta: {
        //   getBaseUrl: (route: RouteLocationNormalized) => ``
        // }
      },
      {
        name: 'Management.Webpart.Edit',
        path: '/Management/Webpart/:subportalID/Edit',
        component: () => import('mik-subportal/src/pages/management/webpart/Edit.vue')
        // meta: {
        //   getBaseUrl: (route: RouteLocationNormalized) => ``
        // }
      }
    ]
  }
]

export default routers
