/* tslint:disable */
/* eslint-disable */
/**
 * Mik.SmartPortal.ConsentForm.App
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const UserAuthorityConstant = {
    USER_AUTH_NONE: 0,
    USER_AUTH_READONLY: 10,
    USER_AUTH_COMMENT_WRITE: 20,
    USER_AUTH_READWRITE: 30,
    USER_AUTH_MANAGER: 70
} as const;

export type UserAuthorityConstant = typeof UserAuthorityConstant[keyof typeof UserAuthorityConstant];



