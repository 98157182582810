const routers = [
  // 프로젝트 루트 => 사옥관리
  {
    path: '/',
    redirect: { name: 'meetingRoom' }
  },
  // 회의실 예약
  {
    path: '/user/meetingRoom',
    name: 'meetingRoom',
    component: () => import('mik-reservation/src/pages/user/meeting-room/ReservationTimeTable.vue')
  },
  // 회의실 예약 상세
  {
    path: '/user/meetingRoom/detail',
    name: 'meetingRoomReservationDetail',
    component: () => import('mik-reservation/src/pages/user/meeting-room/ReservationDetail.vue')
  },
  // 예약가능 회의실
  {
    path: '/user/meetingRoom/reservationAvailable',
    name: 'meetingRoomReservationAvailable',
    component: () => import('mik-reservation/src/pages/user/meeting-room/ReservationAvailable.vue')
  },
  // 회의실 예약내역 조회
  {
    path: '/user/meetingRoom/reservationList',
    name: 'meetingRoomReservationList',
    component: () => import('mik-reservation/src/pages/user/meeting-room/ReservationList.vue')
  },
  // 장비 예약
  {
    path: '/user/equipment',
    name: 'equipment',
    component: () => import('mik-reservation/src/pages/user/equipment/ReservationTimeTable.vue')
  },
  // 장비 예약 상세
  {
    path: '/user/equipment/detail',
    name: 'equipmentReservationDetail',
    component: () => import('mik-reservation/src/pages/user/equipment/ReservationDetail.vue')
  },
  // 장비 예약내역 조회
  {
    path: '/user/equipment/reservationList',
    name: 'equipmentReservationList',
    component: () => import('mik-reservation/src/pages/user/equipment/ReservationList.vue')
  },
  // 회의실 예약 관리 --> 회의실 예약 현황 관리
  {
    path: '/contents-management/meetingRoomReservation',
    name: 'meetingRoomReservationManagement',
    component: () => import('mik-reservation/src/pages/contents-management/MeetingRoomReservation.vue')
  },
  // 장비 예약 관리 --> 장비 예약 현황 관리
  {
    path: '/contents-management/equipmentReservation',
    name: 'equipmentReservationManagement',
    component: () => import('mik-reservation/src/pages/contents-management/EquipmentReservation.vue')
  },
  // 나의 회의실 예약 관리
  {
    path: '/contents-management/myMeetingRoomReservation',
    name: 'myMeetingRoomReservation',
    component: () => import('mik-reservation/src/pages/contents-management/MyMeetingRoomReservation.vue')
  },
  // 나의 장비 예약 관리
  {
    path: '/contents-management/myEquipmentReservation',
    name: 'myEquipmentReservation',
    component: () => import('mik-reservation/src/pages/contents-management/MyEquipmentReservation.vue')
  },
  // 사옥관리
  {
    path: '/system-management/buildingList',
    name: 'buildingList',
    component: () => import('mik-reservation/src/pages/system-management/BuildingList.vue'),
    meta: {
      tenantSelector: true
    }
  },
  // 사옥 층 관리
  {
    path: '/system-management/buildingFloorList',
    name: 'buildingFloorList',
    component: () => import('mik-reservation/src/pages/system-management/BuildingFloorList.vue'),
    meta: {
      tenantSelector: true
    }
  },
  // 회의실 관리
  {
    path: '/system-management/meetingRoomList',
    name: 'meetingRoomList',
    component: () => import('mik-reservation/src/pages/system-management/MeetingRoomList.vue'),
    meta: {
      tenantSelector: true
    }
  },
  // 카테고리 관리
  {
    path: '/system-management/reservationCategory',
    name: 'categoryManagemnet',
    component: () => import('mik-reservation/src/pages/system-management/ReservationCategory.vue'),
    meta: {
      tenantSelector: true
    }
  },
  // 담당자 관리
  {
    path: '/system-management/buildingManager',
    name: 'buildingManager',
    component: () => import('mik-reservation/src/pages/system-management/BuildingManager.vue'),
    meta: {
      tenantSelector: true
    }
  },
  // 장비 관리
  {
    path: '/system-management/equipmentList',
    name: 'equipmentList',
    component: () => import('mik-reservation/src/pages/system-management/EquipmentList.vue'),
    meta: {
      tenantSelector: true
    }
  },
  // 부서별 회의실 예약 통계
  {
    path: '/system-management/meetingRoomStatisticsDept',
    name: 'meetingRoomStatisticsDept',
    component: () => import('mik-reservation/src/pages/system-management/MeetingRoomStatisticsDept.vue'),
    meta: {
      tenantSelector: true
    }
  },
  // 사용자별 회의실 예약 통계
  {
    path: '/system-management/meetingRoomStatisticsUser',
    name: 'meetingRoomStatisticsUser',
    component: () => import('mik-reservation/src/pages/system-management/MeetingRoomStatisticsUser.vue'),
    meta: {
      tenantSelector: true
    }
  },
  // 사옥별 회의실 예약 통계
  {
    path: '/system-management/meetingRoomStatisticsBuilding',
    name: 'meetingRoomStatisticsBuilding',
    component: () => import('mik-reservation/src/pages/system-management/MeetingRoomStatisticsBuilding.vue'),
    meta: {
      tenantSelector: true
    }
  },
  // 회의실별 예약 통계
  {
    path: '/system-management/meetingRoomStatisticsRoom',
    name: 'meetingRoomStatisticsRoom',
    component: () => import('mik-reservation/src/pages/system-management/MeetingRoomStatisticsRoom.vue'),
    meta: {
      tenantSelector: true
    }
  }
]

export default routers
