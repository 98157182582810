import { useCategoryStore } from 'mik-consentform/src/store/category'
import { useConsentFormStore } from 'mik-consentform/src/store/consentForm'
import { useMyConsentFormStore } from 'mik-consentform/src/store/myConsentForm'
import { useStatusStore } from 'mik-consentform/src/store/status'
import { UserAuthorityConstant } from 'mik-common/src/api-autogen/consentform/models/user-authority-constant'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

const guards = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
  useRouterMerge: boolean
) => {
  if (to.name === 'ManagementEdit') {
    const formID: string = to.query?.formID as string
    const store = useConsentFormStore()

    await store.getContentsHtml(formID)
    if (!store.hasContent) {
      next({
        path: '/NotFound'
      })
    }
    // 관리자, 동의서 관리자가 아닌경우 권한없음
    if (store.authLevel < UserAuthorityConstant.USER_AUTH_MANAGER) {
      next({
        path: '/Error/401'
      })
    } else {
      next()
    }
  } else if (to.name === 'ManagementList') {
    const store = useConsentFormStore()
    await store.getConsentFormList(store.searchOption)
    if (store.authLevel < UserAuthorityConstant.USER_AUTH_MANAGER) {
      next({
        path: '/Error/401'
      })
    } else {
      next()
    }
  } else if (to.name === 'ManagementView') {
    const formID = to.params.formID as string
    if (!formID) {
      next({
        path: 'NotFound'
      })
    }
    const store = useConsentFormStore()
    await store.getConsentFormView(formID)
    if (store.authLevel < UserAuthorityConstant.USER_AUTH_MANAGER) {
      next({
        path: '/Error/401'
      })
    } else {
      next()
    }
  } else if (to.name === 'ManagementStatusList') {
    const store = useStatusStore()
    await store.getStatusList(store.searchOption)
    if (store.authLevel < UserAuthorityConstant.USER_AUTH_MANAGER) {
      next({
        path: '/Error/401'
      })
    } else {
      next()
    }
  } else if (to.name === 'ManagementStatusView') {
    const formID = to.params.formID as string
    const recipientID = to.params.recipientID as string
    const store = useStatusStore()
    await store.getStatusView(formID, recipientID)
    if (store.authLevel < UserAuthorityConstant.USER_AUTH_MANAGER) {
      next({
        path: '/Error/401'
      })
    } else {
      next()
    }
  } else if (to.name === 'CategoryManagement') {
    const store = useCategoryStore()
    await store.getCategoryList()
    if (store.authLevel < UserAuthorityConstant.USER_AUTH_MANAGER) {
      next({
        path: '/Error/401'
      })
    } else {
      next()
    }
  } else if (to.name === 'MyConsentList') {
    const store = useMyConsentFormStore()
    await store.getMyConsentList(store.searchOption)
    if (store.authLevel < UserAuthorityConstant.USER_AUTH_READWRITE) {
      next({
        path: '/Error/401'
      })
    } else {
      next()
    }
  } else if (to.name === 'MyConsentView') {
    const formID: string = to.query?.formID as string
    const store = useMyConsentFormStore()

    if (!formID) {
      next({
        path: 'NotFound'
      })
    }
    await store.getMyConsentView(formID)
    if (store.authLevel < UserAuthorityConstant.USER_AUTH_READWRITE) {
      next({
        path: '/Error/401'
      })
    } else {
      next()
    }
  } else if (!useRouterMerge) {
    if (to.name) {
      next()
    } else {
      next(false)
    }
  }
}

export default guards
