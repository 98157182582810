/* tslint:disable */
/* eslint-disable */
/**
 * Mik.SmartPortal.Portal.App
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import type { RequestArgs } from '../base';
// @ts-ignore
import type { ApiResult } from '../models';
// @ts-ignore
import type { BoardInfoModelApiResult } from '../models';
// @ts-ignore
import type { BooleanApiResult } from '../models';
// @ts-ignore
import type { GalleryContentsClientDataModel } from '../models';
// @ts-ignore
import type { GalleryPostInfoModelApiResult } from '../models';
// @ts-ignore
import type { GalleryPostInfoModelItemListInfoModelApiResult } from '../models';
// @ts-ignore
import type { GuidApiResult } from '../models';
/**
 * GalleryPostApi - axios parameter creator
 * @export
 */
export const GalleryPostApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary staticName으로 작성화면의 설정부분을 조회합니다.
         * @param {string} boardStaticName 
         * @param {string} [postId] 
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GalleryPostBoardStaticNameEditinfoGet: async (boardStaticName: string, postId?: string, langCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1GalleryPostBoardStaticNameEditinfoGet', 'boardStaticName', boardStaticName)
            const localVarPath = `/v1/gallery/post/{boardStaticName}/Editinfo`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (postId !== undefined) {
                localVarQueryParameter['postId'] = postId;
            }

            if (langCode !== undefined) {
                localVarQueryParameter['langCode'] = langCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 게시글 목록을 조회합니다.
         * @param {string} boardStaticName 
         * @param {string} [databaseId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {number} [pageNumCount] 
         * @param {string} [searchType] 
         * @param {string} [searchKeyword] 
         * @param {string} [folderId] 
         * @param {string} [langCode] 
         * @param {string} [searchPeriod] 
         * @param {string} [datePeriod] 
         * @param {string} [fieldInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GalleryPostBoardStaticNameGet: async (boardStaticName: string, databaseId?: string, page?: number, size?: number, pageNumCount?: number, searchType?: string, searchKeyword?: string, folderId?: string, langCode?: string, searchPeriod?: string, datePeriod?: string, fieldInfo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1GalleryPostBoardStaticNameGet', 'boardStaticName', boardStaticName)
            const localVarPath = `/v1/gallery/post/{boardStaticName}`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (databaseId !== undefined) {
                localVarQueryParameter['databaseId'] = databaseId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (pageNumCount !== undefined) {
                localVarQueryParameter['pageNumCount'] = pageNumCount;
            }

            if (searchType !== undefined) {
                localVarQueryParameter['searchType'] = searchType;
            }

            if (searchKeyword !== undefined) {
                localVarQueryParameter['searchKeyword'] = searchKeyword;
            }

            if (folderId !== undefined) {
                localVarQueryParameter['folderId'] = folderId;
            }

            if (langCode !== undefined) {
                localVarQueryParameter['langCode'] = langCode;
            }

            if (searchPeriod !== undefined) {
                localVarQueryParameter['searchPeriod'] = searchPeriod;
            }

            if (datePeriod !== undefined) {
                localVarQueryParameter['datePeriod'] = datePeriod;
            }

            if (fieldInfo !== undefined) {
                localVarQueryParameter['fieldInfo'] = fieldInfo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 게시글을 등록합니다.
         * @param {string} boardStaticName 
         * @param {string} [databaseId] 
         * @param {GalleryContentsClientDataModel} [galleryContentsClientDataModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GalleryPostBoardStaticNamePost: async (boardStaticName: string, databaseId?: string, galleryContentsClientDataModel?: GalleryContentsClientDataModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1GalleryPostBoardStaticNamePost', 'boardStaticName', boardStaticName)
            const localVarPath = `/v1/gallery/post/{boardStaticName}`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (databaseId !== undefined) {
                localVarQueryParameter['databaseId'] = databaseId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(galleryContentsClientDataModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 게시글을 삭제합니다.
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {string} [databaseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GalleryPostBoardStaticNamePostIdDelete: async (boardStaticName: string, postId: string, databaseId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1GalleryPostBoardStaticNamePostIdDelete', 'boardStaticName', boardStaticName)
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('v1GalleryPostBoardStaticNamePostIdDelete', 'postId', postId)
            const localVarPath = `/v1/gallery/post/{boardStaticName}/{postId}`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)))
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (databaseId !== undefined) {
                localVarQueryParameter['databaseId'] = databaseId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 게시글을 조회합니다.
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {string} [databaseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GalleryPostBoardStaticNamePostIdGet: async (boardStaticName: string, postId: string, databaseId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1GalleryPostBoardStaticNamePostIdGet', 'boardStaticName', boardStaticName)
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('v1GalleryPostBoardStaticNamePostIdGet', 'postId', postId)
            const localVarPath = `/v1/gallery/post/{boardStaticName}/{postId}`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)))
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (databaseId !== undefined) {
                localVarQueryParameter['databaseId'] = databaseId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 게시글을 수정합니다.
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {string} [databaseId] 
         * @param {GalleryContentsClientDataModel} [galleryContentsClientDataModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GalleryPostBoardStaticNamePostIdPut: async (boardStaticName: string, postId: string, databaseId?: string, galleryContentsClientDataModel?: GalleryContentsClientDataModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1GalleryPostBoardStaticNamePostIdPut', 'boardStaticName', boardStaticName)
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('v1GalleryPostBoardStaticNamePostIdPut', 'postId', postId)
            const localVarPath = `/v1/gallery/post/{boardStaticName}/{postId}`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)))
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (databaseId !== undefined) {
                localVarQueryParameter['databaseId'] = databaseId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(galleryContentsClientDataModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GalleryPostApi - functional programming interface
 * @export
 */
export const GalleryPostApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GalleryPostApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary staticName으로 작성화면의 설정부분을 조회합니다.
         * @param {string} boardStaticName 
         * @param {string} [postId] 
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1GalleryPostBoardStaticNameEditinfoGet(boardStaticName: string, postId?: string, langCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoardInfoModelApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1GalleryPostBoardStaticNameEditinfoGet(boardStaticName, postId, langCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 게시글 목록을 조회합니다.
         * @param {string} boardStaticName 
         * @param {string} [databaseId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {number} [pageNumCount] 
         * @param {string} [searchType] 
         * @param {string} [searchKeyword] 
         * @param {string} [folderId] 
         * @param {string} [langCode] 
         * @param {string} [searchPeriod] 
         * @param {string} [datePeriod] 
         * @param {string} [fieldInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1GalleryPostBoardStaticNameGet(boardStaticName: string, databaseId?: string, page?: number, size?: number, pageNumCount?: number, searchType?: string, searchKeyword?: string, folderId?: string, langCode?: string, searchPeriod?: string, datePeriod?: string, fieldInfo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GalleryPostInfoModelItemListInfoModelApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1GalleryPostBoardStaticNameGet(boardStaticName, databaseId, page, size, pageNumCount, searchType, searchKeyword, folderId, langCode, searchPeriod, datePeriod, fieldInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 게시글을 등록합니다.
         * @param {string} boardStaticName 
         * @param {string} [databaseId] 
         * @param {GalleryContentsClientDataModel} [galleryContentsClientDataModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1GalleryPostBoardStaticNamePost(boardStaticName: string, databaseId?: string, galleryContentsClientDataModel?: GalleryContentsClientDataModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1GalleryPostBoardStaticNamePost(boardStaticName, databaseId, galleryContentsClientDataModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 게시글을 삭제합니다.
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {string} [databaseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1GalleryPostBoardStaticNamePostIdDelete(boardStaticName: string, postId: string, databaseId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1GalleryPostBoardStaticNamePostIdDelete(boardStaticName, postId, databaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 게시글을 조회합니다.
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {string} [databaseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1GalleryPostBoardStaticNamePostIdGet(boardStaticName: string, postId: string, databaseId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GalleryPostInfoModelApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1GalleryPostBoardStaticNamePostIdGet(boardStaticName, postId, databaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 게시글을 수정합니다.
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {string} [databaseId] 
         * @param {GalleryContentsClientDataModel} [galleryContentsClientDataModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1GalleryPostBoardStaticNamePostIdPut(boardStaticName: string, postId: string, databaseId?: string, galleryContentsClientDataModel?: GalleryContentsClientDataModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1GalleryPostBoardStaticNamePostIdPut(boardStaticName, postId, databaseId, galleryContentsClientDataModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GalleryPostApi - factory interface
 * @export
 */
export const GalleryPostApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GalleryPostApiFp(configuration)
    return {
        /**
         * 
         * @summary staticName으로 작성화면의 설정부분을 조회합니다.
         * @param {string} boardStaticName 
         * @param {string} [postId] 
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GalleryPostBoardStaticNameEditinfoGet(boardStaticName: string, postId?: string, langCode?: string, options?: any): AxiosPromise<BoardInfoModelApiResult> {
            return localVarFp.v1GalleryPostBoardStaticNameEditinfoGet(boardStaticName, postId, langCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 게시글 목록을 조회합니다.
         * @param {string} boardStaticName 
         * @param {string} [databaseId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {number} [pageNumCount] 
         * @param {string} [searchType] 
         * @param {string} [searchKeyword] 
         * @param {string} [folderId] 
         * @param {string} [langCode] 
         * @param {string} [searchPeriod] 
         * @param {string} [datePeriod] 
         * @param {string} [fieldInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GalleryPostBoardStaticNameGet(boardStaticName: string, databaseId?: string, page?: number, size?: number, pageNumCount?: number, searchType?: string, searchKeyword?: string, folderId?: string, langCode?: string, searchPeriod?: string, datePeriod?: string, fieldInfo?: string, options?: any): AxiosPromise<GalleryPostInfoModelItemListInfoModelApiResult> {
            return localVarFp.v1GalleryPostBoardStaticNameGet(boardStaticName, databaseId, page, size, pageNumCount, searchType, searchKeyword, folderId, langCode, searchPeriod, datePeriod, fieldInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 게시글을 등록합니다.
         * @param {string} boardStaticName 
         * @param {string} [databaseId] 
         * @param {GalleryContentsClientDataModel} [galleryContentsClientDataModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GalleryPostBoardStaticNamePost(boardStaticName: string, databaseId?: string, galleryContentsClientDataModel?: GalleryContentsClientDataModel, options?: any): AxiosPromise<GuidApiResult> {
            return localVarFp.v1GalleryPostBoardStaticNamePost(boardStaticName, databaseId, galleryContentsClientDataModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 게시글을 삭제합니다.
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {string} [databaseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GalleryPostBoardStaticNamePostIdDelete(boardStaticName: string, postId: string, databaseId?: string, options?: any): AxiosPromise<BooleanApiResult> {
            return localVarFp.v1GalleryPostBoardStaticNamePostIdDelete(boardStaticName, postId, databaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 게시글을 조회합니다.
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {string} [databaseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GalleryPostBoardStaticNamePostIdGet(boardStaticName: string, postId: string, databaseId?: string, options?: any): AxiosPromise<GalleryPostInfoModelApiResult> {
            return localVarFp.v1GalleryPostBoardStaticNamePostIdGet(boardStaticName, postId, databaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 게시글을 수정합니다.
         * @param {string} boardStaticName 
         * @param {string} postId 
         * @param {string} [databaseId] 
         * @param {GalleryContentsClientDataModel} [galleryContentsClientDataModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GalleryPostBoardStaticNamePostIdPut(boardStaticName: string, postId: string, databaseId?: string, galleryContentsClientDataModel?: GalleryContentsClientDataModel, options?: any): AxiosPromise<ApiResult> {
            return localVarFp.v1GalleryPostBoardStaticNamePostIdPut(boardStaticName, postId, databaseId, galleryContentsClientDataModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GalleryPostApi - object-oriented interface
 * @export
 * @class GalleryPostApi
 * @extends {BaseAPI}
 */
export class GalleryPostApi extends BaseAPI {
    /**
     * 
     * @summary staticName으로 작성화면의 설정부분을 조회합니다.
     * @param {string} boardStaticName 
     * @param {string} [postId] 
     * @param {string} [langCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GalleryPostApi
     */
    public v1GalleryPostBoardStaticNameEditinfoGet(boardStaticName: string, postId?: string, langCode?: string, options?: AxiosRequestConfig) {
        return GalleryPostApiFp(this.configuration).v1GalleryPostBoardStaticNameEditinfoGet(boardStaticName, postId, langCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 게시글 목록을 조회합니다.
     * @param {string} boardStaticName 
     * @param {string} [databaseId] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {number} [pageNumCount] 
     * @param {string} [searchType] 
     * @param {string} [searchKeyword] 
     * @param {string} [folderId] 
     * @param {string} [langCode] 
     * @param {string} [searchPeriod] 
     * @param {string} [datePeriod] 
     * @param {string} [fieldInfo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GalleryPostApi
     */
    public v1GalleryPostBoardStaticNameGet(boardStaticName: string, databaseId?: string, page?: number, size?: number, pageNumCount?: number, searchType?: string, searchKeyword?: string, folderId?: string, langCode?: string, searchPeriod?: string, datePeriod?: string, fieldInfo?: string, options?: AxiosRequestConfig) {
        return GalleryPostApiFp(this.configuration).v1GalleryPostBoardStaticNameGet(boardStaticName, databaseId, page, size, pageNumCount, searchType, searchKeyword, folderId, langCode, searchPeriod, datePeriod, fieldInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 게시글을 등록합니다.
     * @param {string} boardStaticName 
     * @param {string} [databaseId] 
     * @param {GalleryContentsClientDataModel} [galleryContentsClientDataModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GalleryPostApi
     */
    public v1GalleryPostBoardStaticNamePost(boardStaticName: string, databaseId?: string, galleryContentsClientDataModel?: GalleryContentsClientDataModel, options?: AxiosRequestConfig) {
        return GalleryPostApiFp(this.configuration).v1GalleryPostBoardStaticNamePost(boardStaticName, databaseId, galleryContentsClientDataModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 게시글을 삭제합니다.
     * @param {string} boardStaticName 
     * @param {string} postId 
     * @param {string} [databaseId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GalleryPostApi
     */
    public v1GalleryPostBoardStaticNamePostIdDelete(boardStaticName: string, postId: string, databaseId?: string, options?: AxiosRequestConfig) {
        return GalleryPostApiFp(this.configuration).v1GalleryPostBoardStaticNamePostIdDelete(boardStaticName, postId, databaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 게시글을 조회합니다.
     * @param {string} boardStaticName 
     * @param {string} postId 
     * @param {string} [databaseId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GalleryPostApi
     */
    public v1GalleryPostBoardStaticNamePostIdGet(boardStaticName: string, postId: string, databaseId?: string, options?: AxiosRequestConfig) {
        return GalleryPostApiFp(this.configuration).v1GalleryPostBoardStaticNamePostIdGet(boardStaticName, postId, databaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 게시글을 수정합니다.
     * @param {string} boardStaticName 
     * @param {string} postId 
     * @param {string} [databaseId] 
     * @param {GalleryContentsClientDataModel} [galleryContentsClientDataModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GalleryPostApi
     */
    public v1GalleryPostBoardStaticNamePostIdPut(boardStaticName: string, postId: string, databaseId?: string, galleryContentsClientDataModel?: GalleryContentsClientDataModel, options?: AxiosRequestConfig) {
        return GalleryPostApiFp(this.configuration).v1GalleryPostBoardStaticNamePostIdPut(boardStaticName, postId, databaseId, galleryContentsClientDataModel, options).then((request) => request(this.axios, this.basePath));
    }
}
