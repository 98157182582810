// Workplanner css
import 'mik-workplanner/src/assets/styles/workplanner.scss'

// 전역 스토리지
import { useWorkPlannerStore } from 'mik-workplanner/src/store/work-planner'
import { Cookies } from 'quasar'
import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'

let isFirst = true
const guards = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
  useRouterMerge: boolean
) => {
  if (isFirst) {
    const langCode = Cookies.get('GWP_LANGUAGE_CODE') ?? 'ko'

    const workPlannerStore = useWorkPlannerStore()
    //
    await workPlannerStore.setWorkType(langCode)
    // 전자결재 Host
    await workPlannerStore.setApvInfo()
    // 캘린더 보드 이름
    await workPlannerStore.setCalendarBoardNameInfo()
    isFirst = false
  }

  if (!useRouterMerge) {
    if (to.name) {
      next()
    } else {
      next(false)
    }
  }
}

export default guards
