/* tslint:disable */
/* eslint-disable */
/**
 * Mik.SmartPortal.Portal.App
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import type { RequestArgs } from '../base';
// @ts-ignore
import type { ApiResult } from '../models';
// @ts-ignore
import type { BoardInfoModelApiResult } from '../models';
// @ts-ignore
import type { GuidApiResult } from '../models';
/**
 * GalleryApi - axios parameter creator
 * @export
 */
export const GalleryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 갤러리 상세 정보를 조회합니다.
         * @param {string} boardStaticName 
         * @param {string} [databaseId] 
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GalleryBoardStaticNameGet: async (boardStaticName: string, databaseId?: string, langCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1GalleryBoardStaticNameGet', 'boardStaticName', boardStaticName)
            const localVarPath = `/v1/gallery/{boardStaticName}`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (databaseId !== undefined) {
                localVarQueryParameter['databaseId'] = databaseId;
            }

            if (langCode !== undefined) {
                localVarQueryParameter['langCode'] = langCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 갤러리 조회
         * @param {string} boardStaticName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GalleryBoardStaticNameIdGet: async (boardStaticName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1GalleryBoardStaticNameIdGet', 'boardStaticName', boardStaticName)
            const localVarPath = `/v1/gallery/{boardStaticName}/id`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary staticName으로 boardId를 포함한 상세 정보를 조회합니다.
         * @param {string} boardStaticName 
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GalleryBoardStaticNameInfoGet: async (boardStaticName: string, langCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1GalleryBoardStaticNameInfoGet', 'boardStaticName', boardStaticName)
            const localVarPath = `/v1/gallery/{boardStaticName}/info`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (langCode !== undefined) {
                localVarQueryParameter['langCode'] = langCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 갤러리 개인설정을 초기화합니다.
         * @param {string} boardStaticName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GalleryBoardStaticNameResetBoardPersonalizationPost: async (boardStaticName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1GalleryBoardStaticNameResetBoardPersonalizationPost', 'boardStaticName', boardStaticName)
            const localVarPath = `/v1/gallery/{boardStaticName}/ResetBoardPersonalization`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 갤러리 개인설정을 세팅합니다.
         * @param {string} boardStaticName 
         * @param {string} [optionName] 
         * @param {string} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GalleryBoardStaticNameSetBoardPersonalizationPost: async (boardStaticName: string, optionName?: string, value?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardStaticName' is not null or undefined
            assertParamExists('v1GalleryBoardStaticNameSetBoardPersonalizationPost', 'boardStaticName', boardStaticName)
            const localVarPath = `/v1/gallery/{boardStaticName}/SetBoardPersonalization`
                .replace(`{${"boardStaticName"}}`, encodeURIComponent(String(boardStaticName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (optionName !== undefined) {
                localVarQueryParameter['optionName'] = optionName;
            }

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GalleryApi - functional programming interface
 * @export
 */
export const GalleryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GalleryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 갤러리 상세 정보를 조회합니다.
         * @param {string} boardStaticName 
         * @param {string} [databaseId] 
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1GalleryBoardStaticNameGet(boardStaticName: string, databaseId?: string, langCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoardInfoModelApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1GalleryBoardStaticNameGet(boardStaticName, databaseId, langCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 갤러리 조회
         * @param {string} boardStaticName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1GalleryBoardStaticNameIdGet(boardStaticName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1GalleryBoardStaticNameIdGet(boardStaticName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary staticName으로 boardId를 포함한 상세 정보를 조회합니다.
         * @param {string} boardStaticName 
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1GalleryBoardStaticNameInfoGet(boardStaticName: string, langCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoardInfoModelApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1GalleryBoardStaticNameInfoGet(boardStaticName, langCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 갤러리 개인설정을 초기화합니다.
         * @param {string} boardStaticName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1GalleryBoardStaticNameResetBoardPersonalizationPost(boardStaticName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1GalleryBoardStaticNameResetBoardPersonalizationPost(boardStaticName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 갤러리 개인설정을 세팅합니다.
         * @param {string} boardStaticName 
         * @param {string} [optionName] 
         * @param {string} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1GalleryBoardStaticNameSetBoardPersonalizationPost(boardStaticName: string, optionName?: string, value?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1GalleryBoardStaticNameSetBoardPersonalizationPost(boardStaticName, optionName, value, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GalleryApi - factory interface
 * @export
 */
export const GalleryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GalleryApiFp(configuration)
    return {
        /**
         * 
         * @summary 갤러리 상세 정보를 조회합니다.
         * @param {string} boardStaticName 
         * @param {string} [databaseId] 
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GalleryBoardStaticNameGet(boardStaticName: string, databaseId?: string, langCode?: string, options?: any): AxiosPromise<BoardInfoModelApiResult> {
            return localVarFp.v1GalleryBoardStaticNameGet(boardStaticName, databaseId, langCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 갤러리 조회
         * @param {string} boardStaticName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GalleryBoardStaticNameIdGet(boardStaticName: string, options?: any): AxiosPromise<GuidApiResult> {
            return localVarFp.v1GalleryBoardStaticNameIdGet(boardStaticName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary staticName으로 boardId를 포함한 상세 정보를 조회합니다.
         * @param {string} boardStaticName 
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GalleryBoardStaticNameInfoGet(boardStaticName: string, langCode?: string, options?: any): AxiosPromise<BoardInfoModelApiResult> {
            return localVarFp.v1GalleryBoardStaticNameInfoGet(boardStaticName, langCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 갤러리 개인설정을 초기화합니다.
         * @param {string} boardStaticName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GalleryBoardStaticNameResetBoardPersonalizationPost(boardStaticName: string, options?: any): AxiosPromise<ApiResult> {
            return localVarFp.v1GalleryBoardStaticNameResetBoardPersonalizationPost(boardStaticName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 갤러리 개인설정을 세팅합니다.
         * @param {string} boardStaticName 
         * @param {string} [optionName] 
         * @param {string} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GalleryBoardStaticNameSetBoardPersonalizationPost(boardStaticName: string, optionName?: string, value?: string, options?: any): AxiosPromise<ApiResult> {
            return localVarFp.v1GalleryBoardStaticNameSetBoardPersonalizationPost(boardStaticName, optionName, value, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GalleryApi - object-oriented interface
 * @export
 * @class GalleryApi
 * @extends {BaseAPI}
 */
export class GalleryApi extends BaseAPI {
    /**
     * 
     * @summary 갤러리 상세 정보를 조회합니다.
     * @param {string} boardStaticName 
     * @param {string} [databaseId] 
     * @param {string} [langCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GalleryApi
     */
    public v1GalleryBoardStaticNameGet(boardStaticName: string, databaseId?: string, langCode?: string, options?: AxiosRequestConfig) {
        return GalleryApiFp(this.configuration).v1GalleryBoardStaticNameGet(boardStaticName, databaseId, langCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 갤러리 조회
     * @param {string} boardStaticName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GalleryApi
     */
    public v1GalleryBoardStaticNameIdGet(boardStaticName: string, options?: AxiosRequestConfig) {
        return GalleryApiFp(this.configuration).v1GalleryBoardStaticNameIdGet(boardStaticName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary staticName으로 boardId를 포함한 상세 정보를 조회합니다.
     * @param {string} boardStaticName 
     * @param {string} [langCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GalleryApi
     */
    public v1GalleryBoardStaticNameInfoGet(boardStaticName: string, langCode?: string, options?: AxiosRequestConfig) {
        return GalleryApiFp(this.configuration).v1GalleryBoardStaticNameInfoGet(boardStaticName, langCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 갤러리 개인설정을 초기화합니다.
     * @param {string} boardStaticName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GalleryApi
     */
    public v1GalleryBoardStaticNameResetBoardPersonalizationPost(boardStaticName: string, options?: AxiosRequestConfig) {
        return GalleryApiFp(this.configuration).v1GalleryBoardStaticNameResetBoardPersonalizationPost(boardStaticName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 갤러리 개인설정을 세팅합니다.
     * @param {string} boardStaticName 
     * @param {string} [optionName] 
     * @param {string} [value] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GalleryApi
     */
    public v1GalleryBoardStaticNameSetBoardPersonalizationPost(boardStaticName: string, optionName?: string, value?: string, options?: AxiosRequestConfig) {
        return GalleryApiFp(this.configuration).v1GalleryBoardStaticNameSetBoardPersonalizationPost(boardStaticName, optionName, value, options).then((request) => request(this.axios, this.basePath));
    }
}
